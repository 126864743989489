import React from 'react';
import { Box } from 'src/components/widgets/styled';
import { pulsate } from 'src/components/widgets/styled/keyframes';
import styled from 'styled-components';
import { rgba } from 'polished';
type Props = {
  color?: string;
  icon?: string;
  active?: boolean;
  isSmall?: boolean;
};

const Marker = styled(Box)<{ smaller?: boolean }>`
  width: ${(props) => (props.smaller ? '48px' : '68px')};
  height: ${(props) => (props.smaller ? '48px' : '68px')};
  display: block;
  .pin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 44px;
    position: relative;
    background-color: ${(props) => props.color || '#d81b4d'};
    border-radius: 50%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-items: center;
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
`;

const PinEffect = styled(Box)<{ smaller?: boolean }>`
  width: ${(props) => (props.smaller ? '48px' : '68px')};
  height: ${(props) => (props.smaller ? '48px' : '68px')};
  position: absolute;
  top: 0;
  display: block;
  background-color: ${(props) => (props.color ? rgba(props.color, 0.6) : '#d81b4d')};
  border-radius: 50%;
  opacity: 0;
  animation: ${pulsate} 800ms ease-out infinite;
`;

const PinEffect2 = styled(PinEffect)`
  background-color: ${(props) => (props.color ? rgba(props.color, 0.4) : '#d81b4d')};
  animation: ${pulsate} 1500ms ease-out infinite;
`;
const PinEffect3 = styled(PinEffect)`
  background-color: ${(props) => (props.color ? rgba(props.color, 0.2) : '#d81b4d')};
  animation: ${pulsate} 2500ms ease-out infinite;
`;

export const MarkerIcon: React.SFC<Props> = React.memo(({ color, icon, active, isSmall }) => {
  return (
    <Marker className="marker" color={color} smaller={isSmall}>
      <Box className="pin" color={color}>
        <img src={icon ?? '/images/pin1.svg'} alt="" />
      </Box>
      <PinEffect color={color} smaller={isSmall} />
      <PinEffect2 color={color} smaller={isSmall} />
      <PinEffect3 color={color} smaller={isSmall} />
    </Marker>
  );
});
