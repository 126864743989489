import {
  AppstoreAddOutlined,
  BankOutlined,
  BarsOutlined,
  BuildOutlined,
  ContactsOutlined,
  FileDoneOutlined,
  FileImageOutlined,
  FolderOpenOutlined,
  FundOutlined,
  HomeOutlined,
  InboxOutlined,
  MailOutlined,
  UserOutlined,
  FileSearchOutlined,
  NotificationOutlined,
  QuestionCircleOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { Route } from '@ant-design/pro-layout/lib/typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppPermissions } from 'src/helpers/app.permission';
import { AppRoutes } from 'src/helpers/app.routes';

export const useRouteTranslation = () => {
  const { t } = useTranslation();
  const route: Route & { permissionKey?: string } = {
    routes: [
      {
        permissionKey: AppPermissions.role_permission,
        path: AppRoutes.roleList,
        name: t('Role and permission'),
        icon: <UserOutlined />,
        children: [
          {
            path: AppRoutes.accountList,
            name: t('Account List'),
          },
          {
            path: AppRoutes.permissionMatrix,
            name: t('Permission matrix setting'),
          },
        ],
      },
      {
        permissionKey: AppPermissions.general_setting,
        path: AppRoutes.general,
        name: t('General setting'),
        icon: <HomeOutlined />,
        children: [
          {
            path: AppRoutes.headerSetting,
            name: t('Header'),
          },
          {
            path: AppRoutes.menuSetting,
            name: t('Menu'),
          },
          {
            path: AppRoutes.footerSetting,
            name: t('Footer'),
          },
          {
            path: AppRoutes.sitemapSetting,
            name: t('Sitemap'),
          },
          {
            path: AppRoutes.partner,
            name: t('Partner'),
          },
        ],
      },
      {
        permissionKey: AppPermissions.home_management,
        path: AppRoutes.home,
        name: t('Home management'),
        icon: <BankOutlined />,
      },
      {
        permissionKey: AppPermissions.page_management,
        path: AppRoutes.pages,
        name: t('Page management'),
        icon: <BarsOutlined />,
      },
      {
        permissionKey: AppPermissions.category_management,
        path: AppRoutes.category,
        name: t('Category management'),
        icon: <AppstoreAddOutlined />,
      },
      {
        permissionKey: AppPermissions.asset_management,
        path: AppRoutes.media,
        name: t('Asset management'),
        icon: <FolderOpenOutlined />,
      },
      {
        permissionKey: AppPermissions.our_team,
        path: AppRoutes.ourTeams,
        name: t('Our team'),
        icon: <ContactsOutlined />,
        children: [
          {
            path: AppRoutes.ourTeamsGeneralInfo,
            name: t('General info'),
          },
          {
            path: AppRoutes.ourTeamsMembers,
            name: t('Members'),
          },
        ],
      },
      {
        permissionKey: AppPermissions.fund_management,
        path: AppRoutes.fundManagement,
        name: t('Fund management'),
        icon: <FundOutlined />,
        children: [
          {
            path: AppRoutes.fundStateMent,
            name: t('Fund statement'),
            Icon: 'smile',
            children: [
              {
                path: AppRoutes.fundGroupListGeneral,
                name: t('Fund group list'),
              },
              {
                path: AppRoutes.unitFundGroup,
                name: t('Unit linked'),
              },
              {
                path: AppRoutes.universalLinkFundDetail,
                name: t('Universal life'),
              },
            ],
          },
          {
            path: AppRoutes.fundList,
            name: t('Unit fund list'),
          },
          {
            path: AppRoutes.fundRateList,
            name: t('Fund rates'),
          },
          {
            path: AppRoutes.interestRate,
            name: t('Interest rate info'),
            Icon: 'smile',
            children: [
              {
                path: AppRoutes.interestRateInfo,
                name: t('Info page'),
              },
              {
                path: AppRoutes.interestRateType,
                name: t('Rate type list'),
              },
              {
                path: AppRoutes.interestRateList,
                name: t('Interest rate'),
              },
            ],
          },
          {
            path: AppRoutes.fundPageList,
            name: t('Page list'),
          },
        ],
      },
      {
        permissionKey: AppPermissions.branches,
        path: AppRoutes.branch,
        name: t('Branches'),
        icon: <BuildOutlined />,
      },
      {
        permissionKey: AppPermissions.financial_report,
        path: AppRoutes.financialStatement,
        name: t('Financial report'),
        icon: <FileDoneOutlined />,
      },
      {
        permissionKey: AppPermissions.articles,
        path: AppRoutes.articles,
        name: t('Articles'),
        icon: <FileImageOutlined />,
        children: [
          {
            path: AppRoutes.press,
            name: t('Press'),
            Icon: 'smile',
            children: [
              {
                path: AppRoutes.pressGeneralInfo,
                name: t('General info'),
              },
              {
                path: AppRoutes.pressList,
                name: t('Press list'),
              },
            ],
          },
          {
            path: AppRoutes.promotions,
            name: t('Promotion'),
            children: [
              {
                path: AppRoutes.promotionGeneralInfo,
                name: t('General info'),
              },
              {
                path: AppRoutes.promotionList,
                name: t('Promotion list'),
              },
            ],
          },
          {
            path: AppRoutes.brandingCampaigns,
            name: t('Branding campaigns'),
            children: [
              {
                path: AppRoutes.brandingCampaignsGeneralInfo,
                name: t('General info'),
              },
              {
                path: AppRoutes.brandingCampaignList,
                name: t('Campaign list'),
              },
            ],
          },
          {
            path: AppRoutes.csr,
            name: t('CSR'),
            children: [
              {
                path: AppRoutes.csrGeneralInfo,
                name: t('General info'),
              },
              {
                path: AppRoutes.csrList,
                name: t('CSR list'),
              },
            ],
          },
          {
            path: AppRoutes.blogs,
            name: t('Blogs'),
            children: [
              {
                path: AppRoutes.blogsGeneralInfo,
                name: t('General info'),
              },
              {
                path: AppRoutes.articleGroup,
                name: t('Group'),
              },
              {
                path: AppRoutes.blogList,
                name: t('Blog list'),
              },
            ],
          },
        ],
      },
      {
        permissionKey: AppPermissions.product_management,
        path: AppRoutes.productManagement,
        name: t('Product management'),
        icon: <InboxOutlined />,
        children: [
          {
            path: AppRoutes.productGeneralInfo,
            name: t('General info'),
          },
          {
            path: AppRoutes.productGroupList,
            name: t('Product groups'),
          },
          {
            path: AppRoutes.productList,
            name: t('Product list'),
          },
        ],
      },
      {
        permissionKey: AppPermissions.customer_request,
        path: AppRoutes.request,
        name: t('Customer requests'),
        icon: <MailOutlined />,
        children: [
          {
            path: AppRoutes.requestSettings,
            name: t('Settings'),
          },
          {
            path: AppRoutes.requestList,
            name: t('Requests'),
          },
        ],
      },
      {
        permissionKey: AppPermissions.policy_management,
        path: AppRoutes.policyManagement,
        name: t('Policy change & Claim instruction'),
        icon: <FileSearchOutlined />,
        children: [
          {
            path: AppRoutes.generalInfoPolicy,
            name: t('Policy change_General info'),
          },
          {
            path: AppRoutes.policyList,
            name: t('Policy change'),
          },
          {
            path: AppRoutes.generalInfoClaim,
            name: t('Claim instruction_General info'),
          },
          {
            path: AppRoutes.claimList,
            name: t('Claim instruction'),
          },
        ],
      },
      {
        permissionKey: AppPermissions.faqs_management,
        path: AppRoutes.faqs,
        name: t('FAQs management'),
        icon: <FileSearchOutlined />,
        children: [
          {
            path: AppRoutes.faqGeneralInfo,
            name: t('General info'),
          },
          {
            path: AppRoutes.faqList,
            name: t('FAQs list'),
          },
        ],
      },
      {
        permissionKey: AppPermissions.popup_management,
        path: AppRoutes.popupManagement,
        name: t('Popup management'),
        icon: <NotificationOutlined />,
        children: [
          {
            path: AppRoutes.newsletterList,
            name: t('Newsletter'),
          },
          {
            path: AppRoutes.advertisementList,
            name: t('Advertisement'),
          },
          {
            path: AppRoutes.cookiesList,
            name: t('Cookies'),
          },
        ],
      },
      {
        permissionKey: AppPermissions.saa_tool,
        path: AppRoutes.saaTool,
        name: t('SAA Tool'),
        icon: <QuestionCircleOutlined />,
        children: [
          {
            path: AppRoutes.questionRateMatrix,
            name: t('Question rate matrix'),
          },
          {
            path: AppRoutes.domainList,
            name: t('Domains & Products'),
          },
          {
            path: AppRoutes.saaResultList,
            name: t('SAA results'),
          },
        ],
      },
      {
        permissionKey: AppPermissions.transaction_log,
        path: AppRoutes.paymentLog,
        name: t('Transaction log'),
        icon: <HistoryOutlined />,
      },
      {
        permissionKey: AppPermissions.SERVICE_PROGRAM,
        path: AppRoutes.customerService,
        name: t('Customer Service'),
        icon: <NotificationOutlined />,
        children: [
          {
            path: AppRoutes.serviceProgramGeneralInfo,
            name: t('General Info'),
          },
          {
            path: AppRoutes.serviceProgram,
            name: t('Service Program'),
          },
        ],
      },
    ],
  };
  return route;
};
