import React, { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import RouteLayout from './components/router/RouteLayout';
import MainLayout from './layouts/Main';
import Blank from './layouts/Blank';
import { AppRoutes } from './helpers/app.routes';
import LoginPage from './pages/login';
import SimpleMap from './modules/maps';
import { AppPermissions } from './helpers/app.permission';

const PageBuilderScreen = React.lazy(() => import('./modules/page-builder'));
const EditorPage = React.lazy(() => import('./modules/editor'));
const Page404 = React.lazy(() => import('src/components/Errors/404/404'));
const Page500 = React.lazy(() => import('src/components/Errors/500/500'));
const HomeBuilderPage = {
  home: React.lazy(() => import('./modules/home-page-builder')),
  headerSetting: React.lazy(() => import('./pages/header-setting')),
  //
  list: React.lazy(() => import('./pages/home/home')),
  create: React.lazy(() => import('./pages/home/create')),
  edit: React.lazy(() => import('./pages/home/update')),
  editHtml: React.lazy(() => import('./pages/home/update-html')),
  view: React.lazy(() => import('./pages/home/view')),
};

const GeneralPage = {
  menuSetting: React.lazy(() => import('./pages/menu-setting')),
  sitemapSetting: React.lazy(() => import('./pages/sitemap-setting')),
};

const Media = {
  list: React.lazy(() => import('./pages/media')),
  create: React.lazy(() => import('./modules/media/create/index.screen')),
  edit: React.lazy(() => import('./modules/media/edit/index.screen')),
};
const Pages = {
  list: React.lazy(() => import('./pages/page/page')),
  create: React.lazy(() => import('./pages/page/create')),
  edit: React.lazy(() => import('./pages/page/update')),
  editHtml: React.lazy(() => import('./pages/page/update-html')),
  view: React.lazy(() => import('./pages/page/view')),
};

const Category = {
  list: React.lazy(() => import('./pages/category/category')),
  create: React.lazy(() => import('./pages/category/create')),
  edit: React.lazy(() => import('./pages/category/edit')),
};

const GeneralSetting = {
  footer: React.lazy(() => import('./modules/general-setting/footer')),
};

const BuilderPage = {
  pageBuilder: React.lazy(() => import('./modules/page-builder/Edit')),
  originBuilder: React.lazy(() => import('./modules/origin-page-builder')),
  contactUsBuilder: React.lazy(() => import('./modules/contact-us-page-builder')),
  businessEnvBuilder: React.lazy(() => import('./modules/business-environment-builder')),
  paymentBuilder: React.lazy(() => import('./modules/payment-builder')),
  bancaBuilder: React.lazy(() => import('./modules/banca-page-builder')),
  careerAgencyBuilder: React.lazy(() => import('./modules/career-agency-builder')),
  templateOneBuilder: React.lazy(() => import('./modules/template-one-builder')),
  templateSevenBuilder: React.lazy(() => import('./modules/template-seven-builder')),
  templateFourBuilder: React.lazy(() => import('./modules/template-four-builder')),
  templateFiveBuilder: React.lazy(() => import('./modules/template-five-builder')),
  templateThreeBuilder: React.lazy(() => import('./modules/template-three-builder')),
  exfBuilder: React.lazy(() => import('./modules/exf-builder')),
};

const OurTeams = {
  ourTeamsMembers: React.lazy(() => import('./modules/our-team/members')),
  ourTeamsMemberDetail: React.lazy(() => import('./modules/our-team/member-detail')),
  ourTeamsMemberCreate: React.lazy(() => import('./modules/our-team/create')),
  ourTeamsMemberUpdate: React.lazy(() => import('./modules/our-team/update')),
  ourTeamsGeneralInfo: React.lazy(() => import('./modules/our-team/general-info')),
};

const FundAndStatement = {
  fundList: React.lazy(() => import('./modules/fund-statement/funds')),
  fundStateDetail: React.lazy(() => import('./modules/fund-statement/fund-detail')),
  fundCreate: React.lazy(() => import('./modules/fund-statement/create')),
  fundUpdate: React.lazy(() => import('./modules/fund-statement/update')),
  fundGroupList: React.lazy(() => import('./modules/fund-statement/fund-group-list')),
  fundRateList: React.lazy(() => import('./modules/fund-statement/fund-rate')),
  unitFundGroup: React.lazy(() => import('./modules/unit-fund-group/detail')),
  unitFundGroupUpdate: React.lazy(() => import('./modules/unit-fund-group/update')),
  universalLinkFundDetail: React.lazy(() => import('./modules/fund-statement/universal-link-fund')),
  universalLinkFundUpdate: React.lazy(() => import('./modules/fund-statement/update-universal-link-fund')),
  fundPageList: React.lazy(() => import('./modules/fund-statement/page-list')),
};

const FundGroup = {
  fundGroupList: React.lazy(() => import('./modules/fund-group/list')),
  fundGroupDetail: React.lazy(() => import('./modules/fund-group/fund-group-detail')),
  fundGroupCreate: React.lazy(() => import('./modules/fund-group/create')),
  fundGroupUpdate: React.lazy(() => import('./modules/fund-group/update')),
};

const InterestRate = {
  interestRateList: React.lazy(() => import('./modules/interest-rate/rate')),
  interestRateType: React.lazy(() => import('./modules/interest-rate/rate-type')),
  interestRateInfo: React.lazy(() => import('./modules/interest-rate/detail')),
  interestRateUpdate: React.lazy(() => import('./modules/interest-rate/update')),
};

const Branch = {
  list: React.lazy(() => import('./modules/branch')),
  create: React.lazy(() => import('./modules/branch/create')),
  edit: React.lazy(() => import('./modules/branch/edit')),
  view: React.lazy(() => import('./modules/branch/view')),
};

const FinancialStatement = {
  financialStatement: React.lazy(() => import('./modules/financial-statement')),
};

const Articles = {
  articles: React.lazy(() => import('./modules/press/general-info')),
};

const Press = {
  pressGeneralInfo: React.lazy(() => import('./modules/press/general-info')),
  list: React.lazy(() => import('./modules/press')),
  create: React.lazy(() => import('./modules/press/create')),
  edit: React.lazy(() => import('./modules/press/edit')),
  view: React.lazy(() => import('./modules/press/view')),
};

const Promotion = {
  promotionGeneralInfo: React.lazy(() => import('./modules/promotion/general-info')),
  list: React.lazy(() => import('./modules/promotion')),
  create: React.lazy(() => import('./modules/promotion/create')),
  edit: React.lazy(() => import('./modules/promotion/edit')),
  view: React.lazy(() => import('./modules/promotion/view')),
};

const BrandingCampaign = {
  generalInfo: React.lazy(() => import('./modules/branding-campaign/general-info')),
  list: React.lazy(() => import('./modules/branding-campaign')),
  create: React.lazy(() => import('./modules/branding-campaign/create')),
  edit: React.lazy(() => import('./modules/branding-campaign/edit')),
  view: React.lazy(() => import('./modules/branding-campaign/view')),
};

const Csr = {
  generalInfo: React.lazy(() => import('./modules/csr/general-info')),
  list: React.lazy(() => import('./modules/csr')),
  create: React.lazy(() => import('./modules/csr/create')),
  edit: React.lazy(() => import('./modules/csr/edit')),
  view: React.lazy(() => import('./modules/csr/view')),
};

const Blog = {
  generalInfo: React.lazy(() => import('./modules/blogs/general-info')),
  list: React.lazy(() => import('./modules/blogs')),
  create: React.lazy(() => import('./modules/blogs/create')),
  edit: React.lazy(() => import('./modules/blogs/edit')),
  view: React.lazy(() => import('./modules/blogs/view')),
};

const ProductManagement = {
  productList: React.lazy(() => import('./modules/product/list')),
  productCreate: React.lazy(() => import('./modules/product/create')),
  productUpdate: React.lazy(() => import('./modules/product/update')),
  productGeneralInfo: React.lazy(() => import('./modules/product/general-info')),
};

const ProductGroup = {
  productGroupList: React.lazy(() => import('./modules/product-group/list')),
  productGroupCreate: React.lazy(() => import('./modules/product-group/create')),
  productGroupUpdate: React.lazy(() => import('./modules/product-group/update')),
};

const Request = {
  list: React.lazy(() => import('./modules/customer-requests')),
  settings: React.lazy(() => import('./modules/customer-requests/settings')),
  view: React.lazy(() => import('./modules/customer-requests/view')),
};

const Partner = {
  list: React.lazy(() => import('./modules/partners')),
  // create: React.lazy(() => import('./modules/partners/create')),
  edit: React.lazy(() => import('./modules/partners/edit')),
  view: React.lazy(() => import('./modules/partners/view')),
};

const ArticleGroup = {
  list: React.lazy(() => import('./pages/blog-group')),
  create: React.lazy(() => import('./pages/blog-group/create')),
  edit: React.lazy(() => import('./pages/blog-group/edit')),
};

const RoleAndPermission = {
  accountList: React.lazy(() => import('./modules/accounts/User')),
  accountCreate: React.lazy(() => import('./modules/accounts/User/create')),
  accountEdit: React.lazy(() => import('./modules/accounts/User/edit')),
  permission: React.lazy(() => import('./modules/accounts/permission')),
};
const FAQs = {
  list: React.lazy(() => import('./modules/faq')),
  create: React.lazy(() => import('./modules/faq/create')),
  edit: React.lazy(() => import('./modules/faq/edit')),
  general: React.lazy(() => import('./modules/faq/general')),
};

const PolicyManagement = {
  policyList: React.lazy(() => import('./modules/policy-change/list')),
  policyCreate: React.lazy(() => import('./modules/policy-change/create')),
  policyUpdate: React.lazy(() => import('./modules/policy-change/update')),
  generalInfoPolicy: React.lazy(() => import('./modules/policy-management/policy-change/general')),
  generalInfoClaim: React.lazy(() => import('./modules/policy-management/claim/general')),
};

const ClaimManagement = {
  claimList: React.lazy(() => import('./modules/claim-instruction/list')),
  claimCreate: React.lazy(() => import('./modules/claim-instruction/create')),
  claimUpdate: React.lazy(() => import('./modules/claim-instruction/update')),
};

const NewsletterManagement = {
  newsletterList: React.lazy(() => import('./modules/popup-management/newsletter/list')),
  newsletteCreate: React.lazy(() => import('./modules/popup-management/newsletter/create')),
  newsletteUpdate: React.lazy(() => import('./modules/popup-management/newsletter/update')),
};

const AdvertisementManagement = {
  advertisementList: React.lazy(() => import('./modules/popup-management/advertisement/list')),
  advertisementCreate: React.lazy(() => import('./modules/popup-management/advertisement/create')),
  advertisementUpdate: React.lazy(() => import('./modules/popup-management/advertisement/update')),
};

const CookiesManagement = {
  cookiesList: React.lazy(() => import('./modules/popup-management/cookies/list')),
  cookiesCreate: React.lazy(() => import('./modules/popup-management/cookies/create')),
  cookiesUpdate: React.lazy(() => import('./modules/popup-management/cookies/update')),
};

const SaaManagement = {
  questionList: React.lazy(() => import('./modules/saa/question-matrix')),
  questionView: React.lazy(() => import('./modules/saa/question-matrix/view')),
  saaResultList: React.lazy(() => import('./modules/saa/results')),
  saaResultView: React.lazy(() => import('./modules/saa/results/view')),
  domainList: React.lazy(() => import('./modules/saa/domain')),
  domainView: React.lazy(() => import('./modules/saa/domain/view')),
  domainEdit: React.lazy(() => import('./modules/saa/domain/edit')),
};

const PaymentManagement = {
  paymentLog: React.lazy(() => import('./modules/payment-log/list')),
};

const ServiceProgram = {
  general: React.lazy(() => import('./modules/service-program/general-info')),
  save: React.lazy(() => import('./modules/service-program/save')),
};

export const RootRouter = memo(() => {
  return (
    <Switch>
      <Route path={AppRoutes.login} component={LoginPage} exact />
      <RouteLayout
        permission={AppPermissions.page_management}
        path={AppRoutes.pages}
        component={Pages.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.page_management}
        path={AppRoutes.createPage}
        component={Pages.create}
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.page_management}
        path={AppRoutes.editPage}
        component={Pages.edit}
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.page_management}
        path={AppRoutes.editPageUpload}
        component={Pages.editHtml}
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.page_management}
        path={AppRoutes.viewPage}
        component={Pages.view}
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.general_setting}
        path={AppRoutes.menuSetting}
        component={GeneralPage.menuSetting}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.general_setting}
        path={AppRoutes.sitemapSetting}
        component={GeneralPage.sitemapSetting}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.general_setting}
        path={AppRoutes.headerSetting}
        component={HomeBuilderPage.headerSetting}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.asset_management}
        path={AppRoutes.media}
        component={Media.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.asset_management}
        path={AppRoutes.mediaCreate}
        component={Media.create}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.asset_management}
        path={AppRoutes.media + '/:id'}
        component={Media.edit}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.home_management}
        path={AppRoutes.homeBuilder}
        component={HomeBuilderPage.home}
        exact
        layout={Blank}
      />
      <RouteLayout
        permission={AppPermissions.home_management}
        path={AppRoutes.home}
        component={HomeBuilderPage.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.home_management}
        path={AppRoutes.homeEdit}
        component={HomeBuilderPage.home}
        exact
      />
      {/*  */}
      <RouteLayout
        permission={AppPermissions.home_management}
        path={AppRoutes.homeCreate}
        component={HomeBuilderPage.create}
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.home_management}
        path={AppRoutes.homeEditInfo}
        component={HomeBuilderPage.edit}
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.home_management}
        path={AppRoutes.homeEditPageUpload}
        component={HomeBuilderPage.editHtml}
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.home_management}
        path={AppRoutes.viewHomePage}
        component={HomeBuilderPage.view}
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.category_management}
        path={AppRoutes.category}
        component={Category.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.category_management}
        path={AppRoutes.categoryCreate}
        component={Category.create}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.category_management}
        path={AppRoutes.categoryEdit}
        component={Category.edit}
        exact
        layout={MainLayout}
      />

      <RouteLayout path="/map" component={SimpleMap} exact layout={MainLayout} />
      <RouteLayout path="/editor" isPrivate={false} component={EditorPage} layout={MainLayout} exact />
      <RouteLayout path={AppRoutes.pageBuilder} component={PageBuilderScreen} exact />

      <RouteLayout
        permission={AppPermissions.general_setting}
        path={AppRoutes.footerSetting}
        component={GeneralSetting.footer}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.page_management}
        path={AppRoutes.pageBuilderEdit}
        component={BuilderPage.pageBuilder}
        exact
      />
      <RouteLayout
        permission={AppPermissions.page_management}
        path={AppRoutes.originBuilder}
        component={BuilderPage.originBuilder}
        exact
      />
      <RouteLayout
        permission={AppPermissions.page_management}
        path={AppRoutes.contactUsBuilder}
        component={BuilderPage.contactUsBuilder}
        exact
        layout={Blank}
      />

      <RouteLayout
        permission={AppPermissions.our_team}
        path={AppRoutes.ourTeamsMembers}
        component={OurTeams.ourTeamsMembers}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.our_team}
        path={AppRoutes.ourTeamsMemberCreate}
        component={OurTeams.ourTeamsMemberCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.our_team}
        path={AppRoutes.ourTeamsMemberDetail}
        component={OurTeams.ourTeamsMemberDetail}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.our_team}
        path={AppRoutes.ourTeamsMemberUpdate}
        component={OurTeams.ourTeamsMemberUpdate}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.our_team}
        path={AppRoutes.ourTeamsGeneralInfo}
        component={OurTeams.ourTeamsGeneralInfo}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.financial_report}
        path={AppRoutes.financialStatement}
        component={FinancialStatement.financialStatement}
        exact
        layout={MainLayout}
      />

      {/*Branches*/}
      <RouteLayout
        permission={AppPermissions.branches}
        path={AppRoutes.branch}
        component={Branch.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.branches}
        path={AppRoutes.branchCreate}
        component={Branch.create}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.branches}
        path={AppRoutes.branchEdit}
        component={Branch.edit}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.branches}
        path={AppRoutes.branchView}
        component={Branch.view}
        exact
        layout={MainLayout}
      />

      {/*Articles*/}
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.articles}
        component={Articles.articles}
        exact
        layout={MainLayout}
      />

      {/*Press*/}
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.pressList}
        component={Press.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.pressCreate}
        component={Press.create}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.pressEdit}
        component={Press.edit}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.pressView}
        component={Press.view}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.pressGeneralInfo}
        component={Press.pressGeneralInfo}
        exact
        layout={MainLayout}
      />

      {/*Promotion*/}
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.promotionList}
        component={Promotion.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.promotionCreate}
        component={Promotion.create}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.promotionEdit}
        component={Promotion.edit}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.promotionView}
        component={Promotion.view}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.promotionGeneralInfo}
        component={Promotion.promotionGeneralInfo}
        exact
        layout={MainLayout}
      />

      {/*Branding campaign*/}
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.brandingCampaignList}
        component={BrandingCampaign.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.brandingCampaignCreate}
        component={BrandingCampaign.create}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.brandingCampaignEdit}
        component={BrandingCampaign.edit}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.brandingCampaignView}
        component={BrandingCampaign.view}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.brandingCampaignsGeneralInfo}
        component={BrandingCampaign.generalInfo}
        exact
        layout={MainLayout}
      />

      {/*CSR*/}
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.csrList}
        component={Csr.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.csrCreate}
        component={Csr.create}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.csrEdit}
        component={Csr.edit}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.csrView}
        component={Csr.view}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.csrGeneralInfo}
        component={Csr.generalInfo}
        exact
        layout={MainLayout}
      />

      {/*Blog*/}
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.blogList}
        component={Blog.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.blogCreate}
        component={Blog.create}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.blogEdit}
        component={Blog.edit}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.blogView}
        component={Blog.view}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.blogsGeneralInfo}
        component={Blog.generalInfo}
        exact
        layout={MainLayout}
      />

      {/*Fund*/}
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundList}
        component={FundAndStatement.fundList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundCreate}
        component={FundAndStatement.fundCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundStateDetail}
        component={FundAndStatement.fundStateDetail}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundUpdate}
        component={FundAndStatement.fundUpdate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundGroupListGeneral}
        component={FundAndStatement.fundGroupList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.unitFundGroup}
        component={FundAndStatement.unitFundGroup}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.unitFundGroupUpdate}
        component={FundAndStatement.unitFundGroupUpdate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.universalLinkFundDetail}
        component={FundAndStatement.universalLinkFundDetail}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.universalLinkFundUpdate}
        component={FundAndStatement.universalLinkFundUpdate}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundGroupList}
        component={FundGroup.fundGroupList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundGroupCreate}
        component={FundGroup.fundGroupCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundGroupDetail}
        component={FundGroup.fundGroupDetail}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundGroupUpdate}
        component={FundGroup.fundGroupUpdate}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundRateList}
        component={FundAndStatement.fundRateList}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.interestRateInfo}
        component={InterestRate.interestRateInfo}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.interestRateUpdate}
        component={InterestRate.interestRateUpdate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.interestRateList}
        component={InterestRate.interestRateList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.interestRateType}
        component={InterestRate.interestRateType}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.fund_management}
        path={AppRoutes.fundPageList}
        component={FundAndStatement.fundPageList}
        exact
        layout={MainLayout}
      />

      {/* Product */}
      <RouteLayout
        permission={AppPermissions.product_management}
        path={AppRoutes.productList}
        component={ProductManagement.productList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.product_management}
        path={AppRoutes.productCreate}
        component={ProductManagement.productCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.product_management}
        path={AppRoutes.productUpdate}
        component={ProductManagement.productUpdate}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.product_management}
        path={AppRoutes.productGeneralInfo}
        component={ProductManagement.productGeneralInfo}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.product_management}
        path={AppRoutes.productGroupList}
        component={ProductGroup.productGroupList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.product_management}
        path={AppRoutes.productGroupCreate}
        component={ProductGroup.productGroupCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.product_management}
        path={AppRoutes.productGroupUpdate}
        component={ProductGroup.productGroupUpdate}
        exact
        layout={MainLayout}
      />

      {/*Customer Request*/}
      <RouteLayout
        permission={AppPermissions.customer_request}
        path={AppRoutes.requestList}
        component={Request.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.customer_request}
        path={AppRoutes.requestSettings}
        component={Request.settings}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.customer_request}
        path={AppRoutes.requestView}
        component={Request.view}
        exact
        layout={MainLayout}
      />

      {/* Partners */}
      <RouteLayout
        permission={AppPermissions.general_setting}
        path={AppRoutes.partner}
        component={Partner.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.general_setting}
        path={AppRoutes.partnerEdit}
        component={Partner.edit}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.general_setting}
        path={AppRoutes.partnerView}
        component={Partner.view}
        exact
        layout={MainLayout}
      />

      {/* Builder */}
      <RouteLayout path={AppRoutes.businessEnvBuilder} component={BuilderPage.businessEnvBuilder} exact />
      <RouteLayout path={AppRoutes.paymentBuilder} component={BuilderPage.paymentBuilder} exact />
      <RouteLayout path={AppRoutes.bancaBuilder} component={BuilderPage.bancaBuilder} exact layout={Blank} />
      <RouteLayout
        path={AppRoutes.careerAgencyBuilder}
        component={BuilderPage.careerAgencyBuilder}
        exact
        layout={Blank}
      />
      <RouteLayout
        path={AppRoutes.templateOneBuilder}
        component={BuilderPage.templateOneBuilder}
        exact
        layout={Blank}
      />
      <RouteLayout
        path={AppRoutes.templateThreeBuilder}
        component={BuilderPage.templateThreeBuilder}
        exact
        layout={Blank}
      />

      <RouteLayout path={AppRoutes.exfBuilder} component={BuilderPage.exfBuilder} exact layout={Blank} />

      <RouteLayout
        path={AppRoutes.templateFourBuilder}
        component={BuilderPage.templateFourBuilder}
        exact
        layout={Blank}
      />
      <RouteLayout
        path={AppRoutes.templateSevenBuilder}
        component={BuilderPage.templateSevenBuilder}
        exact
        layout={Blank}
      />
      <RouteLayout
        path={AppRoutes.templateFiveBuilder}
        component={BuilderPage.templateFiveBuilder}
        exact
        layout={Blank}
      />

      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.articleGroup}
        component={ArticleGroup.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.articleGroupCreate}
        component={ArticleGroup.create}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.articles}
        path={AppRoutes.articleGroupEdit}
        component={ArticleGroup.edit}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.role_permission}
        path={AppRoutes.accountList}
        component={RoleAndPermission.accountList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.role_permission}
        path={AppRoutes.accountCreate}
        component={RoleAndPermission.accountCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.role_permission}
        path={AppRoutes.accountEdit}
        component={RoleAndPermission.accountEdit}
        exact
        layout={MainLayout}
      />
      {/* Change Policy */}
      <RouteLayout
        permission={AppPermissions.policy_management}
        path={AppRoutes.policyList}
        component={PolicyManagement.policyList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.policy_management}
        path={AppRoutes.policyCreate}
        component={PolicyManagement.policyCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.policy_management}
        path={AppRoutes.policyUpdate}
        component={PolicyManagement.policyUpdate}
        exact
        layout={MainLayout}
      />
      {/* Claim Instruction */}
      <RouteLayout
        permission={AppPermissions.policy_management}
        path={AppRoutes.claimList}
        component={ClaimManagement.claimList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.policy_management}
        path={AppRoutes.claimCreate}
        component={ClaimManagement.claimCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.policy_management}
        path={AppRoutes.claimUpdate}
        component={ClaimManagement.claimUpdate}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.policy_management}
        path={AppRoutes.generalInfoPolicy}
        component={PolicyManagement.generalInfoPolicy}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.policy_management}
        path={AppRoutes.generalInfoClaim}
        component={PolicyManagement.generalInfoClaim}
        exact
        layout={MainLayout}
      />

      {/* FAQs */}
      <RouteLayout
        permission={AppPermissions.faqs_management}
        path={AppRoutes.faqGeneralInfo}
        component={FAQs.general}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.faqs_management}
        path={AppRoutes.faqList}
        component={FAQs.list}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.faqs_management}
        path={AppRoutes.faqCreate}
        component={FAQs.create}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.faqs_management}
        path={AppRoutes.faqEdit}
        component={FAQs.edit}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.role_permission}
        path={AppRoutes.permissionMatrix}
        component={RoleAndPermission.permission}
        exact
        layout={MainLayout}
      />

      {/* Popup Management */}
      {/* Newsletter */}
      <RouteLayout
        permission={AppPermissions.popup_management}
        path={AppRoutes.newsletterList}
        component={NewsletterManagement.newsletterList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.popup_management}
        path={AppRoutes.newsletteCreate}
        component={NewsletterManagement.newsletteCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.popup_management}
        path={AppRoutes.newsletteUpdate}
        component={NewsletterManagement.newsletteUpdate}
        exact
        layout={MainLayout}
      />

      {/* Advertisement */}
      <RouteLayout
        permission={AppPermissions.popup_management}
        path={AppRoutes.advertisementList}
        component={AdvertisementManagement.advertisementList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.popup_management}
        path={AppRoutes.advertisementCreate}
        component={AdvertisementManagement.advertisementCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.popup_management}
        path={AppRoutes.advertisementUpdate}
        component={AdvertisementManagement.advertisementUpdate}
        exact
        layout={MainLayout}
      />

      {/* Cookies */}
      <RouteLayout
        permission={AppPermissions.popup_management}
        path={AppRoutes.cookiesList}
        component={CookiesManagement.cookiesList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.popup_management}
        path={AppRoutes.cookiesCreate}
        component={CookiesManagement.cookiesCreate}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.popup_management}
        path={AppRoutes.cookiesUpdate}
        component={CookiesManagement.cookiesUpdate}
        exact
        layout={MainLayout}
      />

      {/* SAA Results */}
      <RouteLayout
        permission={AppPermissions.saa_tool}
        path={AppRoutes.saaResultList}
        component={SaaManagement.saaResultList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.saa_tool}
        path={AppRoutes.saaResultView}
        component={SaaManagement.saaResultView}
        exact
        layout={MainLayout}
      />

      {/* SAA Domain */}
      <RouteLayout
        permission={AppPermissions.saa_tool}
        path={AppRoutes.domainList}
        component={SaaManagement.domainList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.saa_tool}
        path={AppRoutes.domainView}
        component={SaaManagement.domainView}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.saa_tool}
        path={AppRoutes.domainEdit}
        component={SaaManagement.domainEdit}
        exact
        layout={MainLayout}
      />

      {/* Question Matrix */}
      <RouteLayout
        permission={AppPermissions.saa_tool}
        path={AppRoutes.questionRateMatrix}
        component={SaaManagement.questionList}
        exact
        layout={MainLayout}
      />
      <RouteLayout
        permission={AppPermissions.saa_tool}
        path={AppRoutes.questionRateMatrixView}
        component={SaaManagement.questionView}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.transaction_log}
        path={AppRoutes.paymentLog}
        component={PaymentManagement.paymentLog}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.general_setting}
        path={AppRoutes.serviceProgramGeneralInfo}
        component={ServiceProgram.general}
        exact
        layout={MainLayout}
      />

      <RouteLayout
        permission={AppPermissions.general_setting}
        path={AppRoutes.serviceProgram}
        component={ServiceProgram.save}
        exact
        layout={MainLayout}
      />

      <Route path={AppRoutes.page404} component={Page404} exact />
      <Route path={AppRoutes.page500} component={Page500} exact />
      <Redirect path="/" to={AppRoutes.home} />
      <Redirect path="*" to={AppRoutes.page404} />
    </Switch>
  );
});
