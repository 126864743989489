import flatten from 'flat';
import label from './label.json';
import button from './button.json';
import menu from './menu.json';
import search from './search.json';
import media from './media.json';
import page from './page.json';
import member from './member.json';
import branch from './branch.json';
import press from './press.json';
import request from './request.json';
import partner from './partner.json';
import saa from './saa.json';

export default {
  label: flatten<Record<string, any>, typeof label>(label, {
    delimiter: '_',
  }),
  button: flatten<Record<string, any>, typeof label>(button, {
    delimiter: '_',
  }),
  menu: flatten<Record<string, any>, typeof label>(menu, {
    delimiter: '_',
  }),
  search: flatten<Record<string, any>, typeof label>(search, {
    delimiter: '_',
  }),
  media: flatten<Record<string, any>, typeof label>(media, {
    delimiter: '_',
  }),
  page: flatten<Record<string, any>, typeof label>(page, {
    delimiter: '_',
  }),
  member: flatten<Record<string, any>, typeof label>(member, {
    delimiter: '_',
  }),
  branch: flatten<Record<string, any>, typeof label>(branch, {
    delimiter: '_',
  }),
  press: flatten<Record<string, any>, typeof label>(press, {
    delimiter: '_',
  }),
  request: flatten<Record<string, any>, typeof label>(request, {
    delimiter: '_',
  }),
  partner: flatten<Record<string, any>, typeof label>(partner, {
    delimiter: '_',
  }),
  saa: flatten<Record<string, any>, typeof label>(saa, {
    delimiter: '_',
  }),
};
