export const pageNum = 1;
export const perPage = 20;

export const timeFormat24h = 'HH:mm';
export const formatDate = 'YYYY/MM/DD';
export const formatDateReverse = 'DD/MM/YYYY';
export const dateTimeFormat = 'YYYY/MM/DD hh:mm A';
export const dateTimeFormat24h = 'YYYY/MM/DD HH:mm';
export const dateTimeFormat24hs = 'YYYY/MM/DD HH:mm:ss';
export const dateTimeFormat24hsReverse = 'HH:mm:ss DD/MM/YYYY';
export const maxImageSize = Number(process.env.REACT_APP_MAX_SIZE_UPLOAD);
export const maxVideoSize = Number(process.env.REACT_APP_MAX_SIZE_UPLOAD);
export const maxFileSize = Number(process.env.REACT_APP_MAX_SIZE_UPLOAD);
export const UPLOAD_ALLOW_TYPES = [
  'jpeg',
  'jpg',
  'png',
  'gif',
  'svg',
  'mp4',
  'pdf',
  'doc',
  'docx',
  'xlsx',
  'xls',
  'ppt',
  'pptx',
  'webp',
];
export const UPLOAD_ALLOW_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/svg',
  'image/webp',
  'image/svg+xml',
];

export const UPLOAD_ALLOW_VIDEO_TYPES = ['video/mp4'];
export const UPLOAD_ALLOW_FILE_TYPES = ['pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx'];

export const TagColor = {
  FILE: 'geekblue',
  VIDEO: 'volcano',
  IMAGE: 'green',
};
export const URL_WEB_APP = process.env.REACT_APP_FRONTEND_URL;

export const GG_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyCg2w7rqwn5_GtxaUOUEHj9mGGignW1vSI';

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
