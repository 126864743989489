import type * as Types from '../type.interface';

import type { UserFragmentFragment } from '../fragments/user-fragment.generated';
import { gql } from '@apollo/client';
import { UserFragmentFragmentDoc } from '../fragments/user-fragment.generated';
import * as Apollo from '@apollo/client';
export type MeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeQueryResponse = { __typename?: 'Query' } & { me: { __typename?: 'User' } & UserFragmentFragment };

export const MeDocument = gql`
  query me {
    me {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQueryResponse, MeQueryVariables>) {
  return Apollo.useQuery<MeQueryResponse, MeQueryVariables>(MeDocument, baseOptions);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQueryResponse, MeQueryVariables>) {
  return Apollo.useLazyQuery<MeQueryResponse, MeQueryVariables>(MeDocument, baseOptions);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQueryResponse, MeQueryVariables>;
