import React, { useRef, useCallback, useEffect } from 'react';

type Props = {
  map?: any;
  maps?: any;
  onPlacesChanged?: any;
  placeholder?: string;
};
const SearchBox = ({ map, maps, onPlacesChanged, placeholder }: Props) => {
  const input = useRef(null);
  const searchBox = useRef(null);

  const handleOnPlacesChanged = useCallback(() => {
    if (onPlacesChanged) {
      onPlacesChanged((searchBox as any).current.getPlaces());
    }
  }, [onPlacesChanged, searchBox]);

  useEffect(() => {
    map.controls[maps.ControlPosition.TOP_LEFT].push(input.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchBox.current && maps) {
      (searchBox as any).current = new maps.places.SearchBox(input.current);
      (searchBox as any).current.addListener('places_changed', handleOnPlacesChanged);
    }

    return () => {
      if (maps) {
        searchBox.current = null;
        maps.event.clearInstanceListeners(searchBox);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maps, handleOnPlacesChanged]);

  return (
    <input
      ref={input}
      placeholder={placeholder ?? 'Search'}
      type="text"
      style={{
        marginTop: 10,
        marginLeft: 10,
      }}
      className="field-input"
    />
  );
};
export default SearchBox;
