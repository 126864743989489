import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type LoginMutationVariables = Types.Exact<{
  captchaToken: Types.Scalars['String'];
  password: Types.Scalars['String'];
  username: Types.Scalars['String'];
}>;

export type LoginMutationResponse = { __typename?: 'Mutation' } & {
  login: { __typename?: 'AuthConnection' } & Pick<Types.AuthConnection, 'accessToken' | 'refreshToken'> & {
      user: { __typename?: 'User' } & Pick<
        Types.User,
        'id' | 'username' | 'email' | 'createdAt' | 'updatedAt' | 'roles' | 'fullName' | 'permissions'
      >;
    };
};

export const LoginDocument = gql`
  mutation login($captchaToken: String!, $password: String!, $username: String!) {
    login(captchaToken: $captchaToken, password: $password, username: $username) {
      accessToken
      refreshToken
      user {
        id
        username
        email
        createdAt
        updatedAt
        roles
        fullName
        permissions
      }
    }
  }
`;
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutationResponse, LoginMutationVariables>,
) {
  return Apollo.useMutation<LoginMutationResponse, LoginMutationVariables>(LoginDocument, baseOptions);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutationResponse>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutationResponse, LoginMutationVariables>;
