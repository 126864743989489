import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
export type UserFragmentFragment = { __typename?: 'User' } & Pick<
  Types.User,
  'id' | 'username' | 'email' | 'createdAt' | 'updatedAt' | 'roles' | 'fullName' | 'permissions'
>;

export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    username
    email
    createdAt
    updatedAt
    roles
    fullName
    permissions
  }
`;
