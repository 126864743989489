export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasPrevPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
};

export type BasePaginationMeta = {
  __typename?: 'BasePaginationMeta';
  itemCount: Scalars['Float'];
  totalItems: Scalars['Float'];
  itemsPerPage: Scalars['Float'];
  totalPages: Scalars['Float'];
  currentPage: Scalars['Float'];
};

export type InquiryPaymentDetail = {
  __typename?: 'InquiryPaymentDetail';
  duePeriod?: Maybe<Scalars['String']>;
  dueAmount?: Maybe<Scalars['String']>;
};

export type TransactionLog = {
  __typename?: 'TransactionLog';
  id: Scalars['ID'];
  paymentGateway?: Maybe<PaymentGatewayType>;
  baokimId?: Maybe<Scalars['String']>;
  mrcOrderId?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  eAppNumber?: Maybe<Scalars['String']>;
  txnId?: Maybe<Scalars['String']>;
  inquiryId?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['String']>;
  inquiryPaymentDetail?: Maybe<Array<InquiryPaymentDetail>>;
  feeAmount?: Maybe<Scalars['String']>;
  orderStat?: Maybe<Scalars['String']>;
  transactionStat?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  premiumType?: Maybe<PremiumTypeEnum>;
  inquiryTime?: Maybe<Scalars['DateTime']>;
  inquiryResponseTime?: Maybe<Scalars['DateTime']>;
  holderName?: Maybe<Scalars['String']>;
  mainInsuredName?: Maybe<Scalars['String']>;
  holderDob?: Maybe<Scalars['String']>;
  mainInsuredDob?: Maybe<Scalars['String']>;
  payerIsHolder?: Maybe<Scalars['Boolean']>;
  payerName?: Maybe<Scalars['String']>;
  payerEmail?: Maybe<Scalars['String']>;
  payerRelation?: Maybe<PayerRelationEnum>;
  payerDocumentType?: Maybe<PaymentDocumentType>;
  payerDocumentId?: Maybe<Scalars['String']>;
  payerPhoneNumber?: Maybe<Scalars['String']>;
  addField1?: Maybe<Scalars['String']>;
  addField2?: Maybe<Scalars['String']>;
  addField3?: Maybe<Scalars['String']>;
  addField4?: Maybe<Scalars['String']>;
  addField5?: Maybe<Scalars['String']>;
  /** Momo transaction request ID */
  requestId?: Maybe<Scalars['String']>;
  /** Momo transaction reference ID */
  referenceId?: Maybe<Scalars['String']>;
  /** Momo transaction payment ID */
  paymentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum PaymentGatewayType {
  BAOKIM = 'BAOKIM',
  MOMO = 'MOMO',
}

export enum PremiumTypeEnum {
  DINH_KY = 'DINH_KY',
  LAN_DAU = 'LAN_DAU',
  TOPUP = 'TOPUP',
}

export enum PayerRelationEnum {
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  PARTNER = 'PARTNER',
  CHILDREN = 'CHILDREN',
  SIBLING = 'SIBLING',
  CONSULTANT = 'CONSULTANT',
}

export enum PaymentDocumentType {
  CMT = 'CMT',
  CCCD = 'CCCD',
  PASSPORT = 'PASSPORT',
  CMT_QUAN_DOI = 'CMT_QUAN_DOI',
}

export type TransactionLogConnection = {
  __typename?: 'TransactionLogConnection';
  items?: Maybe<Array<TransactionLog>>;
  meta: BasePaginationMeta;
};

/** ExportTransactionLogList */
export type ExportTransactionLogList = {
  __typename?: 'ExportTransactionLogList';
  url?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Faq = {
  __typename?: 'Faq';
  id: Scalars['ID'];
  translations?: Maybe<Array<FaqTranslation>>;
  createdBy?: Maybe<User>;
  deletedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FaqConnection = {
  __typename?: 'FaqConnection';
  items?: Maybe<Array<Faq>>;
  meta: BasePaginationMeta;
};

export type FaqTranslation = {
  __typename?: 'FaqTranslation';
  id: Scalars['ID'];
  faqId: Scalars['ID'];
  items?: Maybe<Array<FaqItem>>;
  lang: LanguageEnum;
  name: Scalars['String'];
  tabIcon: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum LanguageEnum {
  EN = 'EN',
  VI = 'VI',
}

export type FaqItem = {
  __typename?: 'FaqItem';
  id: Scalars['ID'];
  faqTranslationId: Scalars['ID'];
  order: Scalars['Float'];
  title: Scalars['String'];
  description: Scalars['String'];
};

export type FinancialReports = {
  __typename?: 'FinancialReports';
  id: Scalars['ID'];
  lang?: Maybe<LanguageEnum>;
  categoryId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<Category>;
  background?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  featureImage?: Maybe<Scalars['String']>;
  financialStatement?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  featureImageFullPath?: Maybe<Scalars['String']>;
  backgroundFullPath?: Maybe<Scalars['String']>;
  reportList?: Maybe<Array<ReportsModel>>;
};

export type MemberGeneralInfo = {
  __typename?: 'MemberGeneralInfo';
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
  shortDescription?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  category: Category;
  lang: LanguageEnum;
  title: Scalars['String'];
  background: Scalars['String'];
  titleHeading: Scalars['String'];
  subTitleHeading: Scalars['String'];
  pageUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pageUrlPath?: Maybe<Scalars['String']>;
  backgroundPath?: Maybe<Scalars['String']>;
  featureImagePath?: Maybe<Scalars['String']>;
};

export type FundGeneralInfo = {
  __typename?: 'FundGeneralInfo';
  id: Scalars['ID'];
  categoryId?: Maybe<Scalars['ID']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  category: Category;
  lang: LanguageEnum;
  title: Scalars['String'];
  background: Scalars['String'];
  pageUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pageUrlPath?: Maybe<Scalars['String']>;
  backgroundPath?: Maybe<Scalars['String']>;
  featureImagePath?: Maybe<Scalars['String']>;
};

export type ArticleGeneralInfo = {
  __typename?: 'ArticleGeneralInfo';
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
  background?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  suggestionText?: Maybe<Scalars['String']>;
  suggestionBackgroundImage?: Maybe<Scalars['String']>;
  suggestionColor?: Maybe<Scalars['String']>;
  suggestionIcon?: Maybe<Scalars['String']>;
  suggestionButtonUrl?: Maybe<Scalars['String']>;
  suggestionButtonText?: Maybe<Scalars['String']>;
  category: Category;
  lang: LanguageEnum;
  type: ArticleType;
  title: Scalars['String'];
  pageUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pageUrlPath?: Maybe<Scalars['String']>;
  backgroundPath?: Maybe<Scalars['String']>;
  featureImagePath?: Maybe<Scalars['String']>;
  suggestionIconPath?: Maybe<Scalars['String']>;
  suggestionBackgroundImagePath?: Maybe<Scalars['String']>;
};

export enum ArticleType {
  BLOG = 'BLOG',
  PRESS = 'PRESS',
  PROMOTION = 'PROMOTION',
  CSR = 'CSR',
  BRANDING_CAMPAIGN = 'BRANDING_CAMPAIGN',
}

/** Category */
export type Category = Node & {
  __typename?: 'Category';
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  countPage: Scalars['Int'];
  categoryModule?: Maybe<CategoryModuleEnum>;
  pages?: Maybe<Array<PageTranslation>>;
  translations?: Maybe<Array<CategoryTranslations>>;
  financials?: Maybe<Array<FinancialReports>>;
  description?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  children?: Maybe<Array<Category>>;
  parent?: Maybe<Category>;
  translation?: Maybe<CategoryTranslations>;
};

/** Node */
export type Node = {
  id: Scalars['ID'];
};

export enum CategoryModuleEnum {
  GENERAL_SETTING = 'GENERAL_SETTING',
  PAGE_BUILDER = 'PAGE_BUILDER',
  OUR_TEAM = 'OUR_TEAM',
  FUND = 'FUND',
  BRANCHES = 'BRANCHES',
  FINANCIAL_REPORT = 'FINANCIAL_REPORT',
  ARTICLE_BLOG = 'ARTICLE_BLOG',
  ARTICLE_PRESS = 'ARTICLE_PRESS',
  ARTICLE_PROMOTION = 'ARTICLE_PROMOTION',
  ARTICLE_BRANDING_CAMPAIGN = 'ARTICLE_BRANDING_CAMPAIGN',
  ARTICLE_CSR = 'ARTICLE_CSR',
  PRODUCT = 'PRODUCT',
  CUSTOMER_REQUEST = 'CUSTOMER_REQUEST',
  POLICY_CLAIM = 'POLICY_CLAIM',
  FAQ = 'FAQ',
}

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  items?: Maybe<Array<Category>>;
  meta: BasePaginationMeta;
};

export type CategoryTranslations = {
  __typename?: 'CategoryTranslations';
  id: Scalars['ID'];
  categoryId?: Maybe<Scalars['ID']>;
  category?: Maybe<Category>;
  name: Scalars['String'];
  lang: LanguageEnum;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TemplateEntity = {
  __typename?: 'TemplateEntity';
  id: Scalars['ID'];
  details?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  pages?: Maybe<Array<PageTranslation>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ArticleRelatedItems = {
  __typename?: 'ArticleRelatedItems';
  id: Scalars['ID'];
  articleTranslationId: Scalars['ID'];
  articleRelatedId: Scalars['ID'];
  articleRelated?: Maybe<ArticleTranslations>;
  title: Scalars['String'];
  featureImage: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  featureImagePath?: Maybe<Scalars['String']>;
};

/** Comment */
export type Comment = Node & {
  __typename?: 'Comment';
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  articleTranslationId: Scalars['ID'];
  parent?: Maybe<Comment>;
  content: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status: StatusComment;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  children?: Maybe<Array<Comment>>;
  articleTranslation: ArticleTranslations;
  createdBy?: Maybe<User>;
  countChildren?: Maybe<Scalars['Int']>;
};

export enum StatusComment {
  HIDDEN = 'HIDDEN',
  ACTIVE = 'ACTIVE',
}

export type CommentConnection = {
  __typename?: 'CommentConnection';
  items?: Maybe<Array<Comment>>;
  meta: BasePaginationMeta;
};

export type ArticleGroup = {
  __typename?: 'ArticleGroup';
  id: Scalars['ID'];
  createdBy: Scalars['ID'];
  articleIds?: Maybe<Array<Scalars['ID']>>;
  articles?: Maybe<Array<Articles>>;
  creator?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  translations: Array<ArticleGroupTranslation>;
  groupArticleOrder: Array<GroupArticleOrder>;
  countArticle?: Maybe<Scalars['Float']>;
};

export type ArticleGroupTranslation = {
  __typename?: 'ArticleGroupTranslation';
  id: Scalars['ID'];
  articleGroupId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  lang: LanguageEnum;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupArticleOrder = {
  __typename?: 'GroupArticleOrder';
  id: Scalars['ID'];
  articleGroupId?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  orderPosition?: Maybe<Scalars['Int']>;
  article: Articles;
};

export type Articles = {
  __typename?: 'Articles';
  id: Scalars['ID'];
  articleGroupIds?: Maybe<Array<Scalars['ID']>>;
  articleGroups?: Maybe<Array<ArticleGroup>>;
  translations?: Maybe<Array<ArticleTranslations>>;
  type: ArticleType;
  postedDate: Scalars['DateTime'];
  isPage: Scalars['Boolean'];
  isPublished?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: User;
  translation?: Maybe<ArticleTranslations>;
};

export type ArticleConnection = {
  __typename?: 'ArticleConnection';
  items?: Maybe<Array<Articles>>;
  meta: BasePaginationMeta;
};

export type ArticleTranslations = {
  __typename?: 'ArticleTranslations';
  id: Scalars['ID'];
  articleId: Scalars['ID'];
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  moduleType: Scalars['String'];
  relatedItems?: Maybe<Array<ArticleRelatedItems>>;
  lang: LanguageEnum;
  type: ArticleType;
  urlSlug: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  isPage: Scalars['Boolean'];
  isPublished?: Maybe<Scalars['Boolean']>;
  scheduleDate?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  urlSlugPath?: Maybe<Scalars['String']>;
  featureImagePath?: Maybe<Scalars['String']>;
  bannerImagePath?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['DateTime']>;
  hasAdvertisement?: Maybe<Scalars['Boolean']>;
  hasNewsletter?: Maybe<Scalars['Boolean']>;
};

export type ArticleTranslationsHasAdvertisementArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type ArticleTranslationsHasNewsletterArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type Members = {
  __typename?: 'Members';
  id: Scalars['ID'];
  translations?: Maybe<Array<MemberTranslation>>;
  order: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: User;
  isPage: Scalars['Boolean'];
  isPublished?: Maybe<Scalars['Boolean']>;
  translation?: Maybe<MemberTranslation>;
};

export type MemberTranslation = {
  __typename?: 'MemberTranslation';
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  quote?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featureImage?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  lang: LanguageEnum;
  urlSlug: Scalars['String'];
  photo: Scalars['String'];
  fullname: Scalars['String'];
  position: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isPublished?: Maybe<Scalars['Boolean']>;
  urlSlugPath?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  featureImagePath?: Maybe<Scalars['String']>;
  hasAdvertisement?: Maybe<Scalars['Boolean']>;
  hasNewsletter?: Maybe<Scalars['Boolean']>;
};

export type MemberTranslationHasAdvertisementArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type MemberTranslationHasNewsletterArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type Media = Node & {
  __typename?: 'Media';
  id: Scalars['ID'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  fullPath?: Maybe<Scalars['String']>;
  thumbImage?: Maybe<Scalars['String']>;
  mediumImage?: Maybe<Scalars['String']>;
  originImage?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  uploadBy: Scalars['ID'];
  fileName: Scalars['String'];
  name: Scalars['String'];
  filePath?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  fileType?: Maybe<FileType>;
  isDeleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  uploadType: UploadType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  owner?: Maybe<User>;
  capabilities?: Maybe<MediaCapability>;
};

export enum FileType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  FILE = 'FILE',
}

export enum UploadType {
  FILE = 'FILE',
  DIR = 'DIR',
}

export type MediaConnection = {
  __typename?: 'MediaConnection';
  items?: Maybe<Array<Media>>;
  meta: BasePaginationMeta;
};

export type DeleteMedia = {
  __typename?: 'DeleteMedia';
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  id: Scalars['ID'];
  createdBy: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<ProductGroup>;
  children?: Maybe<Array<ProductGroup>>;
  translations?: Maybe<Array<ProductGroupTranslation>>;
  productIds?: Maybe<Array<Scalars['ID']>>;
  products?: Maybe<Array<Product>>;
  creator?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  groupProductOrder: Array<GroupProductOrder>;
  countProduct?: Maybe<Scalars['Float']>;
};

export type ProductGroupProductsArgs = {
  isBackend?: Maybe<Scalars['Boolean']>;
};

export type ProductGroupTranslation = {
  __typename?: 'ProductGroupTranslation';
  id: Scalars['ID'];
  productGroupId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  urlSlug?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  lang: LanguageEnum;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  imageFullPath?: Maybe<Scalars['String']>;
  bannerImageFullPath?: Maybe<Scalars['String']>;
  hasAdvertisement?: Maybe<Scalars['Boolean']>;
  hasNewsletter?: Maybe<Scalars['Boolean']>;
};

export type ProductGroupTranslationHasAdvertisementArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type ProductGroupTranslationHasNewsletterArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type GroupProductOrder = {
  __typename?: 'GroupProductOrder';
  id: Scalars['ID'];
  productGroupId?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  orderPosition?: Maybe<Scalars['Int']>;
  product: Product;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  createdBy: Scalars['ID'];
  productGroupIds?: Maybe<Array<Scalars['ID']>>;
  productGroups?: Maybe<Array<ProductGroup>>;
  informations?: Maybe<Array<ProductInformations>>;
  benefitSettings?: Maybe<Array<ProductBenefitSetting>>;
  benefits?: Maybe<Array<ProductBenefits>>;
  examples?: Maybe<Array<ProductExamples>>;
  featureSettings?: Maybe<Array<ProductFeaturesSetting>>;
  relatedSettings?: Maybe<Array<RelatedProductsSetting>>;
  featurest?: Maybe<Array<ProductFeaturest>>;
  documents?: Maybe<Array<ProductDocuments>>;
  creator?: Maybe<User>;
  moduleType: ModuleType;
  isPage: Scalars['Boolean'];
  isPublished?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum ModuleType {
  PRODUCT = 'PRODUCT',
  FUND = 'FUND',
  PROMOTION = 'PROMOTION',
  PRESS = 'PRESS',
  BLOG = 'BLOG',
  BRANDING_CAMPAIGN = 'BRANDING_CAMPAIGN',
  CSR = 'CSR',
}

export type ProductInformations = {
  __typename?: 'ProductInformations';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  productImage?: Maybe<Scalars['String']>;
  productFeaturestImage?: Maybe<Scalars['String']>;
  urlSlug?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  relatedProducts?: Maybe<Array<RelatedProducts>>;
  lang: LanguageEnum;
  status?: Maybe<StatusType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  coverImageFullPath?: Maybe<Scalars['String']>;
  productImageFullPath?: Maybe<Scalars['String']>;
  hasAdvertisement?: Maybe<Scalars['Boolean']>;
  hasNewsletter?: Maybe<Scalars['Boolean']>;
};

export type ProductInformationsHasAdvertisementArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type ProductInformationsHasNewsletterArgs = {
  lang?: Maybe<LanguageEnum>;
};

export enum StatusType {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export type ProductBenefitSetting = {
  __typename?: 'ProductBenefitSetting';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  tabButton?: Maybe<Scalars['String']>;
  tabIcon?: Maybe<Scalars['String']>;
  sectionTitle?: Maybe<Scalars['String']>;
  lang: LanguageEnum;
  tabIconFullPath?: Maybe<Scalars['String']>;
};

export type ProductBenefits = {
  __typename?: 'ProductBenefits';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  orderPosition?: Maybe<Scalars['Int']>;
  lang: LanguageEnum;
  status?: Maybe<StatusType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  iconFullPath?: Maybe<Scalars['String']>;
};

export type ProductExamples = {
  __typename?: 'ProductExamples';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  tabButton?: Maybe<Scalars['String']>;
  tabIcon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  lang: LanguageEnum;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  imageFullPath?: Maybe<Scalars['String']>;
  tabIconFullPath?: Maybe<Scalars['String']>;
};

export type ProductFeaturesSetting = {
  __typename?: 'ProductFeaturesSetting';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  tabButton?: Maybe<Scalars['String']>;
  tabIcon?: Maybe<Scalars['String']>;
  sectionTitle?: Maybe<Scalars['String']>;
  lang: LanguageEnum;
  tabIconFullPath?: Maybe<Scalars['String']>;
};

export type ProductFeaturest = {
  __typename?: 'ProductFeaturest';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  iconBackground?: Maybe<Scalars['String']>;
  orderPosition?: Maybe<Scalars['Int']>;
  lang: LanguageEnum;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  iconFullPath?: Maybe<Scalars['String']>;
};

export type ProductDocuments = {
  __typename?: 'ProductDocuments';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  tabButton?: Maybe<Scalars['String']>;
  tabIcon?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  fundName?: Maybe<Scalars['String']>;
  fundUrl?: Maybe<Scalars['ID']>;
  fundIcon?: Maybe<Scalars['String']>;
  mediaIds?: Maybe<Array<Scalars['ID']>>;
  medias?: Maybe<Array<Media>>;
  lang: LanguageEnum;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  tabIconFullPath?: Maybe<Scalars['String']>;
  fundIconFullPath?: Maybe<Scalars['String']>;
};

export type RelatedProductsSetting = {
  __typename?: 'RelatedProductsSetting';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  sectionTitle?: Maybe<Scalars['String']>;
  lang: LanguageEnum;
};

export type RelatedProducts = {
  __typename?: 'RelatedProducts';
  id: Scalars['ID'];
  featureImage?: Maybe<Scalars['String']>;
  productInformationId: Scalars['ID'];
  productRelatedId: Scalars['ID'];
  orderPosition?: Maybe<Scalars['Int']>;
  productRelated?: Maybe<ProductInformations>;
  lang: LanguageEnum;
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  featureImageFullPath?: Maybe<Scalars['String']>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  items?: Maybe<Array<Product>>;
  meta: BasePaginationMeta;
};

export type ProductInformationConnection = {
  __typename?: 'ProductInformationConnection';
  items?: Maybe<Array<ProductInformations>>;
  meta: BasePaginationMeta;
};

export type PageEntity = {
  __typename?: 'PageEntity';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  translation: Array<PageTranslation>;
};

export type PageTranslation = {
  __typename?: 'PageTranslation';
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['ID']>;
  pageTitle?: Maybe<Scalars['String']>;
  urlSlug?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  layoutType?: Maybe<LayoutType>;
  pageLayout?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  articleId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  articleGroupIds?: Maybe<Array<Scalars['ID']>>;
  productGroupIds?: Maybe<Array<Scalars['ID']>>;
  createdBy: Scalars['ID'];
  product?: Maybe<Product>;
  article?: Maybe<Articles>;
  member?: Maybe<Members>;
  page?: Maybe<PageEntity>;
  creator?: Maybe<User>;
  category?: Maybe<Category>;
  template?: Maybe<TemplateEntity>;
  lang: LanguageEnum;
  featuredImage?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['DateTime']>;
  scheduleDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PageStatus>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  pageType?: Maybe<PageType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hasEnLanguage?: Maybe<Scalars['Boolean']>;
  hasVnLanguage?: Maybe<Scalars['Boolean']>;
  draftVersionId?: Maybe<Scalars['String']>;
  otherVersions?: Maybe<Array<PageTranslation>>;
  isDraft?: Maybe<Scalars['Boolean']>;
  fullPath?: Maybe<Scalars['String']>;
  mediumImage?: Maybe<Scalars['String']>;
  lowQualityImage?: Maybe<Scalars['String']>;
  thumbImage?: Maybe<Scalars['String']>;
  hasAdvertisement?: Maybe<Scalars['Boolean']>;
  hasNewsletter?: Maybe<Scalars['Boolean']>;
};

export type PageTranslationHasAdvertisementArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type PageTranslationHasNewsletterArgs = {
  lang?: Maybe<LanguageEnum>;
};

export enum LayoutType {
  BUILDER = 'BUILDER',
  UPLOAD = 'UPLOAD',
}

export enum PageStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum PageType {
  HOME = 'HOME',
  PAGE = 'PAGE',
}

export type PageTransConnection = {
  __typename?: 'PageTransConnection';
  items?: Maybe<Array<PageTranslation>>;
  meta: BasePaginationMeta;
};

export type RecommendationModel = {
  __typename?: 'RecommendationModel';
  buttonInfo: Scalars['String'];
  icon: Scalars['String'];
  url: Scalars['String'];
};

export type FundGroups = {
  __typename?: 'FundGroups';
  id: Scalars['ID'];
  translations?: Maybe<Array<FundGroupTranslations>>;
  funds?: Maybe<Array<Funds>>;
  fundType: FundType;
  order: Scalars['Float'];
  isVisible: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: User;
  translation?: Maybe<FundGroupTranslations>;
  count?: Maybe<Scalars['Int']>;
};

export enum FundType {
  UNIVERSAL = 'UNIVERSAL',
  UNIT = 'UNIT',
  INTEREST = 'INTEREST',
}

export type FundGroupConnection = {
  __typename?: 'FundGroupConnection';
  items?: Maybe<Array<FundGroups>>;
  meta: BasePaginationMeta;
};

export type FundGroupTranslations = {
  __typename?: 'FundGroupTranslations';
  id: Scalars['ID'];
  fundGroupId: Scalars['ID'];
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  buttonColor?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageDetail?: Maybe<Scalars['String']>;
  recommendations?: Maybe<Array<RecommendationModel>>;
  moduleType: Scalars['String'];
  lang: LanguageEnum;
  urlSlug: Scalars['String'];
  name: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  imageDetailPath?: Maybe<Scalars['String']>;
  imagePath?: Maybe<Scalars['String']>;
  featureImagePath?: Maybe<Scalars['String']>;
  hasAdvertisement?: Maybe<Scalars['Boolean']>;
  hasNewsletter?: Maybe<Scalars['Boolean']>;
};

export type FundGroupTranslationsHasAdvertisementArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type FundGroupTranslationsHasNewsletterArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type FundRates = {
  __typename?: 'FundRates';
  id: Scalars['ID'];
  fundId: Scalars['ID'];
  effectiveDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  rateValue?: Maybe<Scalars['Float']>;
  translations?: Maybe<Array<FundRateTranslations>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  fund: Funds;
};

export type FundRateTranslations = {
  __typename?: 'FundRateTranslations';
  id: Scalars['ID'];
  fundRateId: Scalars['ID'];
  period: Scalars['String'];
  lang: LanguageEnum;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FundRateConnection = {
  __typename?: 'FundRateConnection';
  items?: Maybe<Array<FundRates>>;
  meta: BasePaginationMeta;
};

export type FundDocumentModel = {
  __typename?: 'FundDocumentModel';
  year?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  mediaId: Scalars['String'];
  media?: Maybe<Media>;
};

export type Funds = {
  __typename?: 'Funds';
  id: Scalars['ID'];
  fundGroupId?: Maybe<Scalars['ID']>;
  chartColor?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<FundTranslations>>;
  rates?: Maybe<Array<FundRates>>;
  fundType: FundType;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: User;
  fundGroup?: Maybe<FundGroups>;
  translation?: Maybe<FundTranslations>;
};

export type FundConnection = {
  __typename?: 'FundConnection';
  items?: Maybe<Array<Funds>>;
  meta: BasePaginationMeta;
};

export type FundTranslations = {
  __typename?: 'FundTranslations';
  id: Scalars['ID'];
  fundId: Scalars['ID'];
  sectionTitle?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<FundDocumentModel>>;
  moduleType: Scalars['String'];
  lang: LanguageEnum;
  name: Scalars['String'];
  status: StatusType;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TemplateDocumentModel = {
  __typename?: 'TemplateDocumentModel';
  media?: Maybe<Media>;
  documentTitle: Scalars['String'];
  mediaId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  documentLink?: Maybe<Scalars['String']>;
  documentLinkFullPath?: Maybe<Scalars['String']>;
};

export type TemplateSectionModel = {
  __typename?: 'TemplateSectionModel';
  title: Scalars['String'];
  documents?: Maybe<Array<TemplateDocumentModel>>;
};

export type ClaimTemplate = {
  __typename?: 'ClaimTemplate';
  id: Scalars['ID'];
  sections?: Maybe<Array<TemplateSectionModel>>;
  sectionTitle: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClaimAddress = {
  __typename?: 'ClaimAddress';
  id: Scalars['ID'];
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  sectionTitle: Scalars['String'];
  title: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClaimNote = {
  __typename?: 'ClaimNote';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  sectionTitle: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Claim = {
  __typename?: 'Claim';
  id: Scalars['ID'];
  translations?: Maybe<Array<ClaimTranslation>>;
  createdBy?: Maybe<User>;
  deletedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClaimConnection = {
  __typename?: 'ClaimConnection';
  items?: Maybe<Array<Claim>>;
  meta: BasePaginationMeta;
};

export type ClaimTranslation = {
  __typename?: 'ClaimTranslation';
  id: Scalars['ID'];
  claimId: Scalars['ID'];
  templateId: Scalars['ID'];
  addressId: Scalars['ID'];
  noteId: Scalars['ID'];
  lang: LanguageEnum;
  name: Scalars['String'];
  tabIcon: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  template?: Maybe<ClaimTemplate>;
  address?: Maybe<ClaimAddress>;
  note?: Maybe<ClaimNote>;
};

export type DocumentMedia = {
  __typename?: 'DocumentMedia';
  documentTitle: Scalars['String'];
  mediaId?: Maybe<Scalars['String']>;
  documentLink?: Maybe<Scalars['String']>;
  documentLinkFullPath?: Maybe<Scalars['String']>;
  media?: Maybe<Media>;
};

export type ChangePolicyDocumentModel = {
  __typename?: 'ChangePolicyDocumentModel';
  documentMedia?: Maybe<Array<DocumentMedia>>;
  sectionTitle: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  allDocuments?: Maybe<Scalars['String']>;
  allDocumentsFullPath?: Maybe<Scalars['String']>;
};

export type ChangePolicies = {
  __typename?: 'ChangePolicies';
  id: Scalars['ID'];
  createdBy: Scalars['ID'];
  translations?: Maybe<Array<ChangePolicyTranslation>>;
  creator?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ChangePolicyTranslation = {
  __typename?: 'ChangePolicyTranslation';
  id: Scalars['ID'];
  changePolicyId?: Maybe<Scalars['ID']>;
  documents?: Maybe<Array<ChangePolicyDocumentModel>>;
  lang: LanguageEnum;
  name: Scalars['String'];
  tabIcon: Scalars['String'];
  firstStepText: Scalars['String'];
  firstStepIcon: Scalars['String'];
  secondStepText: Scalars['String'];
  secondStepIcon: Scalars['String'];
  thirdStepText: Scalars['String'];
  thirdStepIcon: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  documentLabel: Scalars['String'];
  allDocuments?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  tabIconFullPath?: Maybe<Scalars['String']>;
  firstStepIconFullPath?: Maybe<Scalars['String']>;
  secondStepIconFullPath?: Maybe<Scalars['String']>;
  thirdStepIconFullPath?: Maybe<Scalars['String']>;
};

export type ChangePolicyConnection = {
  __typename?: 'ChangePolicyConnection';
  items?: Maybe<Array<ChangePolicies>>;
  meta: BasePaginationMeta;
};

export type AdvertisementPage = {
  __typename?: 'AdvertisementPage';
  id: Scalars['ID'];
  advertisementTranslationId: Scalars['ID'];
  /** Check by url slug if module type is Static Pages */
  urlSlugs?: Maybe<Array<Scalars['String']>>;
  /** Page translation ids */
  pageTransIds?: Maybe<Array<Scalars['ID']>>;
  /** Article translation ids */
  articleTransIds?: Maybe<Array<Scalars['ID']>>;
  /** Member translation ids */
  memberTransIds?: Maybe<Array<Scalars['ID']>>;
  /** Product group translation ids */
  productGroupTransIds?: Maybe<Array<Scalars['ID']>>;
  /** Product Information Ids */
  productInformationIds?: Maybe<Array<Scalars['ID']>>;
  /** Partner group translation ids */
  partnerTransIds?: Maybe<Array<Scalars['ID']>>;
  /** Fund group translation ids */
  fundTransIds?: Maybe<Array<Scalars['ID']>>;
  advertisementTranslation?: Maybe<AdvertisementTranslation>;
  moduleType: PopupModuleEnum;
  lang: LanguageEnum;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum PopupModuleEnum {
  PAGE_BUILDER = 'PAGE_BUILDER',
  OUR_TEAM = 'OUR_TEAM',
  ARTICLE_BLOG = 'ARTICLE_BLOG',
  ARTICLE_PRESS = 'ARTICLE_PRESS',
  ARTICLE_PROMOTION = 'ARTICLE_PROMOTION',
  ARTICLE_BRANDING_CAMPAIGN = 'ARTICLE_BRANDING_CAMPAIGN',
  ARTICLE_CSR = 'ARTICLE_CSR',
  PRODUCT_GROUP = 'PRODUCT_GROUP',
  PRODUCT = 'PRODUCT',
  PARTNER = 'PARTNER',
  STATIC_PAGES = 'STATIC_PAGES',
  FUND = 'FUND',
}

export type Advertisement = {
  __typename?: 'Advertisement';
  id: Scalars['ID'];
  createdBy: Scalars['ID'];
  isPublished?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<User>;
  translations?: Maybe<Array<AdvertisementTranslation>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AdvertisementTranslation = {
  __typename?: 'AdvertisementTranslation';
  id: Scalars['ID'];
  advertisementId?: Maybe<Scalars['ID']>;
  eventTime?: Maybe<Scalars['Int']>;
  advertisementPage?: Maybe<Array<AdvertisementPage>>;
  lang: LanguageEnum;
  name: Scalars['String'];
  image: Scalars['String'];
  imageURL: Scalars['String'];
  event: PopupEventEnum;
  position: PopupPositionEnum;
  status: StatusType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  allUrlSlugs?: Maybe<Array<Scalars['String']>>;
  allPages?: Maybe<Array<AllPopupPages>>;
  imageFullPath?: Maybe<Scalars['String']>;
};

export enum PopupEventEnum {
  DISPLAY_IMMEDIATELY = 'DISPLAY_IMMEDIATELY',
  TIME_ON_PAGE = 'TIME_ON_PAGE',
}

export enum PopupPositionEnum {
  PAGE_CENTER = 'PAGE_CENTER',
  BOTTOM_LEFT_CORNER = 'BOTTOM_LEFT_CORNER',
}

export type AdvertisementConnection = {
  __typename?: 'AdvertisementConnection';
  items?: Maybe<Array<Advertisement>>;
  meta: BasePaginationMeta;
};

export type NewsletterPage = {
  __typename?: 'NewsletterPage';
  id: Scalars['ID'];
  newsletterTranslationId: Scalars['ID'];
  /** Check by url slug if module type is Static Pages */
  urlSlugs?: Maybe<Array<Scalars['String']>>;
  /** Check by url slug if module type is Static Pages */
  pageTransIds?: Maybe<Array<Scalars['ID']>>;
  /** Check by id if module type is not Static Pages */
  articleTransIds?: Maybe<Array<Scalars['ID']>>;
  /** Check by id if module type is not Static Pages */
  memberTransIds?: Maybe<Array<Scalars['ID']>>;
  /** Check by id if module type is not Static Pages */
  productGroupTransIds?: Maybe<Array<Scalars['ID']>>;
  /** Check by id if module type is not Static Pages */
  productInformationIds?: Maybe<Array<Scalars['ID']>>;
  /** Check by id if module type is not Static Pages */
  partnerTransIds?: Maybe<Array<Scalars['ID']>>;
  /** Check by id if module type is not Static Pages */
  fundTransIds?: Maybe<Array<Scalars['ID']>>;
  newsletterTranslation?: Maybe<NewsletterTranslation>;
  lang: LanguageEnum;
  moduleType: PopupModuleEnum;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type Newsletter = {
  __typename?: 'Newsletter';
  id: Scalars['ID'];
  createdBy: Scalars['ID'];
  isPublished?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<User>;
  translations?: Maybe<Array<NewsletterTranslation>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsletterTranslation = {
  __typename?: 'NewsletterTranslation';
  id: Scalars['ID'];
  newsletterId?: Maybe<Scalars['ID']>;
  eventTime?: Maybe<Scalars['Int']>;
  newsletterPage?: Maybe<Array<NewsletterPage>>;
  lang: LanguageEnum;
  name: Scalars['String'];
  image: Scalars['String'];
  layer?: Maybe<Scalars['String']>;
  header: Scalars['String'];
  inforNameText?: Maybe<Scalars['String']>;
  inforNameIcon?: Maybe<Scalars['String']>;
  inforPhoneText?: Maybe<Scalars['String']>;
  inforPhoneIcon?: Maybe<Scalars['String']>;
  inforEmailText?: Maybe<Scalars['String']>;
  inforEmailIcon?: Maybe<Scalars['String']>;
  inforCityText?: Maybe<Scalars['String']>;
  inforCityIcon?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonIcon?: Maybe<Scalars['String']>;
  buttonBackground?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  event: PopupEventEnum;
  position: PopupPositionEnum;
  status: StatusType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  allUrlSlugs?: Maybe<Array<Scalars['String']>>;
  allPages?: Maybe<Array<AllPopupPages>>;
  imageFullPath?: Maybe<Scalars['String']>;
  buttonIconFullPath?: Maybe<Scalars['String']>;
  inforNameIconFullPath?: Maybe<Scalars['String']>;
  inforCityIconFullPath?: Maybe<Scalars['String']>;
  inforPhoneIconFullPath?: Maybe<Scalars['String']>;
  inforEmailIconFullPath?: Maybe<Scalars['String']>;
};

export type NewsletterConnection = {
  __typename?: 'NewsletterConnection';
  items?: Maybe<Array<Newsletter>>;
  meta: BasePaginationMeta;
};

export type Cookies = {
  __typename?: 'Cookies';
  id: Scalars['ID'];
  createdBy: Scalars['ID'];
  isPublished?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<User>;
  translations?: Maybe<Array<CookiesTranslation>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CookiesTranslation = {
  __typename?: 'CookiesTranslation';
  id: Scalars['ID'];
  cookiesId?: Maybe<Scalars['ID']>;
  lang: LanguageEnum;
  name: Scalars['String'];
  description: Scalars['String'];
  icon: Scalars['String'];
  background: Scalars['String'];
  buttonText: Scalars['String'];
  buttonBackground: Scalars['String'];
  status: StatusType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  iconFullPath?: Maybe<Scalars['String']>;
};

export type CookiesConnection = {
  __typename?: 'CookiesConnection';
  items?: Maybe<Array<Cookies>>;
  meta: BasePaginationMeta;
};

export type QuestionRate = Node & {
  __typename?: 'QuestionRate';
  id: Scalars['ID'];
  proportion?: Maybe<Scalars['Int']>;
  questionOptions?: Maybe<Array<QuestionOptions>>;
  questionType: QuestionTypeEnum;
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum QuestionTypeEnum {
  AGE = 'AGE',
  GENDER = 'GENDER',
  STATUS = 'STATUS',
  KID = 'KID',
  KID_AGE = 'KID_AGE',
  LIVING = 'LIVING',
  CONCERN = 'CONCERN',
}

export type QuestionOptions = Node & {
  __typename?: 'QuestionOptions';
  id: Scalars['ID'];
  questionRateId: Scalars['ID'];
  createdBy: Scalars['ID'];
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  familyPoint?: Maybe<Scalars['Float']>;
  educationPoint?: Maybe<Scalars['Float']>;
  healthPoint?: Maybe<Scalars['Float']>;
  retirementPoint?: Maybe<Scalars['Float']>;
  dreamsPoint?: Maybe<Scalars['Float']>;
  creator?: Maybe<User>;
  questionType: QuestionTypeEnum;
  optionNameVi?: Maybe<Scalars['String']>;
  optionNameEn?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  imageFullPath?: Maybe<Scalars['String']>;
};

export type User = Node & {
  __typename?: 'User';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  roles: RoleType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  faqs: Array<Faq>;
  permissions?: Maybe<Array<PermissionModuleType>>;
};

export enum RoleType {
  ROOT = 'ROOT',
  ADMIN = 'ADMIN',
}

export enum PermissionModuleType {
  GENERAL_SETTING = 'GENERAL_SETTING',
  HOME_MANAGEMENT = 'HOME_MANAGEMENT',
  PAGE_MANAGEMENT = 'PAGE_MANAGEMENT',
  CATEGORY_MANAGEMENT = 'CATEGORY_MANAGEMENT',
  ASSET_MANAGEMENT = 'ASSET_MANAGEMENT',
  OUR_TEAM = 'OUR_TEAM',
  FUND_MANAGEMENT = 'FUND_MANAGEMENT',
  BRANCHES = 'BRANCHES',
  FINANCIAL_REPORT = 'FINANCIAL_REPORT',
  ARTICLES = 'ARTICLES',
  PRODUCT_MANAGEMENT = 'PRODUCT_MANAGEMENT',
  CUSTOMER_REQUEST = 'CUSTOMER_REQUEST',
  COMMENTS = 'COMMENTS',
  POLICY_CLAIM = 'POLICY_CLAIM',
  FAQ_MANAGEMENT = 'FAQ_MANAGEMENT',
  USERS = 'USERS',
  ROLE_PERMISSION = 'ROLE_PERMISSION',
  POPUP_MANAGEMENT = 'POPUP_MANAGEMENT',
  SAA_TOOL = 'SAA_TOOL',
  TRANSACTION_LOG = 'TRANSACTION_LOG',
  SERVICE_PROGRAM = 'SERVICE_PROGRAM',
}

export type UserConnection = {
  __typename?: 'UserConnection';
  items?: Maybe<Array<User>>;
  meta: BasePaginationMeta;
};

export type InquiryEAppModel = {
  __typename?: 'InquiryEAppModel';
  /** số HĐ */
  policyNum?: Maybe<Scalars['String']>;
  /** số HSYCBH */
  pagNoId?: Maybe<Scalars['String']>;
  /** số HSYCBH */
  status?: Maybe<Scalars['String']>;
  /** Mã sản phẩm BH */
  pmId?: Maybe<Scalars['String']>;
  /** Tên SP BH */
  productName?: Maybe<Scalars['String']>;
  holderBP?: Maybe<Scalars['String']>;
  /** Tên bên mua BH */
  holderName?: Maybe<Scalars['String']>;
  /** Ngày sinh bên mua BH */
  holderDoB?: Maybe<Scalars['String']>;
  insuredBP?: Maybe<Scalars['String']>;
  /** Tên người được BH */
  insuredName?: Maybe<Scalars['String']>;
  /** Ngày sinh người được BH */
  insuredDoB?: Maybe<Scalars['String']>;
  /** Phí lần đầu tạm tính */
  paymentFrequency?: Maybe<Scalars['String']>;
  /** Phí lần đầu tạm tính */
  premAfterTaxAm?: Maybe<Scalars['String']>;
  topUp?: Maybe<Scalars['String']>;
  totalPayAm?: Maybe<Scalars['String']>;
  addField01?: Maybe<Scalars['String']>;
  addField02?: Maybe<Scalars['String']>;
  addField03?: Maybe<Scalars['String']>;
  addField04?: Maybe<Scalars['String']>;
  addField05?: Maybe<Scalars['String']>;
  addField06?: Maybe<Scalars['String']>;
  inquiryId?: Maybe<Scalars['String']>;
  systemDate?: Maybe<Scalars['String']>;
  systemTime?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type DuePremiumModel = {
  __typename?: 'DuePremiumModel';
  premiumType?: Maybe<Scalars['String']>;
  dueFromDate?: Maybe<Scalars['String']>;
  dueToDate?: Maybe<Scalars['String']>;
  dueAmount?: Maybe<Scalars['String']>;
};

export type InquiryPolicyModel = {
  __typename?: 'InquiryPolicyModel';
  policyStt?: Maybe<Scalars['String']>;
  policyNum?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  bpHolderId?: Maybe<Scalars['String']>;
  bpHolderName?: Maybe<Scalars['String']>;
  idType?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  inquiryDate?: Maybe<Scalars['String']>;
  policyEffDate?: Maybe<Scalars['String']>;
  payFrequentText?: Maybe<Scalars['String']>;
  waers?: Maybe<Scalars['String']>;
  feeAmt?: Maybe<Scalars['String']>;
  overDueAmt?: Maybe<Scalars['String']>;
  suspenseAmt?: Maybe<Scalars['String']>;
  paymentAmt?: Maybe<Scalars['String']>;
  paymentMinAmt?: Maybe<Scalars['String']>;
  insDurationYear?: Maybe<Scalars['String']>;
  bpMainInsuredId?: Maybe<Scalars['String']>;
  bpMainInsuredName?: Maybe<Scalars['String']>;
  duePremium?: Maybe<Array<DuePremiumModel>>;
  systemDate?: Maybe<Scalars['String']>;
  systemTime?: Maybe<Scalars['String']>;
  addField1?: Maybe<Scalars['String']>;
  addField2?: Maybe<Scalars['String']>;
  addField3?: Maybe<Scalars['String']>;
  addField4?: Maybe<Scalars['String']>;
  addField5?: Maybe<Scalars['String']>;
  addField6?: Maybe<Scalars['String']>;
  addField7?: Maybe<Scalars['String']>;
  addField8?: Maybe<Scalars['String']>;
  payerPhone?: Maybe<Scalars['String']>;
  payerEmail?: Maybe<Scalars['String']>;
};

export type InquiryTopUpModel = {
  __typename?: 'InquiryTopUpModel';
  policyStt?: Maybe<Scalars['String']>;
  policyNum?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  bpHolderId?: Maybe<Scalars['String']>;
  bpHolderName?: Maybe<Scalars['String']>;
  bpMainInsuredId?: Maybe<Scalars['String']>;
  bpMainInsuredName?: Maybe<Scalars['String']>;
  topupAmount?: Maybe<Scalars['String']>;
  topupMinAmt?: Maybe<Scalars['String']>;
  topupMaxAmt?: Maybe<Scalars['String']>;
  waers?: Maybe<Scalars['String']>;
  systemDate?: Maybe<Scalars['String']>;
  systemTime?: Maybe<Scalars['String']>;
  payerPhone?: Maybe<Scalars['String']>;
  payerEmail?: Maybe<Scalars['String']>;
};

export type CreateOrderSuccess = {
  __typename?: 'CreateOrderSuccess';
  orderId: Scalars['String'];
  mrcOrderId: Scalars['String'];
  redirectUrl: Scalars['String'];
  paymentUrl: Scalars['String'];
};

export type BaoKimWebHookOrder = {
  __typename?: 'BaoKimWebHookOrder';
  bpm_id?: Maybe<Scalars['Int']>;
  accept_qrpay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  mrc_order_id: Scalars['String'];
  txn_id: Scalars['String'];
  ref_no?: Maybe<Scalars['String']>;
  deposit_id?: Maybe<Scalars['String']>;
  merchant_id?: Maybe<Scalars['String']>;
  total_amount?: Maybe<Scalars['String']>;
  shipping_fee?: Maybe<Scalars['String']>;
  tax_fee?: Maybe<Scalars['String']>;
  mrc_fee?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url_success?: Maybe<Scalars['String']>;
  url_cancel?: Maybe<Scalars['String']>;
  url_detail?: Maybe<Scalars['String']>;
  stat?: Maybe<Scalars['String']>;
  payment_version?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type BaoKimWebhookTxn = {
  __typename?: 'BaoKimWebhookTxn';
  type?: Maybe<Scalars['Int']>;
  stat?: Maybe<Scalars['Int']>;
  is_processed?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  user_id?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['String']>;
  opening_balance?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  opening_freeze_balance?: Maybe<Scalars['String']>;
  freeze_amount?: Maybe<Scalars['String']>;
  freeze_balance?: Maybe<Scalars['String']>;
  ref_no?: Maybe<Scalars['String']>;
  bank_ref_no?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fee_amount?: Maybe<Scalars['String']>;
  src_des?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type BankInformation = {
  __typename?: 'BankInformation';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['Float'];
  bankId: Scalars['String'];
  bankName: Scalars['String'];
  bankShortName: Scalars['String'];
  logo: Scalars['String'];
};

export type AtmData = {
  __typename?: 'ATMData';
  data: Array<BankInformation>;
  isEnable: Scalars['Boolean'];
};

export type VisaData = {
  __typename?: 'VisaData';
  data: Array<BankInformation>;
  isEnable: Scalars['Boolean'];
};

export type BpmList = {
  __typename?: 'BPMList';
  atmData: AtmData;
  visaData: VisaData;
};

export type InitPaymentDataModel = {
  __typename?: 'InitPaymentDataModel';
  clientType?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
};

export type MessageDetailModel = {
  __typename?: 'MessageDetailModel';
  field?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  message: Scalars['String'];
};

export type InitPaymentResponseModel = {
  __typename?: 'InitPaymentResponseModel';
  data?: Maybe<InitPaymentDataModel>;
  msgSts: MessageDetailModel;
};

export type MomoResponseModel = {
  __typename?: 'MomoResponseModel';
  requestId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  reference1?: Maybe<Scalars['String']>;
  reference2?: Maybe<Scalars['String']>;
  resultCode?: Maybe<Scalars['Float']>;
  paymentId?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  creditAmount?: Maybe<Scalars['Float']>;
  encryptedData?: Maybe<Scalars['String']>;
};

export type CheckCallbackResponseModel = {
  __typename?: 'CheckCallbackResponseModel';
  msgSts: MessageDetailModel;
  momoResponse?: Maybe<MomoResponseModel>;
};

export type AdminPermissions = {
  __typename?: 'AdminPermissions';
  id: Scalars['ID'];
  modules?: Maybe<Array<PermissionModuleType>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LogoutModel = {
  __typename?: 'LogoutModel';
  isDeleted: Scalars['Boolean'];
};

/** AuthConnection */
export type AuthConnection = {
  __typename?: 'AuthConnection';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: User;
};

export type MediaCapability = {
  __typename?: 'MediaCapability';
  canCopy?: Maybe<Scalars['Boolean']>;
  canDelete?: Maybe<Scalars['Boolean']>;
  canDownload?: Maybe<Scalars['Boolean']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  canRename?: Maybe<Scalars['Boolean']>;
};

export type BranchTypes = {
  __typename?: 'BranchTypes';
  id: Scalars['ID'];
  translations?: Maybe<Array<BranchTypeTranslations>>;
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  translation?: Maybe<BranchTypeTranslations>;
};

export type BranchTypeTranslations = {
  __typename?: 'BranchTypeTranslations';
  id: Scalars['ID'];
  branchTypeId: Scalars['ID'];
  name: Scalars['String'];
  lang: LanguageEnum;
};

export type Branches = {
  __typename?: 'Branches';
  id: Scalars['ID'];
  branchTypeId?: Maybe<Scalars['ID']>;
  translations?: Maybe<Array<BranchTranslations>>;
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  province?: Maybe<Provinces>;
  district?: Maybe<Districts>;
  ward?: Maybe<Wards>;
  branchType?: Maybe<BranchTypes>;
  translation?: Maybe<BranchTranslations>;
};

export type BranchConnection = {
  __typename?: 'BranchConnection';
  items?: Maybe<Array<Branches>>;
  meta: BasePaginationMeta;
};

export type BranchTranslations = {
  __typename?: 'BranchTranslations';
  id: Scalars['ID'];
  branchId: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  lang: LanguageEnum;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Wards = {
  __typename?: 'Wards';
  id: Scalars['ID'];
  name: Scalars['String'];
  prefix: Scalars['String'];
  district: Districts;
  province: Provinces;
};

export type Districts = {
  __typename?: 'Districts';
  id: Scalars['ID'];
  name: Scalars['String'];
  prefix: Scalars['String'];
  province: Provinces;
  wards: Array<Wards>;
};

export type OptionPoints = {
  __typename?: 'OptionPoints';
  familyPoint?: Maybe<Scalars['Float']>;
  educationPoint?: Maybe<Scalars['Float']>;
  healthPoint?: Maybe<Scalars['Float']>;
  retirementPoint?: Maybe<Scalars['Float']>;
  dreamsPoint?: Maybe<Scalars['Float']>;
};

export type ChildrenInformation = {
  __typename?: 'ChildrenInformation';
  fullName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
};

export type PartnerInformation = {
  __typename?: 'PartnerInformation';
  fullName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
};

export type DomainSolution = {
  __typename?: 'DomainSolution';
  childrenInformation?: Maybe<Array<ChildrenInformation>>;
  partnerInformation?: Maybe<PartnerInformation>;
  totalCost?: Maybe<Scalars['String']>;
  solutionCalculation?: Maybe<Scalars['String']>;
  suggestSaving?: Maybe<Scalars['String']>;
};

export type DomainInformation = {
  __typename?: 'DomainInformation';
  domainType?: Maybe<DomainTypeEnum>;
  kidJob?: Maybe<KidJobEnum>;
  kidStudy?: Maybe<KidStudyEnum>;
  supportYear?: Maybe<FamilySupportYearEnum>;
  supportMoney?: Maybe<Scalars['String']>;
  healthWorry?: Maybe<HealthWorryEnum>;
  policyCover?: Maybe<PolicyCoverEnum>;
  retirementPlan?: Maybe<RetirementPlanEnum>;
  retirementPlace?: Maybe<RetirementPlaceEnum>;
  retirementWork?: Maybe<RetirementWorkEnum>;
  yourDream?: Maybe<YourDreamEnum>;
  dreamComeTrue?: Maybe<DreamComeTrueYearEnum>;
  totalIncome?: Maybe<Scalars['String']>;
  currentSaving?: Maybe<Scalars['String']>;
  totalCost?: Maybe<Scalars['String']>;
  solutionCalculation?: Maybe<Scalars['String']>;
  suggestSaving?: Maybe<Scalars['String']>;
};

export enum DomainTypeEnum {
  DREAM = 'DREAM',
  CHILDREN = 'CHILDREN',
  HEALTH = 'HEALTH',
  FAMILY = 'FAMILY',
  RETIREMENT = 'RETIREMENT',
}

export enum KidJobEnum {
  DOCTOR = 'DOCTOR',
  LAWYER = 'LAWYER',
  TEACHER = 'TEACHER',
  ARTIST = 'ARTIST',
  GOVERNMENT_OFFICER = 'GOVERNMENT_OFFICER',
  BUSINESS_LEADER = 'BUSINESS_LEADER',
}

export enum KidStudyEnum {
  AUSTRALIA = 'AUSTRALIA',
  VIETNAM = 'VIETNAM',
  EUROPE = 'EUROPE',
  SOUTHEAST_ASIA = 'SOUTHEAST_ASIA',
  AMERICA = 'AMERICA',
}

export enum FamilySupportYearEnum {
  UNDER_10_YEARS = 'UNDER_10_YEARS',
  FROM_10_15_YEARS = 'FROM_10_15_YEARS',
  FROM_15_20_YEARS = 'FROM_15_20_YEARS',
  OVER_20_YEARS = 'OVER_20_YEARS',
}

export enum HealthWorryEnum {
  CRITICAL_ILLNESS = 'CRITICAL_ILLNESS',
  ACCIDENT = 'ACCIDENT',
  POOR_QUALITY_HEALTHCARE = 'POOR_QUALITY_HEALTHCARE',
}

export enum PolicyCoverEnum {
  MY_PARENTS = 'MY_PARENTS',
  MY_CHILDREN = 'MY_CHILDREN',
  MYSELF = 'MYSELF',
  MY_PARTNER = 'MY_PARTNER',
}

export enum RetirementPlanEnum {
  UNDER_55_YEARS_OLD = 'UNDER_55_YEARS_OLD',
  FROM_55_60_YEARS_OLD = 'FROM_55_60_YEARS_OLD',
  OVER_60_YEARS_OLD = 'OVER_60_YEARS_OLD',
}

export enum RetirementPlaceEnum {
  BACK_TO_HOMETOWN = 'BACK_TO_HOMETOWN',
  STAY = 'STAY',
}

export enum RetirementWorkEnum {
  TRAVEL_WITH_PARTNER = 'TRAVEL_WITH_PARTNER',
  HAVE_FARM = 'HAVE_FARM',
  ENJOY_SOCIAL_ACTIVITIES = 'ENJOY_SOCIAL_ACTIVITIES',
  SMALL_BUSINESS = 'SMALL_BUSINESS',
}

export enum YourDreamEnum {
  HAVE_CAR = 'HAVE_CAR',
  RAISE_SUCCESSFUL_HAPPY_CHILDREN = 'RAISE_SUCCESSFUL_HAPPY_CHILDREN',
  EXPLORE_THE_WORLD = 'EXPLORE_THE_WORLD',
  OWN_HOUSE = 'OWN_HOUSE',
  SUCCESSFUL_BUSINESSPERSON = 'SUCCESSFUL_BUSINESSPERSON',
}

export enum DreamComeTrueYearEnum {
  UNDER_10_YEARS = 'UNDER_10_YEARS',
  FROM_10_15_YEARS = 'FROM_10_15_YEARS',
  FROM_15_20_YEARS = 'FROM_15_20_YEARS',
  OVER_20_YEARS = 'OVER_20_YEARS',
}

export type SurveyResult = {
  __typename?: 'SurveyResult';
  id: Scalars['ID'];
  gender?: Maybe<GenderEnum>;
  marriageStatus?: Maybe<MarriageStatusEnum>;
  partnerInformation?: Maybe<PartnerInformation>;
  numberOfChildren: Scalars['Int'];
  childrenInformation?: Maybe<Array<ChildrenInformation>>;
  livingOptionId: Scalars['ID'];
  concernOptionIds?: Maybe<Array<Scalars['String']>>;
  domainPriorityId: Scalars['ID'];
  domainInformation?: Maybe<DomainInformation>;
  customerRequestId?: Maybe<Scalars['ID']>;
  customerRequest?: Maybe<CustomerRequest>;
  lang: LanguageEnum;
  fullname: Scalars['String'];
  dateOfBirth: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  livingOption?: Maybe<QuestionOptions>;
  concernOptions?: Maybe<Array<QuestionOptions>>;
  domain?: Maybe<DomainEntity>;
  products?: Maybe<Array<Product>>;
};

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum MarriageStatusEnum {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
}

export type SurveyResultConnection = {
  __typename?: 'SurveyResultConnection';
  items?: Maybe<Array<SurveyResult>>;
  meta: BasePaginationMeta;
};

export type CustomerRequest = {
  __typename?: 'CustomerRequest';
  id: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isCustomer?: Maybe<Scalars['Boolean']>;
  contractNumber?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  isReceiveEmail?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Provinces>;
  status: RequestStatus;
  requestType: CustomerRequestType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum RequestStatus {
  NEW = 'NEW',
  CONSULTED = 'CONSULTED',
}

export enum CustomerRequestType {
  CUSTOMER_REQUEST = 'CUSTOMER_REQUEST',
  NEWSLETTER = 'NEWSLETTER',
}

export type CustomerRequestConnection = {
  __typename?: 'CustomerRequestConnection';
  items?: Maybe<Array<CustomerRequest>>;
  meta: BasePaginationMeta;
};

/** ExportRequestList */
export type ExportRequestList = {
  __typename?: 'ExportRequestList';
  url?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Provinces = {
  __typename?: 'Provinces';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  districts: Array<Districts>;
};

export type ProductGeneralInfo = {
  __typename?: 'ProductGeneralInfo';
  id: Scalars['ID'];
  categoryId?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationSettingModel = {
  __typename?: 'NotificationSettingModel';
  emailList?: Maybe<Array<Scalars['String']>>;
};

export type PolicyDocumentModel = {
  __typename?: 'PolicyDocumentModel';
  name: Scalars['String'];
  documentLink: Scalars['String'];
  documentLinkFullPath?: Maybe<Scalars['String']>;
};

export type Policy = {
  __typename?: 'Policy';
  id: Scalars['ID'];
  policyGroupId: Scalars['ID'];
  createdBy: Scalars['ID'];
  policyGroup?: Maybe<PolicyGroup>;
  translations?: Maybe<Array<PolicyTranslation>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PolicyTranslation = {
  __typename?: 'PolicyTranslation';
  id: Scalars['ID'];
  policyId?: Maybe<Scalars['ID']>;
  documents?: Maybe<Array<PolicyDocumentModel>>;
  lang: LanguageEnum;
  name: Scalars['String'];
  notice: Scalars['String'];
  allDocuments?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PolicyGroup = {
  __typename?: 'PolicyGroup';
  id: Scalars['ID'];
  createdBy: Scalars['ID'];
  policies?: Maybe<Array<Policy>>;
  translations?: Maybe<Array<PolicyGroupTranslation>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PolicyGroupTranslation = {
  __typename?: 'PolicyGroupTranslation';
  id: Scalars['ID'];
  policyGroupId?: Maybe<Scalars['ID']>;
  lang: LanguageEnum;
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type Partners = {
  __typename?: 'Partners';
  id: Scalars['ID'];
  translations?: Maybe<Array<PartnerTranslation>>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  translation?: Maybe<PartnerTranslation>;
};

export type PartnerTranslation = {
  __typename?: 'PartnerTranslation';
  id: Scalars['ID'];
  partnerId: Scalars['ID'];
  brandImage?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  featureImage?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  lang: LanguageEnum;
  urlSlug: Scalars['String'];
  pageUrl: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  urlSlugPath?: Maybe<Scalars['String']>;
  brandImagePath?: Maybe<Scalars['String']>;
  featureImagePath?: Maybe<Scalars['String']>;
  imagePath?: Maybe<Scalars['String']>;
  bannerImagePath?: Maybe<Scalars['String']>;
  hasAdvertisement?: Maybe<Scalars['Boolean']>;
  hasNewsletter?: Maybe<Scalars['Boolean']>;
};

export type PartnerTranslationHasAdvertisementArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type PartnerTranslationHasNewsletterArgs = {
  lang?: Maybe<LanguageEnum>;
};

export type ClaimGeneralInfo = {
  __typename?: 'ClaimGeneralInfo';
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  category: Category;
  lang: LanguageEnum;
  title: Scalars['String'];
  bannerImage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  bannerImagePath?: Maybe<Scalars['String']>;
  featureImagePath?: Maybe<Scalars['String']>;
};

export type PolicyGeneralInfo = {
  __typename?: 'PolicyGeneralInfo';
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  category: Category;
  lang: LanguageEnum;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  featureImagePath?: Maybe<Scalars['String']>;
};

export type FaqGeneralInfo = {
  __typename?: 'FaqGeneralInfo';
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  category: Category;
  lang: LanguageEnum;
  title: Scalars['String'];
  bannerImage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  bannerImagePath?: Maybe<Scalars['String']>;
  featureImagePath?: Maybe<Scalars['String']>;
};

export type DomainEntity = Node & {
  __typename?: 'DomainEntity';
  id: Scalars['ID'];
  domainType?: Maybe<DomainTypeEnum>;
  productIds?: Maybe<Array<Scalars['ID']>>;
  image?: Maybe<Scalars['String']>;
  domainNameVi?: Maybe<Scalars['String']>;
  domainNameEn?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  imageFullPath?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Product>>;
};

/** Service Program Section 1 */
export type ServiceInformation = {
  __typename?: 'ServiceInformation';
  id: Scalars['ID'];
  customerType: CustomerType;
  serviceProgramId?: Maybe<Scalars['ID']>;
  tabName?: Maybe<Scalars['String']>;
  tabIcon?: Maybe<Scalars['String']>;
  tabDescription?: Maybe<Scalars['String']>;
  tabIconFullPath?: Maybe<Scalars['String']>;
};

export enum CustomerType {
  PERSONALE = 'PERSONALE',
  BUSINESS = 'BUSINESS',
}

export type CustomerDetail = {
  __typename?: 'CustomerDetail';
  title?: Maybe<Scalars['String']>;
  titleBackground?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueBackground?: Maybe<Scalars['String']>;
  orderPosition?: Maybe<Scalars['Int']>;
};

/** Service Program Section 2 */
export type CustomerHierarchy = {
  __typename?: 'CustomerHierarchy';
  id: Scalars['ID'];
  customerType: CustomerType;
  serviceProgramId?: Maybe<Scalars['ID']>;
  sectionTitle?: Maybe<Scalars['String']>;
  customerDetail?: Maybe<Array<CustomerDetail>>;
  sectionNote?: Maybe<Scalars['String']>;
};

export type GiftTableHeader = {
  __typename?: 'GiftTableHeader';
  headerTitle: Scalars['String'];
  headerAlias: Scalars['String'];
  headerBackground: Scalars['String'];
};

export type GiftTableRow = {
  __typename?: 'GiftTableRow';
  rowId: Scalars['Int'];
  rowContent: Scalars['String'];
  headerAlias: Scalars['String'];
};

export type GiftList = {
  __typename?: 'GiftList';
  customerType: CustomerType;
  year: Scalars['Int'];
  tableHeader: Array<GiftTableHeader>;
  tableRows: Array<GiftTableRow>;
};

/** Service Program Section 3 */
export type CustomerGift = {
  __typename?: 'CustomerGift';
  id: Scalars['ID'];
  customerType: CustomerType;
  sectionTitle?: Maybe<Scalars['String']>;
  giftList?: Maybe<Array<GiftList>>;
  showAllGifts?: Maybe<Scalars['Boolean']>;
  serviceProgramId?: Maybe<Scalars['ID']>;
};

export type StepDetail = {
  __typename?: 'StepDetail';
  stepInfo?: Maybe<Scalars['String']>;
  orderPosition?: Maybe<Scalars['Int']>;
};

/** Service Program Section 3 */
export type GiftStep = {
  __typename?: 'GiftStep';
  id: Scalars['ID'];
  serviceProgramId?: Maybe<Scalars['ID']>;
  sectionTitle?: Maybe<Scalars['String']>;
  sectionIllustration?: Maybe<Scalars['String']>;
  stepDetail?: Maybe<Array<StepDetail>>;
  sectionIllustrationFullPath?: Maybe<Scalars['String']>;
};

export type GiftInformation = {
  __typename?: 'GiftInformation';
  giftTitle?: Maybe<Scalars['String']>;
  giftLogo?: Maybe<Scalars['String']>;
  giftDescription?: Maybe<Scalars['String']>;
  orderPosition?: Maybe<Scalars['Int']>;
};

/** Service Program Section 4 */
export type GiftDetail = {
  __typename?: 'GiftDetail';
  id: Scalars['ID'];
  serviceProgramId?: Maybe<Scalars['ID']>;
  sectionTitle?: Maybe<Scalars['String']>;
  sectionNote?: Maybe<Scalars['String']>;
  giftInformation?: Maybe<Array<GiftInformation>>;
};

export type FaqDetail = {
  __typename?: 'FAQDetail';
  faqTitle?: Maybe<Scalars['String']>;
  faqDescription?: Maybe<Scalars['String']>;
  orderPosition?: Maybe<Scalars['Int']>;
};

/** Service Program Section 5 */
export type ServiceFaq = {
  __typename?: 'ServiceFAQ';
  id: Scalars['ID'];
  serviceProgramId?: Maybe<Scalars['ID']>;
  sectionTitle?: Maybe<Scalars['String']>;
  faqDetail?: Maybe<Array<FaqDetail>>;
};

/** Service Program General Info */
export type ServiceProgram = {
  __typename?: 'ServiceProgram';
  id: Scalars['ID'];
  desktopBanner?: Maybe<Scalars['String']>;
  mobileBanner?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  serviceInformation?: Maybe<Array<ServiceInformation>>;
  customerHierarchy?: Maybe<Array<CustomerHierarchy>>;
  customerGift?: Maybe<Array<CustomerGift>>;
  giftSteps?: Maybe<Array<GiftStep>>;
  giftDetail?: Maybe<Array<GiftDetail>>;
  serviceFAQs?: Maybe<Array<ServiceFaq>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  desktopBannerFullPath?: Maybe<Scalars['String']>;
  mobileBannerFullPath?: Maybe<Scalars['String']>;
  featureImageFullPath?: Maybe<Scalars['String']>;
};

export type MenuModel = {
  __typename?: 'MenuModel';
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  megaMenu?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['ID']>;
  pageTranslationId?: Maybe<Scalars['ID']>;
  children?: Maybe<Array<MenuModel>>;
  page?: Maybe<PageEntity>;
  pageTranslation?: Maybe<PageTranslation>;
};

export type ArticleListModel = {
  __typename?: 'ArticleListModel';
  title: ArticleType;
  value?: Maybe<Scalars['String']>;
  children: Array<Articles>;
};

export type SiteMapModel = {
  __typename?: 'SiteMapModel';
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['ID']>;
  pageTranslationId?: Maybe<Scalars['ID']>;
  children?: Maybe<Array<SiteMapModel>>;
  page?: Maybe<PageEntity>;
  pageTranslation?: Maybe<PageTranslation>;
};

export type FooterModel = {
  __typename?: 'FooterModel';
  id: Scalars['ID'];
  logo: Scalars['String'];
  address: Scalars['String'];
  hotline: Scalars['String'];
  email: Scalars['String'];
  lang: LanguageEnum;
  sns?: Maybe<Array<FieldModel>>;
  shortcuts?: Maybe<Array<FieldModel>>;
  logoPath?: Maybe<Scalars['String']>;
};

export type FieldModel = {
  __typename?: 'FieldModel';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type HeaderModel = {
  __typename?: 'HeaderModel';
  id: Scalars['ID'];
  partners?: Maybe<Array<PartnerModel>>;
  lang: LanguageEnum;
  logo: Scalars['String'];
  hotline: Scalars['String'];
  buttonName: Scalars['String'];
  buttonURL: Scalars['String'];
  buttonBorderColor: Scalars['String'];
  buttonNameColor: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  fullPath?: Maybe<Scalars['String']>;
};

export type PartnerModel = {
  __typename?: 'PartnerModel';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type SearchResultModel = {
  __typename?: 'SearchResultModel';
  id: Scalars['ID'];
  moduleType: ModuleType;
  lang: LanguageEnum;
  pageTitle: Scalars['String'];
  urlSlug: Scalars['String'];
  content: Scalars['String'];
  highlightTitle?: Maybe<Scalars['String']>;
  highlightContent?: Maybe<Scalars['String']>;
  category?: Maybe<CategoryTranslations>;
  isPage?: Maybe<Scalars['Boolean']>;
};

export type SearchConnection = {
  __typename?: 'SearchConnection';
  items?: Maybe<Array<SearchResultModel>>;
  meta: BasePaginationMeta;
};

export type FinancialFilesInput = {
  __typename?: 'FinancialFilesInput';
  file?: Maybe<Scalars['String']>;
  fileFullPath?: Maybe<Scalars['String']>;
};

export type ReportsModel = {
  __typename?: 'ReportsModel';
  year?: Maybe<Scalars['String']>;
  files?: Maybe<Array<FinancialFilesInput>>;
};

export type AllPopupPages = {
  __typename?: 'AllPopupPages';
  id?: Maybe<Scalars['ID']>;
  moduleType?: Maybe<PopupModuleEnum>;
  name?: Maybe<Scalars['String']>;
  hasNewsletter?: Maybe<Scalars['Boolean']>;
  hasAdvertisement?: Maybe<Scalars['Boolean']>;
  urlSlug?: Maybe<Scalars['String']>;
};

export type StaticPageDetailModel = {
  __typename?: 'StaticPageDetailModel';
  hasAdvertisement?: Maybe<Scalars['Boolean']>;
  hasNewsletter?: Maybe<Scalars['Boolean']>;
  urlSlug: Scalars['String'];
  pageTitle: Scalars['String'];
};

export type DataStaticPagesModel = {
  __typename?: 'DataStaticPagesModel';
  lang?: Maybe<LanguageEnum>;
  pages?: Maybe<Array<StaticPageDetailModel>>;
};

export type Query = {
  __typename?: 'Query';
  /** Get Payment Info: BP Detail */
  checkBPInfor: Scalars['Boolean'];
  /** Get Payment Info: Inquiry E-App */
  getInquiryEApp: InquiryEAppModel;
  /** Get Payment Info: Inquiry Policy */
  getInquiryPolicy: Array<InquiryPolicyModel>;
  /** Get Payment Info: Inquiry Topup */
  getInquiryTopup?: Maybe<InquiryTopUpModel>;
  /** Get Bank List From Bao Kim API */
  getBankList?: Maybe<Array<BankInformation>>;
  /** Get BPM List From Bao Kim API */
  getBPMList?: Maybe<BpmList>;
  /** List transaction log */
  transactionLogs?: Maybe<TransactionLogConnection>;
  /** Export Transaction Log Excel */
  exportTransactionLog?: Maybe<ExportTransactionLogList>;
  /** Get user list pagination BE  */
  users?: Maybe<UserConnection>;
  user?: Maybe<User>;
  getPermissions?: Maybe<AdminPermissions>;
  me: User;
  /** assets management */
  medias?: Maybe<MediaConnection>;
  /** media detail */
  media?: Maybe<Media>;
  /** Get Home Menu */
  getHomeMenu: Array<MenuModel>;
  /** Get Home Menu */
  menuHome: Array<SiteMapModel>;
  /** Get article general info */
  articleGeneralInfo?: Maybe<ArticleGeneralInfo>;
  /** Get admin article general info */
  adminArticleGeneralInfo?: Maybe<ArticleGeneralInfo>;
  /** Get articles */
  articles?: Maybe<Array<Articles>>;
  /** Get articles for admin */
  adminArticle?: Maybe<Array<ArticleListModel>>;
  /** Get paging Articles */
  getPagingArticle?: Maybe<ArticleConnection>;
  /** Get paging Articles for admin */
  getPagingArticleAdmin?: Maybe<ArticleConnection>;
  /** Get article details */
  article?: Maybe<Articles>;
  /** Get Paging Category */
  getPagingCategory?: Maybe<CategoryConnection>;
  /** Get all Category */
  getAllCategory?: Maybe<Array<Category>>;
  /** Get details Category */
  getDetailsCategory?: Maybe<Category>;
  /** article group detail by id */
  articleGroup: ArticleGroup;
  /** List article group - not pagination */
  articleGroups: Array<ArticleGroup>;
  /** Get members */
  members?: Maybe<Array<Members>>;
  /** Get member details */
  member?: Maybe<Members>;
  /** Get member general info */
  memberGeneralInfo?: Maybe<MemberGeneralInfo>;
  /** Get admin member general info */
  adminMemberGeneralInfo?: Maybe<MemberGeneralInfo>;
  /** get list member create from page builder */
  membersFromPage: Array<Members>;
  /** get list member create from page builder */
  membersFromSystemPage: Array<Members>;
  /** Get product general info */
  getProductGeneralInfo?: Maybe<ProductGeneralInfo>;
  /** product group detail by id */
  productGroup: ProductGroup;
  /** List product group - not pagination */
  productGroups: Array<ProductGroup>;
  /** List product group - not pagination - BE Dashboard */
  productGroupsDashboard: Array<ProductGroup>;
  /** List product by Url Slug - not pagination */
  productGroupsBySlug: Array<ProductGroup>;
  /** product group detail by url slug */
  productGroupBySlug?: Maybe<ProductGroup>;
  /** Get product detail */
  product: Product;
  /** Get product detail by slug */
  productBySlug?: Maybe<Product>;
  /** Get product list by slug of product group  */
  productByGroupSlug?: Maybe<Array<Product>>;
  /** Get product list pagination BE  */
  products?: Maybe<ProductConnection>;
  /** Get list product information dropdown list */
  listProducts?: Maybe<ProductInformationConnection>;
  /** Get all Page */
  getAllPage: Array<PageTranslation>;
  /** Get list all Pages with search sort filter */
  pages: PageTransConnection;
  /** Get page detail */
  page: PageTranslation;
  /** Get page detail by slug */
  pageBySlug: PageTranslation;
  /** Count total trash page */
  countPageTrash: Scalars['Float'];
  /** get published home page */
  homePage: PageTranslation;
  /** list page by lang and not paginate */
  pageList: Array<PageTranslation>;
  /** check unique slug */
  checkUniqueSlug: Scalars['Boolean'];
  /** Get all province */
  provinces: Array<Provinces>;
  /** Get details province */
  province?: Maybe<Provinces>;
  /** Get all district */
  districts: Array<Districts>;
  /** Get details district */
  district?: Maybe<Districts>;
  /** Get all wards */
  wards: Array<Wards>;
  /** Get details ward */
  ward?: Maybe<Wards>;
  /** Get branches */
  branches: Array<Branches>;
  /** Get branchTypes */
  branchTypes: Array<BranchTypes>;
  /** Get branch */
  branch?: Maybe<Branches>;
  /** Get Paging Branch */
  getPagingBranch?: Maybe<BranchConnection>;
  /** Get Site Map */
  sitemaps: Array<SiteMapModel>;
  /** Get footer */
  footer?: Maybe<FooterModel>;
  /** Get footer for homepage */
  footerHome?: Maybe<FooterModel>;
  /** Get header detail */
  header?: Maybe<HeaderModel>;
  /** Get header for homepage */
  headerHome?: Maybe<HeaderModel>;
  /** Get fund general info */
  fundGeneralInfo?: Maybe<FundGeneralInfo>;
  /** Get admin fund general info */
  adminFundGeneralInfo?: Maybe<FundGeneralInfo>;
  /** Get funds */
  funds?: Maybe<Array<Funds>>;
  /** Get funds */
  fundByType?: Maybe<Array<Funds>>;
  /** Get paging funds */
  getPagingFund?: Maybe<FundConnection>;
  /** Get fund details */
  fund?: Maybe<Funds>;
  /** Get fundGroups dashboard */
  fundGroupsDashboard?: Maybe<Array<FundGroups>>;
  /** Get fundGroups */
  fundGroups?: Maybe<Array<FundGroups>>;
  /** Get paging fund groups */
  getPagingFundGroup?: Maybe<FundGroupConnection>;
  /** Get fund group details */
  fundGroup?: Maybe<FundGroups>;
  /** Get fund group details by Type */
  fundGroupByType?: Maybe<FundGroups>;
  /** Get fund rate list by fund Id - pagination */
  getPagingFundRates?: Maybe<FundRateConnection>;
  /** Get fund rate list by fund Id - not pagination */
  fundRates?: Maybe<Array<FundRates>>;
  /** Web - Get fund rate list */
  fundRatesByFund?: Maybe<Array<Funds>>;
  /** exportFundRate */
  exportFundRate?: Maybe<Scalars['String']>;
  /** Get fund rate detail */
  fundRate?: Maybe<FundRates>;
  /** Get all fund documents */
  getAllFundDocuments?: Maybe<Array<FundDocumentModel>>;
  /** Search suggestion, return max 5 records */
  searchSuggestion: Array<SearchResultModel>;
  /** Search all Product/Fund/Promotion/Press/Blog/Branding campaign/CSR */
  searchAll: SearchConnection;
  /** Get financial detail */
  financial?: Maybe<FinancialReports>;
  /** Get financial detail */
  reportList?: Maybe<Array<ReportsModel>>;
  /** Get list all Customer request with search sort filter */
  customerRequests: CustomerRequestConnection;
  /** Get detail of a Customer request */
  customerRequest?: Maybe<CustomerRequest>;
  /** Export requets list to CSV file */
  exportRequests?: Maybe<ExportRequestList>;
  /** Get notification setting emails */
  getSetting: NotificationSettingModel;
  /** Get policy group detail */
  policyGroup: PolicyGroup;
  /** Get list policy group */
  policyGroups: Array<PolicyGroup>;
  /** Get policy general info */
  policyGeneralInfo?: Maybe<Array<PolicyGeneralInfo>>;
  /** Get admin policy general info */
  adminPolicyGeneralInfo?: Maybe<Array<PolicyGeneralInfo>>;
  /** Get policy detail - Dashboard */
  policyDashboard?: Maybe<ChangePolicies>;
  /** Get policy detail - Web */
  policy?: Maybe<ChangePolicies>;
  /** Get policy list pagination - Dashboard */
  policiesDashboard: ChangePolicyConnection;
  /** Get policy list pagination - Web */
  policies: ChangePolicyConnection;
  /** Get policy list not pagination - Web */
  listPolicy?: Maybe<Array<ChangePolicies>>;
  /** Get Partners */
  partners?: Maybe<Array<Partners>>;
  /** Get Partner details */
  partner?: Maybe<Partners>;
  /** Get Paging Comment for Admin */
  getPagingComment?: Maybe<CommentConnection>;
  /** Get Paging Comment */
  getPagingCommentAdmin?: Maybe<CommentConnection>;
  /** Get claim general info */
  claimGeneralInfo?: Maybe<Array<ClaimGeneralInfo>>;
  /** Get admin claim general info */
  adminClaimGeneralInfo?: Maybe<Array<ClaimGeneralInfo>>;
  /** Get claims not paging */
  claims?: Maybe<Array<Claim>>;
  /** Get claims paging */
  getPagingClaim?: Maybe<ClaimConnection>;
  /** Get claim details */
  claim?: Maybe<Claim>;
  /** Get claims not paging for Dashboard */
  claimsDashboard?: Maybe<Array<Claim>>;
  /** Get claims paging for Dashboard */
  getPagingClaimDashboard?: Maybe<ClaimConnection>;
  /** Get claim details for Dashboard */
  claimDashboard?: Maybe<Claim>;
  /** Get faq general info */
  faqGeneralInfo?: Maybe<Array<FaqGeneralInfo>>;
  /** Get admin faq general info */
  adminFaqGeneralInfo?: Maybe<Array<FaqGeneralInfo>>;
  /** Get faqs not paging */
  faqs?: Maybe<Array<Faq>>;
  /** Get faqs paging */
  getPagingFaq?: Maybe<FaqConnection>;
  /** Get faq details */
  faq?: Maybe<Faq>;
  /** Get faqs not paging for Dashboard */
  faqsDashboard?: Maybe<Array<Faq>>;
  /** Get faqs paging for Dashboard */
  getPagingFaqDashboard?: Maybe<FaqConnection>;
  /** Get faq details for Dashboard */
  faqDashboard?: Maybe<Faq>;
  /** Get all domains */
  domains?: Maybe<Array<DomainEntity>>;
  /** Get all domains */
  domain?: Maybe<DomainEntity>;
  /** Get all question rates */
  questionRates: Array<QuestionRate>;
  /** Get question rate detail */
  questionRate?: Maybe<QuestionRate>;
  /** Get question option detail */
  questionOption?: Maybe<QuestionRate>;
  /** Get question option list by type */
  questionOptionsByType?: Maybe<Array<QuestionOptions>>;
  /** Get question option list by type */
  questionOptionsByTypeSurvey?: Maybe<Array<QuestionOptions>>;
  /** Get all domains */
  getDomainPriority?: Maybe<Array<DomainEntity>>;
  /** Get children domain suggestion */
  childrenDomain?: Maybe<DomainSolution>;
  /** Get family domain suggestion */
  familyDomain?: Maybe<DomainSolution>;
  /** Get health domain suggestion */
  healthDomain?: Maybe<DomainSolution>;
  /** Get retirement domain suggestion */
  retirementDomain?: Maybe<DomainSolution>;
  /** Get children domain suggestion */
  dreamDomain?: Maybe<DomainSolution>;
  /** Get survey detail Client */
  survey?: Maybe<SurveyResult>;
  /** Get survey detail */
  surveyDashboard?: Maybe<SurveyResult>;
  /** Get survey list backend */
  listSurveyDashboard?: Maybe<SurveyResultConnection>;
  /** Get advertisement detail */
  advertisement: Advertisement;
  /** Get advertisement detail */
  advertisements: AdvertisementConnection;
  /** Get current advertisement detail */
  getPublishAdvertisement?: Maybe<Advertisement>;
  /** Get cookies detail */
  cookie: Cookies;
  /** Get advertisement detail */
  cookies: CookiesConnection;
  /** Get current cookie detail */
  getPublishCookie?: Maybe<Cookies>;
  /** Get Newsletter detail */
  newsletter: Newsletter;
  /** Get list newsletter pagination dashboard */
  newsletters: NewsletterConnection;
  /** Get current newsletter detail */
  getPublishNewsletter?: Maybe<Newsletter>;
  /** Get all pages in system */
  getSystemPages: AllPagesModel;
  /** Get advertisement by url slug */
  getAdvertisementBySlug?: Maybe<AdvertisementTranslation>;
  /** Get Newsletter by url slug */
  getNewsletterBySlug?: Maybe<NewsletterTranslation>;
  /** Get Service Program detail */
  serviceProgram?: Maybe<ServiceProgram>;
};

export type QueryCheckBpInforArgs = {
  bpHolderId?: Maybe<Scalars['String']>;
  payerBirthday: Scalars['String'];
  bpMainInsuredId?: Maybe<Scalars['String']>;
  benificiaryBirthday: Scalars['String'];
};

export type QueryGetInquiryEAppArgs = {
  captchaToken: Scalars['String'];
  benificiaryBirthday: Scalars['String'];
  payerBirthday: Scalars['String'];
  Inquiry_Search: Scalars['String'];
};

export type QueryGetInquiryPolicyArgs = {
  IDate?: Maybe<Scalars['String']>;
  ISearch?: Maybe<Scalars['String']>;
  IType?: Maybe<InquiryPolicyType>;
  payerBirthday: Scalars['String'];
  benificiaryBirthday: Scalars['String'];
  captchaToken: Scalars['String'];
};

export type QueryGetInquiryTopupArgs = {
  IPolicy?: Maybe<Scalars['String']>;
  benificiaryBirthday: Scalars['String'];
  captchaToken: Scalars['String'];
  payerBirthday: Scalars['String'];
};

export type QueryTransactionLogsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  createdDateFrom?: Maybe<Scalars['String']>;
  createdDateTo?: Maybe<Scalars['String']>;
  payerDocumentType?: Maybe<PaymentDocumentType>;
  paymentGateway?: Maybe<PaymentGatewayType>;
};

export type QueryExportTransactionLogArgs = {
  search?: Maybe<Scalars['String']>;
  createdDateFrom?: Maybe<Scalars['String']>;
  createdDateTo?: Maybe<Scalars['String']>;
  payerDocumentType?: Maybe<PaymentDocumentType>;
  paymentGateway?: Maybe<PaymentGatewayType>;
};

export type QueryUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryUserArgs = {
  id: Scalars['String'];
};

export type QueryMediasArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<SortInput>>;
  fileType?: Maybe<FileType>;
  uploadDateFrom?: Maybe<Scalars['String']>;
  uploadDateTo?: Maybe<Scalars['String']>;
};

export type QueryMediaArgs = {
  id: Scalars['ID'];
};

export type QueryGetHomeMenuArgs = {
  lang: LanguageEnum;
};

export type QueryArticleGeneralInfoArgs = {
  type: ArticleType;
};

export type QueryAdminArticleGeneralInfoArgs = {
  lang: LanguageEnum;
  type: ArticleType;
};

export type QueryArticlesArgs = {
  type: ArticleType;
};

export type QueryAdminArticleArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryGetPagingArticleArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  articleGroupIds?: Maybe<Array<Scalars['ID']>>;
  type?: Maybe<ArticleType>;
  lang?: Maybe<LanguageEnum>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type QueryGetPagingArticleAdminArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  articleGroupIds?: Maybe<Array<Scalars['ID']>>;
  type?: Maybe<ArticleType>;
  lang?: Maybe<LanguageEnum>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type QueryArticleArgs = {
  slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  type: ArticleType;
};

export type QueryGetPagingCategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryGetDetailsCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryArticleGroupArgs = {
  id: Scalars['ID'];
};

export type QueryArticleGroupsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMemberArgs = {
  slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type QueryAdminMemberGeneralInfoArgs = {
  lang: LanguageEnum;
};

export type QueryMembersFromSystemPageArgs = {
  lang: LanguageEnum;
};

export type QueryProductGroupArgs = {
  id: Scalars['ID'];
};

export type QueryProductGroupsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryProductGroupsDashboardArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryProductGroupsBySlugArgs = {
  urlSlug: Scalars['String'];
};

export type QueryProductGroupBySlugArgs = {
  urlSlug: Scalars['String'];
};

export type QueryProductArgs = {
  id: Scalars['String'];
};

export type QueryProductBySlugArgs = {
  urlSlug: Scalars['String'];
};

export type QueryProductByGroupSlugArgs = {
  urlSlug: Scalars['String'];
};

export type QueryProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  productGroupId?: Maybe<Scalars['String']>;
  lang?: Maybe<LanguageEnum>;
  status?: Maybe<StatusType>;
};

export type QueryListProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  productGroupId?: Maybe<Scalars['String']>;
  lang?: Maybe<LanguageEnum>;
  status?: Maybe<StatusType>;
};

export type QueryPagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<SortInput>>;
  category?: Maybe<Scalars['String']>;
  createdDateFrom?: Maybe<Scalars['String']>;
  createdDateTo?: Maybe<Scalars['String']>;
  pageIds?: Maybe<Array<Scalars['String']>>;
  lang?: Maybe<LanguageEnum>;
  status?: Maybe<PageStatus>;
  pageType: PageType;
};

export type QueryPageArgs = {
  id: Scalars['String'];
};

export type QueryPageBySlugArgs = {
  isPreview?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
};

export type QueryCountPageTrashArgs = {
  pageType: PageType;
};

export type QueryPageListArgs = {
  pageId?: Maybe<Scalars['ID']>;
  pageType?: Maybe<PageType>;
  search?: Maybe<Scalars['String']>;
  lang?: Maybe<LanguageEnum>;
};

export type QueryCheckUniqueSlugArgs = {
  input: CheckSlugInput;
};

export type QueryProvincesArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryProvinceArgs = {
  id: Scalars['ID'];
};

export type QueryDistrictsArgs = {
  provinceId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryDistrictArgs = {
  id: Scalars['ID'];
};

export type QueryWardsArgs = {
  districtId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryWardArgs = {
  id: Scalars['ID'];
};

export type QueryBranchesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  provinceId?: Maybe<Scalars['ID']>;
  branchTypeId?: Maybe<Scalars['ID']>;
  districtId?: Maybe<Scalars['ID']>;
  wardId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryBranchArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryGetPagingBranchArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  provinceId?: Maybe<Scalars['ID']>;
  branchTypeId?: Maybe<Scalars['ID']>;
  districtId?: Maybe<Scalars['ID']>;
  wardId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QuerySitemapsArgs = {
  lang: LanguageEnum;
};

export type QueryFooterArgs = {
  lang: LanguageEnum;
};

export type QueryHeaderArgs = {
  lang: LanguageEnum;
};

export type QueryAdminFundGeneralInfoArgs = {
  lang: LanguageEnum;
};

export type QueryFundsArgs = {
  fundType?: Maybe<FundType>;
};

export type QueryFundByTypeArgs = {
  fundType?: Maybe<Array<FundType>>;
};

export type QueryGetPagingFundArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  fundType?: Maybe<FundType>;
  search?: Maybe<Scalars['String']>;
  emptyTranslation?: Maybe<Scalars['Boolean']>;
};

export type QueryFundArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryGetPagingFundGroupArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryFundGroupArgs = {
  urlSlug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type QueryFundGroupByTypeArgs = {
  type: FundType;
};

export type QueryGetPagingFundRatesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  fundId?: Maybe<Scalars['ID']>;
  sort?: Maybe<SortFundRate>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type QueryFundRatesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  fundId?: Maybe<Scalars['ID']>;
  sort?: Maybe<SortFundRate>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type QueryFundRatesByFundArgs = {
  fundType: FundType;
  fundId?: Maybe<Scalars['ID']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  fundIds?: Maybe<Array<Scalars['ID']>>;
};

export type QueryExportFundRateArgs = {
  fundType: FundType;
  fundId?: Maybe<Scalars['ID']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  fundIds?: Maybe<Array<Scalars['ID']>>;
};

export type QueryFundRateArgs = {
  id: Scalars['ID'];
};

export type QuerySearchSuggestionArgs = {
  keyword?: Maybe<Scalars['String']>;
  moduleType?: Maybe<SearchFilterType>;
  lang?: Maybe<LanguageEnum>;
};

export type QuerySearchAllArgs = {
  keyword?: Maybe<Scalars['String']>;
  moduleType?: Maybe<SearchFilterType>;
  lang?: Maybe<LanguageEnum>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryFinancialArgs = {
  lang: LanguageEnum;
};

export type QueryCustomerRequestsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sorts?: Maybe<SortOptionDefault>;
  status?: Maybe<RequestStatus>;
  search?: Maybe<Scalars['String']>;
  createdDateFrom?: Maybe<Scalars['String']>;
  createdDateTo?: Maybe<Scalars['String']>;
};

export type QueryCustomerRequestArgs = {
  id: Scalars['ID'];
};

export type QueryExportRequestsArgs = {
  sorts?: Maybe<SortOptionDefault>;
  status?: Maybe<RequestStatus>;
  search?: Maybe<Scalars['String']>;
  createdDateFrom?: Maybe<Scalars['String']>;
  createdDateTo?: Maybe<Scalars['String']>;
};

export type QueryPolicyGroupArgs = {
  id: Scalars['String'];
};

export type QueryPolicyDashboardArgs = {
  id: Scalars['String'];
};

export type QueryPolicyArgs = {
  id: Scalars['String'];
};

export type QueryPoliciesDashboardArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  lang?: Maybe<LanguageEnum>;
};

export type QueryPoliciesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  lang?: Maybe<LanguageEnum>;
};

export type QueryPartnerArgs = {
  slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type QueryGetPagingCommentArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  status?: Maybe<StatusComment>;
  lang?: Maybe<LanguageEnum>;
  parentId?: Maybe<Scalars['ID']>;
  articleTranslationId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryGetPagingCommentAdminArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  status?: Maybe<StatusComment>;
  lang?: Maybe<LanguageEnum>;
  parentId?: Maybe<Scalars['ID']>;
  articleTranslationId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryClaimsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryGetPagingClaimArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryClaimArgs = {
  id: Scalars['ID'];
};

export type QueryClaimsDashboardArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryGetPagingClaimDashboardArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryClaimDashboardArgs = {
  id: Scalars['ID'];
};

export type QueryFaqsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryGetPagingFaqArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryFaqArgs = {
  id: Scalars['ID'];
};

export type QueryFaqsDashboardArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryGetPagingFaqDashboardArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryFaqDashboardArgs = {
  id: Scalars['ID'];
};

export type QueryDomainArgs = {
  id: Scalars['String'];
};

export type QueryQuestionRateArgs = {
  id: Scalars['String'];
};

export type QueryQuestionOptionArgs = {
  id: Scalars['String'];
};

export type QueryQuestionOptionsByTypeArgs = {
  questionType: QuestionTypeEnum;
};

export type QueryQuestionOptionsByTypeSurveyArgs = {
  questionType: QuestionTypeEnum;
};

export type QueryGetDomainPriorityArgs = {
  input: DomainPriorityInput;
};

export type QueryChildrenDomainArgs = {
  input: ChildrenDomainInput;
};

export type QueryFamilyDomainArgs = {
  input: FamilyDomainInput;
};

export type QueryHealthDomainArgs = {
  input: HealthDomainInput;
};

export type QueryRetirementDomainArgs = {
  input: RetirementDomainInput;
};

export type QueryDreamDomainArgs = {
  input: DreamDomainInput;
};

export type QuerySurveyArgs = {
  id: Scalars['String'];
};

export type QuerySurveyDashboardArgs = {
  id: Scalars['String'];
};

export type QueryListSurveyDashboardArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  domainType?: Maybe<DomainTypeEnum>;
  search?: Maybe<Scalars['String']>;
};

export type QueryAdvertisementArgs = {
  id: Scalars['String'];
};

export type QueryAdvertisementsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryCookieArgs = {
  id: Scalars['String'];
};

export type QueryCookiesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryNewsletterArgs = {
  id: Scalars['String'];
};

export type QueryNewslettersArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryGetSystemPagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  lang: LanguageEnum;
  module: PopupModuleEnum;
};

export type QueryGetAdvertisementBySlugArgs = {
  moduleType: PopupModuleEnum;
  urlSlug: Scalars['String'];
};

export type QueryGetNewsletterBySlugArgs = {
  moduleType: PopupModuleEnum;
  urlSlug: Scalars['String'];
};

/**
 * POLICY : Policy Number
 * ZMBL01 :Birth certificate
 * ZMBL02 :Identity card
 * ZMBL03 :Citizen Identity number
 * ZMBL04 :Drive’s license
 * ZMBL15 :Business license
 * ZMBL18 :Military Identity Number
 * FS0002 :Passport
 */
export enum InquiryPolicyType {
  POLICY = 'POLICY',
  ZMBL01 = 'ZMBL01',
  ZMBL02 = 'ZMBL02',
  ZMBL03 = 'ZMBL03',
  ZMBL04 = 'ZMBL04',
  ZMBL15 = 'ZMBL15',
  ZMBL18 = 'ZMBL18',
  FS0002 = 'FS0002',
}

export type SortInput = {
  field: Scalars['String'];
  direction?: Maybe<Scalars['String']>;
};

/** check Unique slug */
export type CheckSlugInput = {
  id?: Maybe<Scalars['ID']>;
  urlSlug: Scalars['String'];
  pageType?: Maybe<PageType>;
};

export enum SortFundRate {
  OLDEST = 'OLDEST',
  NEWEST = 'NEWEST',
  RATE_ASC = 'RATE_ASC',
  RATE_DESC = 'RATE_DESC',
}

export enum SearchFilterType {
  ALL = 'ALL',
  PRODUCT = 'PRODUCT',
  FUND = 'FUND',
  ARTICLE = 'ARTICLE',
}

/** List Sort Option Action Log Type */
export enum SortOptionDefault {
  ASC = 'ASC',
  DESC = 'DESC',
  DEFAULT = 'DEFAULT',
  OTHER = 'OTHER',
}

export type DomainPriorityInput = {
  numberOfChildren: Scalars['Int'];
  childrenInformation?: Maybe<Array<ChildrenInformationInput>>;
  concernOptionIds: Array<Scalars['ID']>;
  gender: GenderEnum;
  dateOfBirth: Scalars['DateTime'];
  marriageStatus: MarriageStatusEnum;
  livingOptionId: Scalars['String'];
};

export type ChildrenInformationInput = {
  fullName: Scalars['String'];
  dateOfBirth: Scalars['DateTime'];
  gender: GenderEnum;
};

export type ChildrenDomainInput = {
  childrenInformation: ChildrenInformationInput;
  kidJob: KidJobEnum;
  kidStudy: KidStudyEnum;
  totalIncome: Scalars['String'];
  currentSaving: Scalars['String'];
};

export type FamilyDomainInput = {
  numberOfChildren: Scalars['Int'];
  marriageStatus: MarriageStatusEnum;
  supportYear: FamilySupportYearEnum;
  supportMoney: Scalars['String'];
  totalIncome: Scalars['String'];
  currentSaving: Scalars['String'];
};

export type HealthDomainInput = {
  numberOfChildren: Scalars['Int'];
  marriageStatus: MarriageStatusEnum;
  policyCover: PolicyCoverEnum;
  healthWorry: HealthWorryEnum;
  totalIncome: Scalars['String'];
  currentSaving: Scalars['String'];
};

export type RetirementDomainInput = {
  numberOfChildren: Scalars['Int'];
  marriageStatus: MarriageStatusEnum;
  retirementPlan: RetirementPlanEnum;
  retirementPlace: RetirementPlaceEnum;
  retirementWork: RetirementWorkEnum;
  totalIncome: Scalars['String'];
  currentSaving: Scalars['String'];
};

export type DreamDomainInput = {
  numberOfChildren: Scalars['Int'];
  marriageStatus: MarriageStatusEnum;
  yourDream: YourDreamEnum;
  dreamComeTrue: DreamComeTrueYearEnum;
  totalIncome: Scalars['String'];
  currentSaving: Scalars['String'];
};

export type AllPagesModel =
  | PageBuilderModel
  | ProductGroupModel
  | ProductModel
  | ArticlesModel
  | BranchesModel
  | StaticPagesModel
  | MemberModel
  | PartnersModel
  | FundsModel;

export type PageBuilderModel = {
  __typename?: 'PageBuilderModel';
  dataPage?: Maybe<PageTransConnection>;
  moduleType: PopupModuleEnum;
};

export type ProductGroupModel = {
  __typename?: 'ProductGroupModel';
  dataProductGroup?: Maybe<Array<ProductGroup>>;
  moduleType: PopupModuleEnum;
};

export type ProductModel = {
  __typename?: 'ProductModel';
  dataProduct?: Maybe<ProductConnection>;
  moduleType: PopupModuleEnum;
};

export type ArticlesModel = {
  __typename?: 'ArticlesModel';
  dataArticle?: Maybe<ArticleConnection>;
  moduleType: PopupModuleEnum;
};

export type BranchesModel = {
  __typename?: 'BranchesModel';
  dataBranches?: Maybe<BranchConnection>;
  moduleType: PopupModuleEnum;
};

export type StaticPagesModel = {
  __typename?: 'StaticPagesModel';
  dataStaticPages?: Maybe<Array<DataStaticPagesModel>>;
  moduleType: PopupModuleEnum;
};

export type MemberModel = {
  __typename?: 'MemberModel';
  dataMember?: Maybe<Array<Members>>;
  moduleType: PopupModuleEnum;
};

export type PartnersModel = {
  __typename?: 'PartnersModel';
  dataPartner?: Maybe<Array<Partners>>;
  moduleType: PopupModuleEnum;
};

export type FundsModel = {
  __typename?: 'FundsModel';
  dataFund?: Maybe<Array<FundGroups>>;
  moduleType: PopupModuleEnum;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Get Payment Info: Inquiry Policy */
  paymentBaoKim: CreateOrderSuccess;
  /** Init momo payment */
  initPaymentMomo: InitPaymentResponseModel;
  /** Check callback payment */
  checkCallBackMomo: CheckCallbackResponseModel;
  createUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  setPermissions?: Maybe<Scalars['Boolean']>;
  login: AuthConnection;
  logout: LogoutModel;
  deleteMedia: DeleteMedia;
  updateMedia: Media;
  /** create directory */
  createDirectory: Media;
  /** Create Or Update Home Menu */
  createOrUpdateHomeMenu: Array<MenuModel>;
  /** createOrUpdateArticleGeneralInfo */
  createOrUpdateArticleGeneralInfo?: Maybe<ArticleGeneralInfo>;
  /** createOrUpdateArticle */
  createOrUpdateArticle?: Maybe<Articles>;
  /** Delete Article */
  deleteArticle?: Maybe<Scalars['Boolean']>;
  /** Create new Category */
  createCategory?: Maybe<Category>;
  /** Edit Category */
  editCategory?: Maybe<Category>;
  /** Delete Category */
  deleteCategory?: Maybe<Scalars['Boolean']>;
  /** Create a new article Group */
  createArticleGroup: ArticleGroup;
  /** Soft delete article group */
  deleteArticleGroup: Scalars['Boolean'];
  /** Edit Article Group */
  editArticleGroup: ArticleGroup;
  /** Create member */
  createOrUpdateMember?: Maybe<Members>;
  /** reOrderMembers */
  reOrderMembers?: Maybe<Array<Members>>;
  /** Delete Member */
  deleteMember?: Maybe<Scalars['Boolean']>;
  /** createOrUpdateMemberGeneralInfo */
  createOrUpdateMemberGeneralInfo?: Maybe<MemberGeneralInfo>;
  /** Create or Edit Product General Info */
  saveProductGeneral: ProductGeneralInfo;
  /** Create a new product Group */
  createProductGroup: ProductGroup;
  /** Soft delete product group */
  deleteProductGroup: Scalars['Boolean'];
  /** Edit Product Group */
  editProductGroup: ProductGroup;
  /** Create a new product */
  createProduct: Product;
  /** Edit product */
  editProduct: Product;
  /** Edit product */
  deleteProduct: Scalars['Boolean'];
  /** Create a new page */
  createPage: PageTranslation;
  /** Edit page layout */
  editPageLayout: PageTranslation;
  /** Soft delete page */
  movePageToTrash: PageTranslation;
  /** Delete/Restore page */
  actionPage: PageTranslation;
  /** Edit page: general infors and seo configures */
  editPage: PageTranslation;
  /** Edit page: general infors and seo configures */
  clonePage: PageTranslation;
  /** Create new Branch */
  createBranch?: Maybe<Branches>;
  /** Edit Branch */
  editBranch?: Maybe<Branches>;
  /** Delete Branch */
  deleteBranch?: Maybe<Scalars['Boolean']>;
  /** Create Or Update Site Map */
  createOrUpdateSiteMap: Array<SiteMapModel>;
  /** Create or Update footer */
  createOrUpdateFooter?: Maybe<FooterModel>;
  /** Create or Update header */
  headerSetting?: Maybe<HeaderModel>;
  /** createOrUpdateFundGeneralInfo */
  createOrUpdateFundGeneralInfo?: Maybe<FundGeneralInfo>;
  /** createOrUpdateFund */
  createOrUpdateFund?: Maybe<Funds>;
  /** createOrUpdateInterest */
  createOrUpdateInterest?: Maybe<Funds>;
  /** Delete Fund */
  deleteFund?: Maybe<Scalars['Boolean']>;
  /** createOrUpdateFundGroup */
  createOrUpdateFundGroup?: Maybe<FundGroups>;
  /** reOrderFundGroup */
  reOrderFundGroup?: Maybe<Array<FundGroups>>;
  /** show/hide fund group */
  changeVisibleFundGroup?: Maybe<FundGroups>;
  /** create new Fund Rate */
  createFundRate: FundRates;
  /** edit Fund Rate */
  editFundRate: FundRates;
  /** delete Fund Rate */
  deleteFundRate: Scalars['Boolean'];
  /** Create new or update financial report language version */
  upsertFinancial: FinancialReports;
  /** Create a new customer request */
  createCustomerRequest: CustomerRequest;
  /** Delete a customer request */
  deleteCustomerRequest: Scalars['Boolean'];
  /** Change status of customer request */
  changeStatusCustomerRequest: CustomerRequest;
  /** Save Notification Setting */
  saveSetting: NotificationSettingModel;
  /** Create a new policy group */
  createPolicyGroup: PolicyGroup;
  /** Edit a policy group */
  editPolicyGroup: PolicyGroup;
  /** Deelte a policy group */
  deletePolicyGroup: Scalars['Boolean'];
  /** createOrUpdatePolicyGeneralInfo */
  createOrUpdatePolicyGeneralInfo?: Maybe<Array<PolicyGeneralInfo>>;
  /** Create a new policy */
  createPolicy: ChangePolicies;
  /** Edit a policy */
  editPolicy: ChangePolicies;
  /** Delete a policy */
  deletePolicy: Scalars['Boolean'];
  /** editPartner */
  editPartner?: Maybe<Partners>;
  /** create Comment */
  createCommentAdmin?: Maybe<Comment>;
  /** create Comment */
  createComment?: Maybe<Comment>;
  /** bulkActionComment */
  bulkActionComment?: Maybe<Scalars['Boolean']>;
  /** actionComment */
  actionComment?: Maybe<Scalars['Boolean']>;
  /** createOrUpdateClaimGeneralInfo */
  createOrUpdateClaimGeneralInfo?: Maybe<Array<ClaimGeneralInfo>>;
  /** createClaim */
  createClaim?: Maybe<Claim>;
  /** editClaim */
  editClaim?: Maybe<Claim>;
  /** deleteClaim */
  deleteClaim?: Maybe<Scalars['Boolean']>;
  /** createOrUpdateFaqGeneralInfo */
  createOrUpdateFaqGeneralInfo?: Maybe<Array<FaqGeneralInfo>>;
  /** createFaq */
  createFaq?: Maybe<Faq>;
  /** editFaq */
  editFaq?: Maybe<Faq>;
  /** deleteFaq */
  deleteFaq?: Maybe<Scalars['Boolean']>;
  editDomain?: Maybe<DomainEntity>;
  updateQuestionRate: QuestionRate;
  addQuestionOption: QuestionOptions;
  editQuestionOption: QuestionOptions;
  deleteQuestionOption: Scalars['Boolean'];
  createSurvey?: Maybe<SurveyResult>;
  /** createAdvertisement */
  createAdvertisement?: Maybe<Advertisement>;
  /** editAdvertisement */
  editAdvertisement?: Maybe<Advertisement>;
  /** deleteAdvertisement */
  deleteAdvertisement?: Maybe<Scalars['Boolean']>;
  /** publishAdvertisement */
  publishAdvertisement?: Maybe<Scalars['Boolean']>;
  /** createCookies */
  createCookies?: Maybe<Cookies>;
  /** editCookies */
  editCookies?: Maybe<Cookies>;
  /** deleteCookies */
  deleteCookies?: Maybe<Scalars['Boolean']>;
  /** publishCookies */
  publishCookies?: Maybe<Scalars['Boolean']>;
  /** createNewsletter */
  createNewsletter?: Maybe<Newsletter>;
  /** editNewsletter */
  editNewsletter?: Maybe<Newsletter>;
  /** deleteNewsletter */
  deleteNewsletter?: Maybe<Scalars['Boolean']>;
  /** publishNewsletter */
  publishNewsletter?: Maybe<Scalars['Boolean']>;
  /** Save Service Program Data */
  saveServiceProgram?: Maybe<ServiceProgram>;
  /** Save Service Program Data */
  saveServiceGeneral?: Maybe<ServiceProgram>;
};

export type MutationPaymentBaoKimArgs = {
  input: CornfirmPaymentInput;
};

export type MutationInitPaymentMomoArgs = {
  input: MomoInitPaymentInput;
};

export type MutationCheckCallBackMomoArgs = {
  data: Scalars['String'];
};

export type MutationCreateUserArgs = {
  input: NewUserInput;
};

export type MutationUpdateUserArgs = {
  input: EditUserInput;
};

export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};

export type MutationSetPermissionsArgs = {
  permissions?: Maybe<Array<PermissionModuleType>>;
};

export type MutationLoginArgs = {
  captchaToken: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationDeleteMediaArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type MutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};

export type MutationCreateDirectoryArgs = {
  parentId?: Maybe<Scalars['ID']>;
  dirName: Scalars['String'];
};

export type MutationCreateOrUpdateHomeMenuArgs = {
  lang: LanguageEnum;
  input: Array<HomeMenuInput>;
};

export type MutationCreateOrUpdateArticleGeneralInfoArgs = {
  input: ArticleGeneralInfoInput;
};

export type MutationCreateOrUpdateArticleArgs = {
  input: NewArticleInput;
};

export type MutationDeleteArticleArgs = {
  id: Scalars['ID'];
};

export type MutationCreateCategoryArgs = {
  input: NewCategoryInput;
};

export type MutationEditCategoryArgs = {
  input: EditCategoryInput;
};

export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationCreateArticleGroupArgs = {
  input: NewArticleGroupInput;
};

export type MutationDeleteArticleGroupArgs = {
  id: Scalars['ID'];
};

export type MutationEditArticleGroupArgs = {
  input: EditArticleGroupInput;
};

export type MutationCreateOrUpdateMemberArgs = {
  input: NewMemberInput;
};

export type MutationReOrderMembersArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationDeleteMemberArgs = {
  id: Scalars['ID'];
};

export type MutationCreateOrUpdateMemberGeneralInfoArgs = {
  input: MemberGeneralInfoInput;
};

export type MutationSaveProductGeneralArgs = {
  categoryId: Scalars['String'];
};

export type MutationCreateProductGroupArgs = {
  input: NewProductGroupInput;
};

export type MutationDeleteProductGroupArgs = {
  id: Scalars['ID'];
};

export type MutationEditProductGroupArgs = {
  input: EditProductGroupInput;
};

export type MutationCreateProductArgs = {
  input: NewProductInput;
};

export type MutationEditProductArgs = {
  input: EditProductInput;
};

export type MutationDeleteProductArgs = {
  id: Scalars['String'];
};

export type MutationCreatePageArgs = {
  input: NewPageTransInput;
};

export type MutationEditPageLayoutArgs = {
  input: EditPageLayout;
};

export type MutationMovePageToTrashArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type MutationActionPageArgs = {
  action?: Maybe<ActionPage>;
  id?: Maybe<Scalars['ID']>;
};

export type MutationEditPageArgs = {
  input: EditPageInput;
};

export type MutationClonePageArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type MutationCreateBranchArgs = {
  input: NewBranchInput;
};

export type MutationEditBranchArgs = {
  input: EditBranchInput;
};

export type MutationDeleteBranchArgs = {
  id: Scalars['ID'];
};

export type MutationCreateOrUpdateSiteMapArgs = {
  lang: LanguageEnum;
  input: Array<SiteMapInput>;
};

export type MutationCreateOrUpdateFooterArgs = {
  input: FooterInput;
};

export type MutationHeaderSettingArgs = {
  input: HeaderInput;
};

export type MutationCreateOrUpdateFundGeneralInfoArgs = {
  input: FundGeneralInfoInput;
};

export type MutationCreateOrUpdateFundArgs = {
  input: NewFundInput;
};

export type MutationCreateOrUpdateInterestArgs = {
  input: NewFundInterestInput;
};

export type MutationDeleteFundArgs = {
  id: Scalars['ID'];
};

export type MutationCreateOrUpdateFundGroupArgs = {
  input: NewFundGroupInput;
};

export type MutationReOrderFundGroupArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
};

export type MutationChangeVisibleFundGroupArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MutationCreateFundRateArgs = {
  input: NewFundRateInput;
};

export type MutationEditFundRateArgs = {
  input: EditFundRateInput;
};

export type MutationDeleteFundRateArgs = {
  id: Scalars['ID'];
};

export type MutationUpsertFinancialArgs = {
  input: FinancialInput;
};

export type MutationCreateCustomerRequestArgs = {
  input: CustomerRequestInput;
};

export type MutationDeleteCustomerRequestArgs = {
  id: Scalars['ID'];
};

export type MutationChangeStatusCustomerRequestArgs = {
  status?: Maybe<RequestStatus>;
  id: Scalars['ID'];
};

export type MutationSaveSettingArgs = {
  emailList: Array<Scalars['String']>;
};

export type MutationCreatePolicyGroupArgs = {
  input: NewPolicyGroupInput;
};

export type MutationEditPolicyGroupArgs = {
  input: EditPolicyGroupInput;
};

export type MutationDeletePolicyGroupArgs = {
  id: Scalars['String'];
};

export type MutationCreateOrUpdatePolicyGeneralInfoArgs = {
  input: Array<PolicyGeneralInfoInput>;
};

export type MutationCreatePolicyArgs = {
  input: NewChangePolicyInput;
};

export type MutationEditPolicyArgs = {
  input: EditChangePolicyInput;
};

export type MutationDeletePolicyArgs = {
  id: Scalars['String'];
};

export type MutationEditPartnerArgs = {
  input: EditPartnerInput;
};

export type MutationCreateCommentAdminArgs = {
  input: NewCommentAdminInput;
};

export type MutationCreateCommentArgs = {
  input: NewCommentInput;
};

export type MutationBulkActionCommentArgs = {
  ids: Array<Scalars['ID']>;
  status: StatusComment;
};

export type MutationActionCommentArgs = {
  id: Scalars['ID'];
  status: StatusComment;
};

export type MutationCreateOrUpdateClaimGeneralInfoArgs = {
  input: Array<ClaimGeneralInfoInput>;
};

export type MutationCreateClaimArgs = {
  input: Array<NewClaimInput>;
};

export type MutationEditClaimArgs = {
  input: Array<EditClaimInput>;
  claimId: Scalars['ID'];
};

export type MutationDeleteClaimArgs = {
  id: Scalars['ID'];
};

export type MutationCreateOrUpdateFaqGeneralInfoArgs = {
  input: Array<FaqGeneralInfoInput>;
};

export type MutationCreateFaqArgs = {
  input: Array<NewFaqInput>;
};

export type MutationEditFaqArgs = {
  input: Array<EditFaqInput>;
  faqId: Scalars['ID'];
};

export type MutationDeleteFaqArgs = {
  id: Scalars['ID'];
};

export type MutationEditDomainArgs = {
  input: DomainInput;
};

export type MutationUpdateQuestionRateArgs = {
  input: QuestionRateInput;
};

export type MutationAddQuestionOptionArgs = {
  input: QuestionOptionInput;
};

export type MutationEditQuestionOptionArgs = {
  input: EditQuestionOptionInput;
};

export type MutationDeleteQuestionOptionArgs = {
  optionId: Scalars['String'];
};

export type MutationCreateSurveyArgs = {
  input: SurveyInput;
};

export type MutationCreateAdvertisementArgs = {
  input: CreateAdvertisementInput;
};

export type MutationEditAdvertisementArgs = {
  input: EditAdvertisementInput;
};

export type MutationDeleteAdvertisementArgs = {
  id: Scalars['String'];
};

export type MutationPublishAdvertisementArgs = {
  id: Scalars['String'];
};

export type MutationCreateCookiesArgs = {
  input: Array<CookiesTransInput>;
};

export type MutationEditCookiesArgs = {
  input: EditCookiesInput;
};

export type MutationDeleteCookiesArgs = {
  id: Scalars['String'];
};

export type MutationPublishCookiesArgs = {
  id: Scalars['String'];
};

export type MutationCreateNewsletterArgs = {
  input: CreateNewsletterInput;
};

export type MutationEditNewsletterArgs = {
  input: EditNewsletterInput;
};

export type MutationDeleteNewsletterArgs = {
  id: Scalars['String'];
};

export type MutationPublishNewsletterArgs = {
  id: Scalars['String'];
};

export type MutationSaveServiceProgramArgs = {
  input: ServiceProgramInput;
};

export type MutationSaveServiceGeneralArgs = {
  input: ServiceProgramGeneralInput;
};

export type CornfirmPaymentInput = {
  totalAmout: Scalars['Int'];
  lang?: Maybe<LanguageEnum>;
  policyNumber?: Maybe<Scalars['String']>;
  eAppNumber?: Maybe<Scalars['String']>;
  /** Date and time user inquiry */
  inquiryTime?: Maybe<Scalars['DateTime']>;
  /** system date returned after query policy */
  inquiryResponseTime: Scalars['DateTime'];
  /** inquiry id returned after query policy */
  inquiryId?: Maybe<Scalars['String']>;
  /** holder name returned after query policy */
  holderName?: Maybe<Scalars['String']>;
  /** holder name returned after query policy */
  mainInsuredName?: Maybe<Scalars['String']>;
  holderDob?: Maybe<Scalars['String']>;
  mainInsuredDob?: Maybe<Scalars['String']>;
  payerIsHolder?: Maybe<Scalars['Boolean']>;
  payerName?: Maybe<Scalars['String']>;
  payerRelation?: Maybe<PayerRelationEnum>;
  payerEmail?: Maybe<Scalars['String']>;
  payerDocumentType?: Maybe<PaymentDocumentType>;
  payerDocumentId?: Maybe<Scalars['String']>;
  payerPhoneNumber?: Maybe<Scalars['String']>;
  premiumType?: Maybe<PremiumTypeEnum>;
  addField1?: Maybe<Scalars['String']>;
  addField2?: Maybe<Scalars['String']>;
  addField3?: Maybe<Scalars['String']>;
  addField4?: Maybe<Scalars['String']>;
  addField5?: Maybe<Scalars['String']>;
  bpmId?: Maybe<Scalars['Int']>;
  captchaToken: Scalars['String'];
  inquiryPaymentDetail?: Maybe<Array<InquiryPaymentDetailInput>>;
};

export type InquiryPaymentDetailInput = {
  duePeriod?: Maybe<Scalars['String']>;
  dueAmount?: Maybe<Scalars['String']>;
};

export type MomoInitPaymentInput = {
  policy: PaymentPolicyInput;
  clientType: MomoClientType;
};

export type PaymentPolicyInput = {
  /** Loai thanh toan */
  feeType: PaymentFeeType;
  /** Ma HD/ HSYCBH */
  idNumber: Scalars['String'];
  /** Ten san pham */
  prdName: Scalars['String'];
  /** Ten nguoi mua bao hiem */
  phName: Scalars['String'];
  /** Thanh toan bang credit card: Y/N */
  ccAllow: Scalars['String'];
  /** Loai thanh toan */
  orderId: Scalars['String'];
  duePremium?: Maybe<PolicyDuePremiumInput>;
  initialPremium?: Maybe<PolicyInitialPremiumInput>;
  topup?: Maybe<PolicyTopupInput>;
};

export enum PaymentFeeType {
  LAN_DAU = 'LAN_DAU',
  DINH_KY = 'DINH_KY',
  TOPUP = 'TOPUP',
}

export type PolicyDuePremiumInput = {
  /** So tien con du */
  suspenseAmt?: Maybe<Scalars['String']>;
  duePremiumInfo?: Maybe<Array<DuePremiumInfoInput>>;
};

export type DuePremiumInfoInput = {
  /** Loai phi: DUE/NDUE */
  premiumType: Scalars['String'];
  dueFrom: Scalars['String'];
  dueTo: Scalars['String'];
  /** So tien */
  dueAmt: Scalars['String'];
  id: Scalars['String'];
};

export type PolicyInitialPremiumInput = {
  id: Scalars['String'];
  /** Phi lan dau tam tinh */
  preMafTerTaxAmt: Scalars['String'];
  /** Tong phi thanh toan lan dau */
  totalAmt: Scalars['String'];
};

export type PolicyTopupInput = {
  /** So tien top up */
  amount: Scalars['String'];
  id: Scalars['String'];
};

export enum MomoClientType {
  WEBINAPP = 'WEBINAPP',
  ANDROID_APP = 'ANDROID_APP',
  IOS_APP = 'IOS_APP',
}

export type NewUserInput = {
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
};

export type EditUserInput = {
  id: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  confirmPassword?: Maybe<Scalars['String']>;
};

export type UpdateMediaInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Input array home menu */
export type HomeMenuInput = {
  id?: Maybe<Scalars['ID']>;
  pageId?: Maybe<Scalars['ID']>;
  pageTranslationId?: Maybe<Scalars['ID']>;
  icon?: Maybe<Scalars['String']>;
  megaMenu?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  children?: Maybe<Array<HomeMenuInput>>;
};

/** Input Article General Info */
export type ArticleGeneralInfoInput = {
  categoryId: Scalars['ID'];
  lang: LanguageEnum;
  type: ArticleType;
  title: Scalars['String'];
  background?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  suggestionText?: Maybe<Scalars['String']>;
  suggestionBackgroundImage?: Maybe<Scalars['String']>;
  suggestionColor?: Maybe<Scalars['String']>;
  suggestionIcon?: Maybe<Scalars['String']>;
  suggestionButtonUrl?: Maybe<Scalars['String']>;
  suggestionButtonText?: Maybe<Scalars['String']>;
};

/** Input new Article */
export type NewArticleInput = {
  articleId?: Maybe<Scalars['ID']>;
  articleGroupIds?: Maybe<Array<Scalars['ID']>>;
  lang: LanguageEnum;
  type: ArticleType;
  urlSlug: Scalars['String'];
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  content: Scalars['String'];
  relatedItems?: Maybe<Array<RelatedItemInput>>;
  postedDate?: Maybe<Scalars['DateTime']>;
  scheduleDate?: Maybe<Scalars['DateTime']>;
};

/** Input new RelatedItem */
export type RelatedItemInput = {
  id?: Maybe<Scalars['ID']>;
  articleTranslationId?: Maybe<Scalars['ID']>;
  articleRelatedId: Scalars['ID'];
  title: Scalars['String'];
  featureImage: Scalars['String'];
};

/** Input New Category */
export type NewCategoryInput = {
  description?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  categoryModule?: Maybe<CategoryModuleEnum>;
  translations: Array<CategoryTranslationInput>;
};

/** Input New Category Translation */
export type CategoryTranslationInput = {
  name: Scalars['String'];
  lang: LanguageEnum;
};

/** Input Edit Category */
export type EditCategoryInput = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  categoryModule?: Maybe<CategoryModuleEnum>;
  parentId?: Maybe<Scalars['ID']>;
  translations: Array<CategoryTranslationInput>;
};

/** create a new article group */
export type NewArticleGroupInput = {
  articlesOrder?: Maybe<Array<GroupArticlesOrderInput>>;
  translations: Array<NewArticleGroupTransInput>;
};

/** assign articles to group */
export type GroupArticlesOrderInput = {
  orderPosition: Scalars['Int'];
  articleId: Scalars['String'];
};

/** create new article group translations */
export type NewArticleGroupTransInput = {
  lang?: Maybe<LanguageEnum>;
  name: Scalars['String'];
  urlSlug?: Maybe<Scalars['String']>;
};

/** Input Edit article group */
export type EditArticleGroupInput = {
  id: Scalars['ID'];
  articlesOrder?: Maybe<Array<GroupArticlesOrderInput>>;
  translations: Array<NewArticleGroupTransInput>;
};

/** Input new member */
export type NewMemberInput = {
  memberId?: Maybe<Scalars['ID']>;
  lang: LanguageEnum;
  urlSlug: Scalars['String'];
  photo: Scalars['String'];
  fullname: Scalars['String'];
  position: Scalars['String'];
  quote?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featureImage?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
};

/** Input Member General Info */
export type MemberGeneralInfoInput = {
  categoryId: Scalars['ID'];
  lang: LanguageEnum;
  title: Scalars['String'];
  background: Scalars['String'];
  titleHeading: Scalars['String'];
  subTitleHeading: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
};

/** create a new product group */
export type NewProductGroupInput = {
  parentId: Scalars['ID'];
  productsOrder?: Maybe<Array<GroupProductsOrderInput>>;
  translations: Array<NewProductGroupTransInput>;
};

/** assign products to group */
export type GroupProductsOrderInput = {
  orderPosition: Scalars['Int'];
  productId: Scalars['String'];
};

/** create new product group translations */
export type NewProductGroupTransInput = {
  lang?: Maybe<LanguageEnum>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  urlSlug?: Maybe<Scalars['String']>;
};

/** Input Edit product group */
export type EditProductGroupInput = {
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  productsOrder?: Maybe<Array<GroupProductsOrderInput>>;
  translations: Array<EditProductGroupTransInput>;
};

export type EditProductGroupTransInput = {
  lang?: Maybe<LanguageEnum>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  urlSlug?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
};

/** create new product input */
export type NewProductInput = {
  productGroupIds?: Maybe<Array<Scalars['ID']>>;
  informations?: Maybe<Array<ProductInformationsInput>>;
  benefitSetting?: Maybe<Array<ProductBenefitSettingInput>>;
  benefits?: Maybe<Array<ProductBenefitsInput>>;
  examples?: Maybe<Array<ProductExamplesInput>>;
  featurestSetting?: Maybe<Array<ProductFeaturestSettingInput>>;
  featurest?: Maybe<Array<ProductFeaturestsInput>>;
  documents?: Maybe<Array<ProductDocumentsInput>>;
  relatedProductSetting?: Maybe<Array<RelatedProductSettingInput>>;
  relatedProducts?: Maybe<Array<RelatedProductsInput>>;
  status?: Maybe<StatusType>;
};

/** Product informations input */
export type ProductInformationsInput = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<LanguageEnum>;
  name: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  productImage?: Maybe<Scalars['String']>;
  productFeaturestImage?: Maybe<Scalars['String']>;
  urlSlug?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
};

/** Product benefit setting input */
export type ProductBenefitSettingInput = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<LanguageEnum>;
  tabButton?: Maybe<Scalars['String']>;
  tabIcon?: Maybe<Scalars['String']>;
  sectionTitle?: Maybe<Scalars['String']>;
};

/** Product benefits input */
export type ProductBenefitsInput = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<LanguageEnum>;
  name: Scalars['String'];
  description: Scalars['String'];
  icon: Scalars['String'];
  orderPosition: Scalars['Int'];
};

/** Product examples input */
export type ProductExamplesInput = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<LanguageEnum>;
  tabButton?: Maybe<Scalars['String']>;
  tabIcon?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};

/** Product featurests setting input */
export type ProductFeaturestSettingInput = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<LanguageEnum>;
  tabButton?: Maybe<Scalars['String']>;
  tabIcon?: Maybe<Scalars['String']>;
  sectionTitle?: Maybe<Scalars['String']>;
};

/** Product featurests input */
export type ProductFeaturestsInput = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<LanguageEnum>;
  name: Scalars['String'];
  description: Scalars['String'];
  icon: Scalars['String'];
  iconBackground?: Maybe<Scalars['String']>;
  orderPosition: Scalars['Int'];
};

/** Product documents input */
export type ProductDocumentsInput = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<LanguageEnum>;
  tabButton?: Maybe<Scalars['String']>;
  tabIcon?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  mediaIds?: Maybe<Array<Scalars['ID']>>;
  fundName?: Maybe<Scalars['String']>;
  fundUrl?: Maybe<Scalars['String']>;
  fundIcon?: Maybe<Scalars['String']>;
};

/** Related products setting input */
export type RelatedProductSettingInput = {
  lang?: Maybe<LanguageEnum>;
  sectionTitle?: Maybe<Scalars['String']>;
};

/** Related products input */
export type RelatedProductsInput = {
  lang?: Maybe<LanguageEnum>;
  title: Scalars['String'];
  featureImage?: Maybe<Scalars['String']>;
  productRelatedId?: Maybe<Scalars['ID']>;
  orderPosition: Scalars['Int'];
};

/** edit product input */
export type EditProductInput = {
  productGroupIds?: Maybe<Array<Scalars['ID']>>;
  informations?: Maybe<Array<ProductInformationsInput>>;
  benefitSetting?: Maybe<Array<ProductBenefitSettingInput>>;
  benefits?: Maybe<Array<ProductBenefitsInput>>;
  examples?: Maybe<Array<ProductExamplesInput>>;
  featurestSetting?: Maybe<Array<ProductFeaturestSettingInput>>;
  featurest?: Maybe<Array<ProductFeaturestsInput>>;
  documents?: Maybe<Array<ProductDocumentsInput>>;
  relatedProductSetting?: Maybe<Array<RelatedProductSettingInput>>;
  relatedProducts?: Maybe<Array<RelatedProductsInput>>;
  status?: Maybe<StatusType>;
  id: Scalars['ID'];
  lang: LanguageEnum;
};

/** create new translation page */
export type NewPageTransInput = {
  featuredImage?: Maybe<Scalars['String']>;
  lang?: Maybe<LanguageEnum>;
  pageTitle: Scalars['String'];
  categoryId?: Maybe<Scalars['ID']>;
  postedDate?: Maybe<Scalars['DateTime']>;
  scheduleDate?: Maybe<Scalars['DateTime']>;
  productGroupIds?: Maybe<Array<Scalars['ID']>>;
  articleGroupIds?: Maybe<Array<Scalars['ID']>>;
  position?: Maybe<Scalars['String']>;
  urlSlug: Scalars['String'];
  pageId?: Maybe<Scalars['ID']>;
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeyword: Array<Scalars['String']>;
  templateId?: Maybe<Scalars['ID']>;
  pageLayout?: Maybe<Scalars['String']>;
  pageType?: Maybe<PageType>;
  status?: Maybe<PageStatus>;
  layoutType?: Maybe<LayoutType>;
};

/** Edit Page Layout */
export type EditPageLayout = {
  id: Scalars['ID'];
  pageLayout: Scalars['String'];
  status: PageStatus;
};

export enum ActionPage {
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
}

/** Input Edit Page Translation */
export type EditPageInput = {
  featuredImage?: Maybe<Scalars['String']>;
  lang?: Maybe<LanguageEnum>;
  pageTitle: Scalars['String'];
  categoryId?: Maybe<Scalars['ID']>;
  postedDate?: Maybe<Scalars['DateTime']>;
  scheduleDate?: Maybe<Scalars['DateTime']>;
  productGroupIds?: Maybe<Array<Scalars['ID']>>;
  articleGroupIds?: Maybe<Array<Scalars['ID']>>;
  position?: Maybe<Scalars['String']>;
  urlSlug: Scalars['String'];
  pageId?: Maybe<Scalars['ID']>;
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeyword: Array<Scalars['String']>;
  templateId?: Maybe<Scalars['ID']>;
  pageLayout?: Maybe<Scalars['String']>;
  pageType?: Maybe<PageType>;
  status?: Maybe<PageStatus>;
  layoutType?: Maybe<LayoutType>;
  id: Scalars['ID'];
};

/** Input New Branch */
export type NewBranchInput = {
  branchTypeId: Scalars['ID'];
  translations: Array<BranchTranslationInput>;
  lat: Scalars['String'];
  lon: Scalars['String'];
  phone: Scalars['String'];
  fax?: Maybe<Scalars['String']>;
  provinceId?: Maybe<Scalars['ID']>;
  districtId?: Maybe<Scalars['ID']>;
  wardId?: Maybe<Scalars['ID']>;
};

/** Input Translation Branch */
export type BranchTranslationInput = {
  name: Scalars['String'];
  address: Scalars['String'];
  lang: LanguageEnum;
};

/** Input Edit Branch */
export type EditBranchInput = {
  branchTypeId: Scalars['ID'];
  translations: Array<BranchTranslationInput>;
  lat: Scalars['String'];
  lon: Scalars['String'];
  phone: Scalars['String'];
  fax?: Maybe<Scalars['String']>;
  provinceId?: Maybe<Scalars['ID']>;
  districtId?: Maybe<Scalars['ID']>;
  wardId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};

/** Input array site map */
export type SiteMapInput = {
  id?: Maybe<Scalars['ID']>;
  pageId?: Maybe<Scalars['ID']>;
  pageTranslationId?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  children?: Maybe<Array<SiteMapInput>>;
};

/** Input footer */
export type FooterInput = {
  logo: Scalars['String'];
  address: Scalars['String'];
  hotline: Scalars['String'];
  email: Scalars['String'];
  lang: LanguageEnum;
  sns?: Maybe<Array<FieldInput>>;
  shortcuts?: Maybe<Array<FieldInput>>;
};

export type FieldInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

/** Header input */
export type HeaderInput = {
  lang: LanguageEnum;
  logo?: Maybe<Scalars['String']>;
  partners?: Maybe<Array<PartnerInput>>;
  hotline?: Maybe<Scalars['String']>;
  buttonName?: Maybe<Scalars['String']>;
  buttonURL?: Maybe<Scalars['String']>;
  buttonBorderColor?: Maybe<Scalars['String']>;
  buttonNameColor?: Maybe<Scalars['String']>;
};

export type PartnerInput = {
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** Input Fund General Info */
export type FundGeneralInfoInput = {
  categoryId: Scalars['ID'];
  lang: LanguageEnum;
  title: Scalars['String'];
  background: Scalars['String'];
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
};

/** Input new fund */
export type NewFundInput = {
  fundId?: Maybe<Scalars['ID']>;
  fundGroupId?: Maybe<Scalars['ID']>;
  chartColor?: Maybe<Scalars['String']>;
  lang: LanguageEnum;
  name: Scalars['String'];
  sectionTitle?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<FundDocumentsInput>>;
  fundType?: Maybe<FundType>;
  status?: Maybe<StatusType>;
};

export type FundDocumentsInput = {
  name: Scalars['String'];
  year: Scalars['Int'];
  mediaId: Scalars['String'];
};

/** Input new fund interest */
export type NewFundInterestInput = {
  fundId?: Maybe<Scalars['ID']>;
  translations: Array<FundInterestTranslation>;
};

/** Input translation interest */
export type FundInterestTranslation = {
  lang: LanguageEnum;
  name: Scalars['String'];
};

/** Input new fund group */
export type NewFundGroupInput = {
  fundGroupId?: Maybe<Scalars['ID']>;
  fundType?: Maybe<FundType>;
  chartColor?: Maybe<Scalars['String']>;
  lang: LanguageEnum;
  name: Scalars['String'];
  urlSlug: Scalars['String'];
  label: Scalars['String'];
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  buttonColor?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageDetail?: Maybe<Scalars['String']>;
  sectionTitle?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<FundDocumentsInput>>;
  recommendations?: Maybe<Array<RecommendationInput>>;
};

export type RecommendationInput = {
  buttonInfo?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Create new fund rate input */
export type NewFundRateInput = {
  fundId: Scalars['ID'];
  effectiveDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  rateValue: Scalars['Float'];
  translations?: Maybe<Array<FundRateTranslationInput>>;
};

/** Create fundRate Translation */
export type FundRateTranslationInput = {
  lang: LanguageEnum;
  period: Scalars['String'];
};

/** Edit fund rate input */
export type EditFundRateInput = {
  fundId: Scalars['ID'];
  effectiveDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  rateValue: Scalars['Float'];
  translations?: Maybe<Array<FundRateTranslationInput>>;
  id: Scalars['ID'];
};

/** Financial input */
export type FinancialInput = {
  lang: LanguageEnum;
  categoryId: Scalars['ID'];
  title: Scalars['String'];
  subTitle: Scalars['String'];
  background?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
  financialStatement: Array<FinancialStatementInput>;
};

export type FinancialStatementFilesInput = {
  file: Scalars['String'];
  fileFullPath: Scalars['String'];
};

/** Financial Statement input */
export type FinancialStatementInput = {
  year: Scalars['String'];
  files: Array<FinancialStatementFilesInput>;
};

/** Customer request input */
export type CustomerRequestInput = {
  captchaToken: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isCustomer?: Maybe<Scalars['Boolean']>;
  contractNumber?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  isReceiveEmail?: Maybe<Scalars['Boolean']>;
  cityId?: Maybe<Scalars['String']>;
  requestType?: Maybe<CustomerRequestType>;
  surveyResultId?: Maybe<Scalars['String']>;
};

/** Create a new Policy group */
export type NewPolicyGroupInput = {
  translations: Array<PolicyGroupTranslationInput>;
};

/** Create a new Policy group */
export type PolicyGroupTranslationInput = {
  lang: LanguageEnum;
  name: Scalars['String'];
};

/** Edit a Policy group */
export type EditPolicyGroupInput = {
  translations: Array<PolicyGroupTranslationInput>;
  id: Scalars['ID'];
};

/** Input Policy General Info */
export type PolicyGeneralInfoInput = {
  categoryId: Scalars['ID'];
  lang: LanguageEnum;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
};

/** Create a new change policy */
export type NewChangePolicyInput = {
  translations: Array<ChangePolicyTranslationInput>;
};

/** Policy translation input */
export type ChangePolicyTranslationInput = {
  lang: LanguageEnum;
  name: Scalars['String'];
  tabIcon: Scalars['String'];
  firstStepText: Scalars['String'];
  firstStepIcon: Scalars['String'];
  secondStepText: Scalars['String'];
  secondStepIcon: Scalars['String'];
  thirdStepText: Scalars['String'];
  thirdStepIcon: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  documentLabel: Scalars['String'];
  documents?: Maybe<Array<ChangePolicyDocumentsInput>>;
};

/** Change Policy documents input */
export type ChangePolicyDocumentsInput = {
  sectionTitle: Scalars['String'];
  documentMedia: Array<DocumentMediaInput>;
  note?: Maybe<Scalars['String']>;
};

/** Change Policy documents media input */
export type DocumentMediaInput = {
  documentTitle: Scalars['String'];
  mediaId?: Maybe<Scalars['String']>;
  documentLink?: Maybe<Scalars['String']>;
};

export type EditChangePolicyInput = {
  translations: Array<ChangePolicyTranslationInput>;
  id: Scalars['ID'];
};

/** Input update partner */
export type EditPartnerInput = {
  partnerId: Scalars['ID'];
  lang: LanguageEnum;
  urlSlug: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  brandImage?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  featureImage?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
};

/** Input New Comment For Admin */
export type NewCommentAdminInput = {
  content: Scalars['String'];
  parentId: Scalars['ID'];
};

/** Input New Comment */
export type NewCommentInput = {
  content: Scalars['String'];
  articleTranslationId: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** Input Claim General Info */
export type ClaimGeneralInfoInput = {
  categoryId: Scalars['ID'];
  lang: LanguageEnum;
  title: Scalars['String'];
  bannerImage?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
};

/** Input New Claim */
export type NewClaimInput = {
  lang: LanguageEnum;
  name: Scalars['String'];
  tabIcon: Scalars['String'];
  template?: Maybe<ClaimTemplateInput>;
  address?: Maybe<ClaimAddressInput>;
  note?: Maybe<ClaimNoteInput>;
};

/** Input Claim Template */
export type ClaimTemplateInput = {
  id?: Maybe<Scalars['ID']>;
  sectionTitle: Scalars['String'];
  sections?: Maybe<Array<ClaimTemplateSectionInput>>;
};

/** Input Claim Template Section */
export type ClaimTemplateSectionInput = {
  title: Scalars['String'];
  documents?: Maybe<Array<ClaimTemplateDocumentsInput>>;
};

/** Claim documents input */
export type ClaimTemplateDocumentsInput = {
  documentTitle: Scalars['String'];
  mediaId?: Maybe<Scalars['String']>;
  documentLink?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

/** Input Claim Address */
export type ClaimAddressInput = {
  id?: Maybe<Scalars['ID']>;
  sectionTitle: Scalars['String'];
  title: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

/** Input Claim Note */
export type ClaimNoteInput = {
  id?: Maybe<Scalars['ID']>;
  sectionTitle: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

/** Input Edit Claim */
export type EditClaimInput = {
  lang: LanguageEnum;
  name: Scalars['String'];
  tabIcon: Scalars['String'];
  template?: Maybe<ClaimTemplateInput>;
  address?: Maybe<ClaimAddressInput>;
  note?: Maybe<ClaimNoteInput>;
  id: Scalars['ID'];
};

/** Input Faq General Info */
export type FaqGeneralInfoInput = {
  categoryId: Scalars['ID'];
  lang: LanguageEnum;
  title: Scalars['String'];
  bannerImage?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
};

/** Input New Faq */
export type NewFaqInput = {
  lang: LanguageEnum;
  name: Scalars['String'];
  tabIcon: Scalars['String'];
  items?: Maybe<Array<FaqItemInput>>;
};

/** Input item faq */
export type FaqItemInput = {
  title: Scalars['String'];
  description: Scalars['String'];
};

/** Input Edit Faq */
export type EditFaqInput = {
  lang: LanguageEnum;
  name: Scalars['String'];
  tabIcon: Scalars['String'];
  items?: Maybe<Array<FaqItemInput>>;
  id: Scalars['ID'];
};

export type DomainInput = {
  id: Scalars['String'];
  domainNameVi?: Maybe<Scalars['String']>;
  domainNameEn?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  productIds?: Maybe<Array<Scalars['String']>>;
};

export type QuestionRateInput = {
  id: Scalars['ID'];
  proportion?: Maybe<Scalars['Int']>;
};

export type QuestionOptionInput = {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  questionType: QuestionTypeEnum;
  optionNameVi?: Maybe<Scalars['String']>;
  optionNameEn?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  familyPoint?: Maybe<Scalars['Float']>;
  educationPoint?: Maybe<Scalars['Float']>;
  healthPoint?: Maybe<Scalars['Float']>;
  retirementPoint?: Maybe<Scalars['Float']>;
  dreamsPoint?: Maybe<Scalars['Float']>;
};

export type EditQuestionOptionInput = {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  questionType: QuestionTypeEnum;
  optionNameVi?: Maybe<Scalars['String']>;
  optionNameEn?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  familyPoint?: Maybe<Scalars['Float']>;
  educationPoint?: Maybe<Scalars['Float']>;
  healthPoint?: Maybe<Scalars['Float']>;
  retirementPoint?: Maybe<Scalars['Float']>;
  dreamsPoint?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
};

export type SurveyInput = {
  numberOfChildren: Scalars['Int'];
  childrenInformation?: Maybe<Array<ChildrenInformationInput>>;
  concernOptionIds: Array<Scalars['ID']>;
  gender: GenderEnum;
  dateOfBirth: Scalars['DateTime'];
  marriageStatus: MarriageStatusEnum;
  livingOptionId: Scalars['String'];
  lang: LanguageEnum;
  fullname: Scalars['String'];
  partnerInformation?: Maybe<PartnerInformationInput>;
  domainPriorityId: Scalars['String'];
  domainInformation: SurveyDomainInput;
};

export type PartnerInformationInput = {
  fullName: Scalars['String'];
  dateOfBirth: Scalars['DateTime'];
  gender: GenderEnum;
};

export type SurveyDomainInput = {
  domainType?: Maybe<DomainTypeEnum>;
  kidJob?: Maybe<KidJobEnum>;
  kidStudy?: Maybe<KidStudyEnum>;
  supportYear?: Maybe<FamilySupportYearEnum>;
  supportMoney?: Maybe<Scalars['String']>;
  healthWorry?: Maybe<HealthWorryEnum>;
  policyCover?: Maybe<PolicyCoverEnum>;
  retirementPlan?: Maybe<RetirementPlanEnum>;
  retirementPlace?: Maybe<RetirementPlaceEnum>;
  retirementWork?: Maybe<RetirementWorkEnum>;
  yourDream?: Maybe<YourDreamEnum>;
  totalIncome?: Maybe<Scalars['String']>;
  currentSaving?: Maybe<Scalars['String']>;
  totalCost?: Maybe<Scalars['String']>;
  solutionCalculation?: Maybe<Scalars['String']>;
  suggestSaving?: Maybe<Scalars['String']>;
};

/** edit translation advertisement input */
export type CreateAdvertisementInput = {
  translations: Array<AdvertisementTransInput>;
  advertisementPage?: Maybe<Array<PopupPageInput>>;
};

/** create new translation advertisement input */
export type AdvertisementTransInput = {
  lang: LanguageEnum;
  name: Scalars['String'];
  image: Scalars['String'];
  imageURL: Scalars['String'];
  event: PopupEventEnum;
  eventTime?: Maybe<Scalars['Int']>;
  position: PopupPositionEnum;
  status: StatusType;
};

export type PopupPageInput = {
  lang: LanguageEnum;
  moduleType: PopupModuleEnum;
  urlSlugs?: Maybe<Array<Scalars['String']>>;
  pageTransIds?: Maybe<Array<Scalars['String']>>;
  articleTransIds?: Maybe<Array<Scalars['String']>>;
  memberTransIds?: Maybe<Array<Scalars['String']>>;
  productGroupTransIds?: Maybe<Array<Scalars['String']>>;
  productInformationIds?: Maybe<Array<Scalars['String']>>;
  partnerTransIds?: Maybe<Array<Scalars['String']>>;
  fundTransIds?: Maybe<Array<Scalars['String']>>;
};

/** edit translation advertisement input */
export type EditAdvertisementInput = {
  translations: Array<AdvertisementTransInput>;
  advertisementPage?: Maybe<Array<PopupPageInput>>;
  id: Scalars['ID'];
};

/** create new translation cookies input */
export type CookiesTransInput = {
  lang: LanguageEnum;
  name: Scalars['String'];
  description: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  background: Scalars['String'];
  buttonText: Scalars['String'];
  buttonBackground: Scalars['String'];
  status: StatusType;
};

/** edit translation cookies input */
export type EditCookiesInput = {
  id: Scalars['ID'];
  translations: Array<CookiesTransInput>;
};

/** create Newsletter input */
export type CreateNewsletterInput = {
  translations: Array<NewsletterTransInput>;
  newsletterPage?: Maybe<Array<PopupPageInput>>;
};

/** create new translation newsletter input */
export type NewsletterTransInput = {
  lang: LanguageEnum;
  name: Scalars['String'];
  image: Scalars['String'];
  layer?: Maybe<Scalars['String']>;
  header: Scalars['String'];
  inforNameText: Scalars['String'];
  inforNameIcon: Scalars['String'];
  inforPhoneText: Scalars['String'];
  inforPhoneIcon: Scalars['String'];
  inforEmailText: Scalars['String'];
  inforEmailIcon: Scalars['String'];
  inforCityText: Scalars['String'];
  inforCityIcon: Scalars['String'];
  buttonText: Scalars['String'];
  buttonBackground: Scalars['String'];
  footer?: Maybe<Scalars['String']>;
  event: PopupEventEnum;
  eventTime?: Maybe<Scalars['Int']>;
  position: PopupPositionEnum;
  status: StatusType;
};

/** edit newsletter input */
export type EditNewsletterInput = {
  translations: Array<NewsletterTransInput>;
  newsletterPage?: Maybe<Array<PopupPageInput>>;
  id: Scalars['ID'];
};

/** Service Program */
export type ServiceProgramInput = {
  serviceInformation?: Maybe<Array<ServiceInformationInput>>;
  customerHierarchy?: Maybe<Array<CustomerHierarchyInput>>;
  customerGift?: Maybe<Array<CustomerGiftInput>>;
  giftSteps?: Maybe<Array<GiftSteplInput>>;
  giftDetail?: Maybe<Array<GiftDetailInput>>;
  serviceFAQs?: Maybe<Array<ServiceFaqInput>>;
};

/** Input Thông tin chung */
export type ServiceInformationInput = {
  customerType: CustomerType;
  tabName?: Maybe<Scalars['String']>;
  tabIcon?: Maybe<Scalars['String']>;
  tabDescription?: Maybe<Scalars['String']>;
};

/** Input Phân hạng KH */
export type CustomerHierarchyInput = {
  customerType: CustomerType;
  sectionTitle?: Maybe<Scalars['String']>;
  customerDetail?: Maybe<Array<CustomerDetailInput>>;
  sectionNote?: Maybe<Scalars['String']>;
};

/** Input Phân hạng KH - Detail */
export type CustomerDetailInput = {
  title?: Maybe<Scalars['String']>;
  titleBackground?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueBackground?: Maybe<Scalars['String']>;
  orderPosition?: Maybe<Scalars['Int']>;
};

/** Input Quà tặng tri ân */
export type CustomerGiftInput = {
  customerType: CustomerType;
  sectionTitle?: Maybe<Scalars['String']>;
  giftList?: Maybe<Array<GiftListInput>>;
  showAllGifts?: Maybe<Scalars['Boolean']>;
};

/** Input Quà tặng tri ân - Detail */
export type GiftListInput = {
  customerType: CustomerType;
  year: Scalars['Int'];
  tableHeader: Array<GiftTableHeaderInput>;
  tableRows: Array<GiftTableRowInput>;
};

/** Input Quà tặng tri ân - Detail */
export type GiftTableHeaderInput = {
  headerTitle: Scalars['String'];
  headerAlias: Scalars['String'];
  headerBackground: Scalars['String'];
};

/** Input Quà tặng tri ân - Detail */
export type GiftTableRowInput = {
  rowId: Scalars['Int'];
  rowContent: Scalars['String'];
  headerAlias: Scalars['String'];
};

/** Input Hình thức tặng quà */
export type GiftSteplInput = {
  sectionTitle?: Maybe<Scalars['String']>;
  sectionIllustration?: Maybe<Scalars['String']>;
  stepDetail?: Maybe<Array<StepDetailInput>>;
};

/** Input Hình thức tặng quà - Detail */
export type StepDetailInput = {
  stepInfo?: Maybe<Scalars['String']>;
  orderPosition?: Maybe<Scalars['Int']>;
};

/** Input Thông tin quà tặng */
export type GiftDetailInput = {
  sectionTitle?: Maybe<Scalars['String']>;
  sectionNote?: Maybe<Scalars['String']>;
  giftInformation?: Maybe<Array<GiftInformationInput>>;
};

/** Input Thông tin quà tặng - Detail */
export type GiftInformationInput = {
  giftTitle?: Maybe<Scalars['String']>;
  giftLogo?: Maybe<Scalars['String']>;
  giftDescription?: Maybe<Scalars['String']>;
  orderPosition?: Maybe<Scalars['Int']>;
};

/** Input Câu hỏi thường gặp */
export type ServiceFaqInput = {
  sectionTitle?: Maybe<Scalars['String']>;
  faqDetail?: Maybe<Array<FaqDetailInput>>;
};

/** Input Câu hỏi thường gặp - Detail */
export type FaqDetailInput = {
  faqTitle?: Maybe<Scalars['String']>;
  faqDescription?: Maybe<Scalars['String']>;
  orderPosition?: Maybe<Scalars['Int']>;
};

/** Service Program General Info */
export type ServiceProgramGeneralInput = {
  desktopBanner?: Maybe<Scalars['String']>;
  mobileBanner?: Maybe<Scalars['String']>;
  seoPageTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeyword?: Maybe<Array<Scalars['String']>>;
  featureImage?: Maybe<Scalars['String']>;
};
