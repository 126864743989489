import React from 'react';
import GoogleMapReact from 'google-map-react';
import { GG_API_KEY } from 'src/helpers/constant';
import { MarkerIcon } from 'src/components/Maker';
import SearchBox from './SearchBox';
import _ from 'lodash';

const AnyReactComponent = ({ text, isModal }: { text: string; isModal?: boolean }) => {
  return isModal ? <img src={'/images/pin-dark.svg'} alt="" className="filterRed" /> : <MarkerIcon />;
};

const options = {
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ],
};

type Props = {
  isModal?: boolean;
  lat?: number;
  lng?: number;
  style?: any;
  handleCroods?: (lat: number, lng: number) => void;
};
const SimpleMap = ({ lat, lng, style, isModal, handleCroods }: Props) => {
  const centerProps = {
    lat: lat ? lat : 21.0165714,
    lng: lng ? lng : 105.7772347,
  };
  const [mapInstance, setMapInstance] = React.useState();
  const [mapsapi, setMapsapi] = React.useState();
  const [mapsApiLoaded, setMapsApiLoaded] = React.useState<boolean>(false);
  const [center, setCenter] = React.useState<{
    lat: number;
    lng: number;
  }>(centerProps);

  const handleApiLoaded = (map: any, maps: any) => {
    setMapInstance(map);
    setMapsapi(maps);
    setMapsApiLoaded(true);
  };

  const onPlacesChanged = (places: any) => {
    const bounds = new (mapsapi as any).LatLngBounds();
    if (places) {
      places.forEach((place: any) => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      const nextMarkers = places.map((place: any) => ({
        position: place.geometry.location,
      }));
      const nextCenter = _.get(nextMarkers, '0.position', center);
      const latCenter = nextCenter.lat((a: any) => {
        return a;
      });
      const lngCenter = nextCenter.lng((a: any) => {
        return a;
      });
      setCenter({
        lat: latCenter,
        lng: lngCenter,
      });
      if (handleCroods) {
        handleCroods(latCenter, lngCenter);
      }
    }
  };

  return (
    <div style={{ height: '100vh', width: '100%', ...style }}>
      <GoogleMapReact
        options={options}
        bootstrapURLKeys={{ key: GG_API_KEY, libraries: ['places', 'drawing'] }}
        center={isModal ? center : centerProps}
        defaultCenter={isModal ? center : centerProps}
        defaultZoom={17}
        resetBoundsOnResize={true}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onClick={({ lat, lng }) => {
          if (handleCroods && isModal) {
            handleCroods(lat, lng);
          }
        }}
      >
        {isModal ? (
          <AnyReactComponent text="My Marker" isModal={isModal} {...center} />
        ) : (
          <AnyReactComponent text="My Marker" {...centerProps} />
        )}
        {isModal && mapsApiLoaded ? (
          <SearchBox maps={mapsapi} map={mapInstance} onPlacesChanged={onPlacesChanged} />
        ) : null}
      </GoogleMapReact>
    </div>
  );
};

export default SimpleMap;
