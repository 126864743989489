import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useAuthenticated } from 'src/hooks/useAuthenticated';
import { AppRoutes } from 'src/helpers/app.routes';
import Error403 from '../Errors/403';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { RoleType } from 'src/graphql/type.interface';

type Props = {
  layout?: React.ComponentType<any>;
  isPrivate?: boolean;
  permission?: any;
  redirect?: string;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
} & RouteProps;

export const RouteLayout: React.FC<Props> = ({
  layout: Layout,
  component: Component,
  isPrivate,
  permission,
  redirect,
  ...props
}) => {
  const user = useCurrentUser();
  const userRoles = useCurrentUser()?.permissions ?? [];
  const isAuthenticated = useAuthenticated();
  if (!isAuthenticated && isPrivate) return <Redirect to={redirect ?? AppRoutes.login} />;
  if (user?.roles !== RoleType.ROOT && !userRoles.includes(permission ?? '')) return <Error403 />;
  return (
    <Route
      {...props}
      render={(ownProps) => {
        if (Layout)
          return (
            <Layout>
              <Component {...ownProps} />
            </Layout>
          );
        return <Component {...ownProps} />;
      }}
    />
  );
};

export default RouteLayout;

RouteLayout.defaultProps = {
  isPrivate: true,
};
