export const AppRoutes = {
  home: '/home',
  homeCreate: '/home/create',
  homeEditInfo: '/home/edit/:id',
  homeEdit: '/home/:builderId/edit',
  homeEditPageUpload: '/home/edit-page-upload/:id',
  homeEditInfoId: (id: number | string) => `/home/edit/${id}`,
  homeEditPageLayoutId: (id: number | string) => `/home/edit-page-builder/${id}`,
  homeEditPageUploadId: (id: number | string) => `/home/edit-page-upload/${id}`,
  viewHomePage: '/home/view/:id',
  viewHomePageId: (id: number | string) => `/home/view/${id}`,
  menuSetting: '/general/menu',
  headerSetting: '/general/header',
  general: '/general',
  sitemapSetting: '/general/sitemap',
  about: '/about',
  homeBuilder: '/home-builder',
  login: '/login',
  register: '/auth/register',
  forgot: '/auth/forgot',
  page404: '/404',
  page500: '/500',
  //Media
  media: '/media',
  mediaCreate: '/media/create',

  //Category
  category: '/category',
  categoryCreate: '/category/create',
  categoryEdit: '/category/edit/:id',
  categoryEditId: (id: number | string) => `/category/edit/${id}`,

  // PAGE
  pages: '/pages',
  createPage: '/pages/create',
  editPage: '/pages/edit/:id',
  editPageBuilder: '/pages/edit-page-builder/:id',
  editPageUpload: '/pages/edit-page-upload/:id',
  editPageId: (id: number | string) => `/pages/edit/${id}`,
  editPageLayoutId: (id: number | string) => `/pages/edit-page-builder/${id}`,
  editPageUploadtId: (id: number | string) => `/pages/edit-page-upload/${id}`,
  viewPage: '/pages/view/:id',
  viewPageId: (id: number | string) => `/pages/view/${id}`,
  pageBuilder: '/pages/:builderId/builder',
  pageBuilderId: (id: number | string) => `/pages/${id}/builder`,

  footerSetting: '/general-setting/footer',
  //PageBuilder
  pageBuilderEdit: '/page-builder/:builderId/edit',
  originBuilder: '/origin-builder/:builderId/edit',
  contactUsBuilder: '/contact-us-builder/:builderId/edit',
  businessEnvBuilder: '/business-environment-builder/:builderId/edit',
  paymentBuilder: '/payment-builder/:builderId/edit',
  bancaBuilder: '/banca-builder/:builderId/edit',
  careerAgencyBuilder: '/career-agency-builder/:builderId/edit',
  templateOneBuilder: '/template-one-builder/:builderId/edit',
  templateSevenBuilder: '/template-seven-builder/:builderId/edit',
  templateFourBuilder: '/template-four-builder/:builderId/edit',
  templateFiveBuilder: '/template-five-builder/:builderId/edit',
  templateThreeBuilder: '/template-three-builder/:builderId/edit',
  exfBuilder: '/exf-builder/:builderId/edit',

  //Our Team
  ourTeams: '/our-team',
  ourTeamsMembers: '/our-team/member',
  ourTeamsMemberDetail: '/our-team/member/:memberId',
  ourTeamsMemberDetailId: (memberId: number | string) => `/our-team/member/${memberId}`,
  ourTeamsMemberCreate: '/our-team/member/create',
  ourTeamsMemberUpdate: '/our-team/member/:memberId/edit',
  ourTeamsMemberUpdateId: (memberId: number | string) => `/our-team/member/${memberId}/edit`,
  ourTeamsGeneralInfo: '/our-team/general-info',

  //Fund and Statement
  fundManagement: '/fund-management',
  fundStateMent: '/fund-statement',
  fundList: '/fund-statement/fund',
  fundStateDetail: '/fund-statement/fund/:fundId',
  fundDetailId: (fundId: number | string) => `/fund-statement/fund/${fundId}`,
  fundCreate: '/fund-statement/fund/create',
  fundUpdate: '/fund-statement/fund/:fundId/edit',
  fundUpdateId: (fundId: number | string) => `/fund-statement/fund/${fundId}/edit`,
  fundGroupListGeneral: '/fund-statement/fund-group-list',
  unitFundGroup: '/fund-statement/unit-fund-group',
  unitFundGroupUpdate: '/fund-statement/unit-fund-group/update',
  universalLinkFundDetail: '/fund-statement/universal-link-fund',
  universalLinkFundUpdate: '/fund-statement/universal-link-fund/edit',
  fundPageList: '/fund-statement/page-list',

  //Fund Group
  fundGroupList: '/fund-statement/fund-group',
  fundGroupDetail: '/fund-statement/fund-group/:fundGroupId',
  fundGroupDetailId: (fundGroupId: number | string) => `/fund-statement/fund-group/${fundGroupId}`,
  fundGroupCreate: '/fund-statement/fund-group/create',
  fundGroupUpdate: '/fund-statement/fund-group/:fundGroupId/edit',
  fundGroupUpdateId: (fundGroupId: number | string) => `/fund-statement/fund-group/${fundGroupId}/edit`,

  //Interest Rate
  interestRate: '/interest-rate-management',
  interestRateList: '/interest-rate',
  interestRateInfo: '/interest-rate/info',
  interestRateUpdate: '/interest-rate/info/update',
  interestRateType: '/interest-rate-type',

  //Branch
  branch: '/branch',
  branchCreate: '/branch/create',
  branchEdit: '/branch/:id/edit',
  branchEditId: (id: number | string) => `/branch/${id}/edit`,
  branchView: '/branch/:id/view',
  branchViewId: (id: number | string) => `/branch/${id}/view`,

  //Financial Statement
  financialStatement: '/financial-report',

  //Articles
  articles: '/articles',

  //Press
  pressGeneralInfo: '/presses/general-info',
  press: '/presses',
  pressList: '/press',
  pressCreate: '/press/create',
  pressEdit: '/press/:id/edit',
  pressEditId: (id: number | string) => `/press/${id}/edit`,
  pressView: '/press/:id/view',
  pressViewId: (id: number | string) => `/press/${id}/view`,

  //Promotion
  promotionGeneralInfo: '/promotions/general-info',
  promotions: '/promotions',
  promotionList: '/promotion',
  promotionCreate: '/promotion/create',
  promotionEdit: '/promotion/:id/edit',
  promotionEditId: (id: number | string) => `/promotion/${id}/edit`,
  promotionView: '/promotion/:id/view',
  promotionViewId: (id: number | string) => `/promotion/${id}/view`,

  //Branding Campaign
  brandingCampaignsGeneralInfo: '/branding-campaigns/general-info',
  brandingCampaigns: '/branding-campaigns',
  brandingCampaignList: '/branding-campaign',
  brandingCampaignCreate: '/branding-campaign/create',
  brandingCampaignEdit: '/branding-campaign/:id/edit',
  brandingCampaignEditId: (id: number | string) => `/branding-campaign/${id}/edit`,
  brandingCampaignView: '/branding-campaign/:id/view',
  brandingCampaignViewId: (id: number | string) => `/branding-campaign/${id}/view`,

  //CSR
  csrGeneralInfo: '/csr-s/general-info',
  csr: '/csr-s',
  csrList: '/csr',
  csrCreate: '/csr/create',
  csrEdit: '/csr/:id/edit',
  csrEditId: (id: number | string) => `/csr/${id}/edit`,
  csrView: '/csr/:id/view',
  csrViewId: (id: number | string) => `/csr/${id}/view`,

  //Blog
  blogsGeneralInfo: '/blogs/general-info',
  blogs: '/blogs',
  blogList: '/blog',
  blogCreate: '/blog/create',
  blogEdit: '/blog/:id/edit',
  blogEditId: (id: number | string) => `/blog/${id}/edit`,
  blogView: '/blog/:id/view',
  blogViewId: (id: number | string) => `/blog/${id}/view`,

  // Product Management
  productManagement: '/product-management',
  productGeneralInfo: '/product-management/general-info',
  productList: '/product-management/product',
  productCreate: '/product-management/product/create',
  productUpdate: '/product-management/product/:productId/edit',
  productUpdateId: (productId: number | string) => `/product-management/product/${productId}/edit`,

  // Product Group
  productGroupList: '/product-management/group',
  productGroupCreate: '/product-management/group/create',
  productGroupUpdate: '/product-management/group/:productGroupId/edit',
  productGroupUpdateId: (productGroupId: number | string) => `/product-management/group/${productGroupId}/edit`,

  //Customer Request
  request: '/customer-requests',
  requestList: '/customer-requests/requests',
  requestSettings: '/customer-requests/settings',
  requestView: '/customer-requests/:id/view',
  requestViewId: (id: number | string) => `/customer-requests/${id}/view`,

  // Partners
  partner: '/partner',
  partnerEdit: '/partner/:id/edit',
  partnerEditId: (id: number | string) => `/partner/${id}/edit`,
  partnerView: '/partner/:id/view',
  partnerViewId: (id: number | string) => `/partner/${id}/view`,

  //Article Group
  articleGroup: '/blog-group',
  articleGroupCreate: '/blog-group/create',
  articleGroupEdit: '/blog-group/edit/:id',
  articleGroupEditId: (id: number | string) => `/blog-group/edit/${id}`,

  fundRateList: '/fund-rate',

  //Role Anh Permisstion
  roleList: '/role-and-permisson',
  accountList: '/account',
  accountCreate: '/account/create',
  accountEdit: '/account/edit/:id',
  accountEditId: (id: number | string) => `/account/edit/${id}`,
  // Policy Management
  policyManagement: '/policy-management',
  policyList: '/policy-management/policy',
  policyCreate: '/policy-management/policy/create',
  policyUpdate: '/policy-management/policy/:policyId/edit',
  policyUpdateId: (policyId: number | string) => `/policy-management/policy/${policyId}/edit`,

  // Claim Management
  claimList: '/policy-management/claim',
  claimCreate: '/policy-management/claim/create',
  claimUpdate: '/policy-management/claim/:claimId/edit',
  claimUpdateId: (claimId: number | string) => `/policy-management/claim/${claimId}/edit`,

  generalInfoPolicy: '/policy-change-general-info',
  generalInfoClaim: '/claim-general-info',
  policyChangeList: '/policy-change',
  generalInfoFAQs: '/faqs-general-info',
  listFAQs: '/faq',
  //faq
  faqs: '/faq-management',
  faqGeneralInfo: '/faq-general-info',
  faqList: '/faq',
  faqCreate: '/faq/create',
  faqEdit: '/faq/:id/edit',
  faqEditId: (id: number | string) => `/faq/${id}/edit`,
  faqView: '/faq/:id/view',
  faqViewId: (id: number | string) => `/faq/${id}/view`,

  permissionMatrix: '/permission-matrix-setting',

  //popup-management
  popupManagement: '/popup-management',
  newsletterList: '/popup-management/newsletter',
  newsletteCreate: '/popup-management/newsletter/create',
  newsletteUpdate: '/popup-management/newsletter/:popupId/edit',
  newsletteUpdateId: (popupId: number | string) => `/popup-management/newsletter/${popupId}/edit`,

  advertisementList: '/popup-management/advertisement',
  advertisementCreate: '/popup-management/advertisement/create',
  advertisementUpdate: '/popup-management/advertisement/:popupId/edit',
  advertisementUpdateId: (popupId: number | string) => `/popup-management/advertisement/${popupId}/edit`,

  cookiesList: '/popup-management/cookies',
  cookiesCreate: '/popup-management/cookies/create',
  cookiesUpdate: '/popup-management/cookies/:popupId/edit',
  cookiesUpdateId: (popupId: number | string) => `/popup-management/cookies/${popupId}/edit`,

  //SAA Tool
  saaTool: '/saa-tool',
  questionMatrix: '/question-matrix',
  questionRateMatrix: '/question-rate-matrix',
  questionRateMatrixView: '/question-rate-matrix/:id/view',
  questionRateMatrixViewId: (id: number | string) => `/question-rate-matrix/${id}/view`,
  questionRateMatrixEdit: '/question-rate-matrix/:id/edit',
  questionRateMatrixEditId: (id: number | string) => `/question-rate-matrix/${id}/edit`,
  saaResults: '/saa-results',
  saaResultList: '/saa-result',
  saaResultView: '/saa-result/:id/view',
  saaResultViewId: (id: number | string) => `/saa-result/${id}/view`,
  domains: '/domains',
  domainList: '/domain/list',
  domainView: '/domain/:id/view',
  domainViewId: (id: number | string) => `/domain/${id}/view`,
  domainEdit: '/domain/:id/edit',
  domainEditId: (id: number | string) => `/domain/${id}/edit`,

  // Payment Log
  paymentLog: '/payment-log',

  //Page Qua Tang Tri An

  customerService: '/customer-service',
  serviceProgram: '/service-program',
  serviceProgramGeneralInfo: '/service-program/general-info',
};
