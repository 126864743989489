import styled from 'styled-components';
import { background, BackgroundProps, StylesProps } from 'styled-system';
import { border, BorderProps } from 'styled-system';
import { color, ColorProps } from 'styled-system';
import { flexbox, FlexboxProps } from 'styled-system';
import { grid, GridProps } from 'styled-system';
import { layout, LayoutProps } from 'styled-system';
import { opacity, OpacityProps } from 'styled-system';
import { position, PositionProps } from 'styled-system';
import { shadow, ShadowProps } from 'styled-system';
import { space, SpaceProps } from 'styled-system';
import { typography, TypographyProps } from 'styled-system';
import { headShake } from './keyframes';
import { getPropsName } from 'src/helpers/getPropsName';
import { COLOR } from 'src/helpers/color';

export type BoxProps = { css?: any } & BackgroundProps &
  BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  OpacityProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TypographyProps & { css?: any };
export const Box = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(
      background,
      border,
      color,
      flexbox,
      grid,
      layout,
      opacity,
      position,
      shadow,
      space,
      typography,
    ).includes(prop) && defaultValidatorFn(prop),
})<BoxProps>(background, border, color, flexbox, grid, layout, opacity, position, shadow, space, typography, {
  fontFamily: `'Source Sans Pro', sans-serif`,
});

export type BaseElementProps = { css?: any } & BackgroundProps &
  BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  OpacityProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TypographyProps;
export const BaseElement = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(
      background,
      border,
      color,
      flexbox,
      grid,
      layout,
      opacity,
      position,
      shadow,
      space,
      typography,
    ).includes(prop) && defaultValidatorFn(prop),
})<BaseElementProps>(background, border, color, flexbox, grid, layout, opacity, position, shadow, space, typography);

export type GridElementProps = { css?: any } & BackgroundProps &
  BorderProps &
  ColorProps &
  GridProps &
  LayoutProps &
  OpacityProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TypographyProps;
export const GridElement = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(background, border, color, grid, layout, opacity, position, shadow, space, typography).includes(
      prop,
    ) && defaultValidatorFn(prop),
})<GridElementProps>(background, border, color, grid, layout, opacity, position, shadow, space, typography);

export const Background = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(background, flexbox, layout).includes(prop) && defaultValidatorFn(prop),
})<BackgroundProps & FlexboxProps & LayoutProps & StylesProps>(background, flexbox, layout);

export type TypoProps = { css?: any } & SpaceProps &
  TypographyProps &
  ColorProps &
  LayoutProps &
  OpacityProps &
  FlexboxProps;
export const Typography = styled('p').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(typography, space, color, layout, opacity, flexbox).includes(prop) && defaultValidatorFn(prop),
})<TypoProps>(typography, space, color, layout, opacity, flexbox);

export type LinkProps = {
  hoverColor?: string;
  css?: any;
} & BorderProps &
  BackgroundProps &
  SpaceProps &
  TypographyProps &
  LayoutProps &
  ColorProps &
  FlexboxProps;
export const Link = styled('a').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(background, border, typography, space, layout, position, color, flexbox).includes(prop) &&
    defaultValidatorFn(prop),
})<LinkProps>`
  ${typography}
  ${border}
  ${background}
  ${space}
  ${layout}
  ${position}
  ${color}
  ${flexbox}
  &:hover {
    ${({ hoverColor }) =>
      !!hoverColor &&
      `
      color: ${hoverColor};
    `}
    * {
      ${({ hoverColor }) =>
        !!hoverColor &&
        `
      color: ${hoverColor};
    `}
    }
    .headShake {
      -webkit-animation-name: ${headShake};
      animation-name: ${headShake};
      animation-duration: 1s;
    }
    .hoverText {
      text-decoration: underline;
    }
  }
`;

export const FallbackMessage = styled('span').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(background, border, typography, space, layout, position, color).includes(prop) &&
    defaultValidatorFn(prop),
})<BorderProps & BackgroundProps & SpaceProps & TypographyProps & LayoutProps & ColorProps>(
  background,
  border,
  typography,
  space,
  layout,
  position,
  color,
);

export const Container = styled(Box).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(
      background,
      border,
      color,
      flexbox,
      grid,
      layout,
      opacity,
      position,
      shadow,
      space,
      typography,
    ).includes(prop) && defaultValidatorFn(prop),
})<BoxProps>(
  {
    maxWidth: 1440,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
  },
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  opacity,
  position,
  shadow,
  space,
  typography,
);

export const Button = styled('a').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(background, border, typography, space, layout).includes(prop) && defaultValidatorFn(prop),
})<
  {
    hoverBtnColor?: string;
  } & BorderProps &
    BackgroundProps &
    SpaceProps &
    TypographyProps &
    LayoutProps
>`
  display: inline-block;
  padding: 0 30px;
  line-height: 44px;
  border-radius: 30px;
  border: 1px solid ${COLOR.primary};
  background-color: ${COLOR.primary};
  color: ${COLOR.white};
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  ${typography}
  ${background}
    ${border}
    ${space}
    ${layout}
    &:hover {
    color: ${COLOR.white};
    img {
      filter: brightness(0) invert(1);
    }
    ${({ hoverBtnColor }) =>
      (!!hoverBtnColor &&
        `
      background-color: ${hoverBtnColor};
      border-color: ${hoverBtnColor};
    `) ||
      `
      background-color: ${COLOR.second};
      border-color: ${COLOR.second};`}
  }
`;

export const ButtonOutline = styled(Button).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(background, border, typography, space, layout).includes(prop) && defaultValidatorFn(prop),
})<
  {
    btnColor?: string;
    hoverBtnColor?: string;
  } & BorderProps &
    BackgroundProps &
    SpaceProps &
    TypographyProps &
    LayoutProps
>`
  background-color: transparent;
  ${({ btnColor }) =>
    (!!btnColor &&
      `
      color: ${btnColor};
      border-color: ${btnColor};
    `) ||
    `
    color: ${COLOR.primary};
      border-color: ${COLOR.primary};`}
  &:hover {
    color: white;
    * {
      color: white !important;
    }
    img,
    svg {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(78deg) brightness(200%) contrast(102%);
    }
    ${({ hoverBtnColor }) =>
      (!!hoverBtnColor &&
        `
      background-color: ${hoverBtnColor};
      border-color: ${hoverBtnColor};
    `) ||
      `
    background-color: ${COLOR.primary};
      border-color: ${COLOR.primary};`}
  }
`;

export type ImgProps = BorderProps &
  BackgroundProps &
  TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps &
  OpacityProps;
export const Img = styled('img').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !getPropsName(background, border, typography, space, layout, position, color, opacity).includes(prop) &&
    defaultValidatorFn(prop),
})<ImgProps>(background, border, typography, space, layout, position, color, opacity);
