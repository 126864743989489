import { rgba } from 'polished';
// export const COLOR = {
//   primary: '#fb4390',
//   second: '#01C4DE',
//   third: '#5bc5f2',
//   four: '#165BAA',
//   five: '#141ED2',
//   textColor: '#112843',
//   white: '#fff',
//   black: '#000',
//   borderColor: '#d9d9d9',
//   borderLineColor: '#E7E9EC',
//   borderInputColor: '#eee',
//   footerColor: '#00377A',
//   gray: '#687787',
//   grayColor: rgba('#687787', 0.64),
//   primaryBlur: '#FFE9EF',
//   blue2: '#5BC5F2',
//   blueBlur: '#A8D2F0',
//   fiveBlur: '#E4F7FF',
// };
// export const PERSON_COLOR = [COLOR.primaryBlur, '#F9F8EB', '#DAF7F3', '#A8D2F0', '#E7E9EC'];
export const COLOR = {
  primary: '#fb4390',
  second: '#01C4DE',
  third: '#5bc5f2',
  four: '#165BAA',
  five: '#141ED2',
  textColor: '#112843',
  white: '#fff',
  black: '#000',
  borderColor: '#d9d9d9',
  borderLineColor: '#E7E9EC',
  borderInputColor: '#eee',
  footerColor: '#00377A',
  gray: '#687787',
  grayColor: rgba('#687787', 0.64),
  primaryBlur: '#FFE9EF',
  blueBlur: '#A8D2F0',
  fiveBlur: '#E4F7FF',
  grayBlur: '#F8F8F8',
  pink: '#FFF0F0',
  borderPink: '#FFC6C6',
  light: '#99A2AD',
  tempBlue: '#3F47D2',
};

export const FIVE_COLOR = ['#FA87B3', COLOR.third, '#FFA1A1', '#737AEE', '#FFB979'];
export const FIVE_COLOR_BLUR = [COLOR.primaryBlur, COLOR.fiveBlur, '#FFEDED', '#E7E8FB', COLOR.grayBlur];
export const CSS_DEFAULT = {
  containerSpace: [24, 24, 24, 24, 20],
  containerTemplate: ['100%', '100%', '100%', '992px', '1200px'],
};

export const EditorColor: string[] = [
  '#5BC5F2',
  '#E4F7FF',
  '#165BAA',
  '#E7E8FB',
  '#FB4390',
  '#FFE9EF',
  '#D63120',
  '#FFF0F0',
  '#141ED2',
  '#E7E8FB',
  '#000000',
  '#333333',
  '#666666',
  '#999999',
  '#cccccc',
  '#ffffff',
  '#61a951',
  '#16a085',
  '#07a9fe',
  '#003ba5',
  '#8e44ad',
  '#f32784',
  '#c0392b',
  '#d35400',
  '#f39c12',
  '#fdda00',
  '#7f8c8d',
  '#2c3e50',
];
