import { PermissionModuleType } from 'src/graphql/type.interface';
export const AppPermissions = {
  general_setting: PermissionModuleType.GENERAL_SETTING,
  home_management: PermissionModuleType.HOME_MANAGEMENT,
  page_management: PermissionModuleType.PAGE_MANAGEMENT,
  category_management: PermissionModuleType.CATEGORY_MANAGEMENT,
  asset_management: PermissionModuleType.ASSET_MANAGEMENT,
  our_team: PermissionModuleType.OUR_TEAM,
  fund_management: PermissionModuleType.FUND_MANAGEMENT,
  branches: PermissionModuleType.BRANCHES,
  financial_report: PermissionModuleType.FINANCIAL_REPORT,
  articles: PermissionModuleType.ARTICLES,
  product_management: PermissionModuleType.PRODUCT_MANAGEMENT,
  customer_request: PermissionModuleType.CUSTOMER_REQUEST,
  policy_management: PermissionModuleType.POLICY_CLAIM,
  faqs_management: PermissionModuleType.FAQ_MANAGEMENT,
  users_management: PermissionModuleType.USERS,
  role_permission: PermissionModuleType.ROLE_PERMISSION,
  popup_management: PermissionModuleType.POPUP_MANAGEMENT,
  saa_tool: PermissionModuleType.SAA_TOOL,
  transaction_log: PermissionModuleType.TRANSACTION_LOG,
  SERVICE_PROGRAM: PermissionModuleType.SERVICE_PROGRAM,
};
