import React, { memo, SFC } from 'react';
import { Button, Result } from 'antd';
import { AppRoutes } from 'src/helpers/app.routes';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { PermissionModuleType } from 'src/graphql/type.interface';
import { useLogoutMutation } from 'src/graphql/mutations/logout.generated';
import { useApolloError } from 'src/hooks/useApolloError';
import { useHistory } from 'react-router-dom';

const Error403: SFC = memo(() => {
  const user = useCurrentUser();
  const history = useHistory();

  const [logout] = useLogoutMutation({
    onCompleted: () => {
      window.location.reload();
    },
    onError: useApolloError().apolloErrors,
  });
  // console.log(user);
  let route = AppRoutes.home;
  switch (user?.permissions?.[0]) {
    case PermissionModuleType.GENERAL_SETTING:
      route = AppRoutes.headerSetting;
      break;
    case PermissionModuleType.PAGE_MANAGEMENT:
      route = AppRoutes.pages;
      break;
    case PermissionModuleType.CATEGORY_MANAGEMENT:
      route = AppRoutes.category;
      break;
    case PermissionModuleType.ASSET_MANAGEMENT:
      route = AppRoutes.media;
      break;
    case PermissionModuleType.OUR_TEAM:
      route = AppRoutes.ourTeamsMembers;
      break;
    case PermissionModuleType.FUND_MANAGEMENT:
      route = AppRoutes.fundList;
      break;
    case PermissionModuleType.BRANCHES:
      route = AppRoutes.branch;
      break;
    case PermissionModuleType.FINANCIAL_REPORT:
      route = AppRoutes.financialStatement;
      break;
    case PermissionModuleType.ARTICLES:
      route = AppRoutes.pressList;
      break;
    case PermissionModuleType.PRODUCT_MANAGEMENT:
      route = AppRoutes.productList;
      break;
    case PermissionModuleType.CUSTOMER_REQUEST:
      route = AppRoutes.requestList;
      break;
    case PermissionModuleType.POLICY_CLAIM:
      route = AppRoutes.policyList;
      break;
    case PermissionModuleType.FAQ_MANAGEMENT:
      route = AppRoutes.faqList;
      break;
    default:
      break;
  }

  const handleLogout = async () => {
    if (user?.permissions && user?.permissions?.length > 0) {
      history.push(route);
    } else {
      logout();
    }
  };

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={handleLogout}>
          Back
        </Button>
      }
    />
  );
});

export default Error403;
