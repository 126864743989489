import { filter, forEach } from 'lodash';
import { LanguageEnum, Category, ProductGroup, QuestionOptions } from 'src/graphql/type.interface';
export function getAntLayoutElement() {
  return document.querySelector('section.ant-layout:not(.ant-layout-has-sider)');
}

export const removeTypenameMenu = (val: any) => {
  return (
    val?.map((v: any) => {
      return {
        children: removeTypename(v?.children),
        icon: v?.icon,
        id: v?.id,
        pageId: v?.pageId,
        slug: v?.slug,
        text: v?.text,
        url: v?.url,
        megaMenu: v?.megaMenu,
      };
    }) ?? []
  );
};
export const removeTypename = (val: any) => {
  return (
    val?.map((v: any) => {
      return {
        children: removeTypename(v?.children),
        icon: v?.icon,
        id: v?.id,
        pageId: v?.pageId,
        slug: v?.slug,
        text: v?.text,
        url: v?.url,
      };
    }) ?? []
  );
};

export const removeChildren = (val: Category[]) => {
  const data =
    val?.map((v: Category) => {
      const child = {
        id: v?.id,
        parentId: v?.parentId,
        countPage: v?.countPage,
        translations: v?.translations,
        description: v?.description,
        createdAt: v?.createdAt,
        updatedAt: v?.updatedAt,
        pages: v?.pages,
        parent: v?.parent,
      };
      if (v?.children?.length) Object.assign(child, { children: removeChildren(v?.children) });
      return child;
    }) ?? [];
  return data;
};

export const countIndex = (index: number, page: number, take: number) => {
  const order = page > 1 ? (page - 1) * take + index : index;
  return order;
};

export const getTitleLang = (val: any, lang?: LanguageEnum) => {
  return filter(val, (ck) => ck.lang === lang)?.[0]?.name || '';
};

export const getObjLang = (val: any, lang?: LanguageEnum) => {
  return filter(val, (ck) => ck.lang === lang)?.[0] || '';
};

export const coverFormatData = (val: Category[], categoryId?: string, lang: LanguageEnum = LanguageEnum.VI) => {
  const currentData =
    val?.map((v: Category) => {
      const dataNew = {
        countPage: v?.countPage,
        createdAt: v?.createdAt,
        description: v?.description,
        id: v?.id,
        parent: v?.parent,
        translations: v?.translations,
        updatedAt: v?.updatedAt,
      };
      Object.assign(dataNew, {
        value: v?.id,
        title: getTitleLang(v?.translations, lang),
      });
      if (v?.id === categoryId) Object.assign(dataNew, { disabled: true });
      if (v?.children?.length) Object.assign(dataNew, { children: coverFormatData(v?.children) });
      return dataNew;
    }) ?? [];
  return currentData;
};
export const getFullAddress = (item: any) => {
  const arrData: string[] = [];

  if (item?.street && item?.street !== '') {
    arrData.push(item?.street);
  }
  if (item?.ward?.name && item?.ward?.name !== '') {
    arrData.push(item?.ward?.name);
  }
  if (item?.district?.name && item?.district?.name !== '') {
    arrData.push(item?.district?.name);
  }
  if (item?.province?.name && item?.province?.name !== '') {
    arrData.push(item?.province?.name);
  }

  return arrData.join(', ');
};

export const getContentByLang = (val: any, lang?: LanguageEnum, properties: string = '') => {
  return filter(val, (ck) => ck.lang === lang)?.[0]?.[properties] || '';
};

export const removeNewArray = (oldItems: string[], id?: string) => {
  let selected: string[] = [...oldItems];
  const checkIndex = selected.findIndex((item) => item === id);
  if (checkIndex < 0) {
    return true;
  } else {
    selected = selected.filter((item) => item !== id);
  }

  return selected;
};

export const checkChangeStep = (typeAction: string, curentStep: number, stepToGo: number) => {
  if (curentStep < stepToGo && curentStep + 1 < stepToGo) {
    return false;
  }
  return true;
};

export const checkChangeStepService = (curentStep: number, stepToGo: number) => {
  if (curentStep < stepToGo && curentStep + 1 < stepToGo) {
    return false;
  }
  return true;
};

export const formatDataTreeProduct = (val: any[], productGroupId?: string, lang: LanguageEnum = LanguageEnum.VI) => {
  const currentData =
    val?.map((v: ProductGroup) => {
      const dataNew = {
        countPage: v?.countProduct,
        createdAt: v?.createdAt,
        id: v?.id,
        parent: v?.parent,
        translations: v?.translations,
        updatedAt: v?.updatedAt,
      };
      Object.assign(dataNew, {
        value: v?.id,
        title: getTitleLang(v?.translations, lang),
      });
      if (v?.id === productGroupId) Object.assign(dataNew, { disabled: true });
      if (v?.children?.length) Object.assign(dataNew, { children: formatDataTreeProduct(v?.children, '', lang) });
      return dataNew;
    }) ?? [];
  return currentData;
};

export const stripHtmlTags = (str: string) => {
  if (str === null || str === '' || !str) return;
  else str = str.toString();
  return str.replace(/<[^>]*>/g, '')?.trim();
};

export const getLanguageTitle = (lang?: LanguageEnum) => {
  switch (lang) {
    case LanguageEnum.EN:
      return 'English';
    default:
      return 'Vietnamese';
  }
};

export const getArrayLang = <T extends { lang: LanguageEnum }>(val?: T[] | null, lang?: LanguageEnum) => {
  return filter(val, (ck) => ck.lang === lang) || [];
};

export const formatTreeDataProductGroups = (val: any[], lang: LanguageEnum, type?: string) => {
  const currentData =
    val?.map((v: Category) => {
      const dataNew = {
        id: v?.id,
        parentId: v?.parentId,
        translations: v?.translations,
      };
      Object.assign(dataNew, {
        value: v?.id,
        title: getObjLang(v?.translations, lang)?.name,
        disabled: false,
      });
      if (type === 'PRODUCT' && !v?.parentId) {
        Object.assign(dataNew, {
          disabled: true,
        });
      }
      if (v?.children?.length) Object.assign(dataNew, { children: formatTreeDataProductGroups(v?.children, lang) });
      return dataNew;
    }) ?? [];

  return currentData;
};
export const formatMoney = (num: number | string, prefix?: string) => {
  const unit = prefix ?? 'VNĐ';
  if (!num) return '0 ' + prefix;
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' ' + unit;
};
export const formatDataChartWidget = (items: any) => {
  return (
    items?.map((v: any) => {
      return {
        ...v,
        value: Number(v.value) || 0,
      };
    }) ?? []
  );
};

export const formatConcernOptions = (data: any[]) => {
  const arrData: string[] = [];
  forEach(data, function (item: QuestionOptions, key: number) {
    if (item?.optionNameVi) {
      arrData.push(item?.optionNameVi);
    }
  });

  return arrData.join('/');
};

export const getTextLang = (textVI?: string, textEN?: string, lang?: string | LanguageEnum) => {
  let text = textVI;
  switch (lang) {
    case 'EN':
      text = textEN;
      break;
    case 'en':
      text = textEN;
      break;
    default:
      break;
  }
  return text ?? '';
};

export const removeVietnameseTones = (str: string) => {
  str = str?.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str?.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str?.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str?.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str?.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str?.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str?.replace(/đ/g, 'd');
  str = str?.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str?.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str?.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str?.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str?.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str?.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str?.replace(/Đ/g, 'D');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str?.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str?.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  // eslint-disable-next-line no-useless-escape
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
  return str?.trim() ?? '';
};

export const makeSlug = (str: string) => {
  return removeVietnameseTones(str)
    ?.toLowerCase()
    ?.replace(/ /g, '-')
    ?.replace(/[^\w-]+/g, '');
};
export const camelize = (str: string) => {
  return str.toLowerCase().replace(/\W+(.)/g, function (match, chr) {
    return chr.toUpperCase();
  });
};

export const trunCate = (str: string, characters?: number) => {
  const number: number = characters ?? 100;
  return str.length > number ? str.substring(0, number) + '...' : str;
};
